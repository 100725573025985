import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { Box, Button, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import classes from './FileDropzone.module.scss';
import { IconCloudUpload, IconHelpHexagonFilled } from '@tabler/icons-react';

interface FileDropzoneProps {
  acceptedFileTypes: string[];
  handleFileDrop: (files: FileWithPath[]) => void;
  buttonText?: string;
}

// 2 GB
const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024;

export const FileDropzone = ({
  handleFileDrop,
  acceptedFileTypes,
  buttonText = 'Choose a file',
}: FileDropzoneProps) => {
  const [fileRejected, setFileRejected] = useState(false);

  const openRef = useRef<() => void>(null);

  const handleRejectedFiles = () => {
    setFileRejected(true);
  };

  const handleValidFileDrop = (files: FileWithPath[]) => {
    setFileRejected(false);
    handleFileDrop(files);
  };

  useEffect(() => {
    return () => {
      setFileRejected(false);
    };
  }, []);

  return (
    <Dropzone
      maxFiles={1}
      maxSize={MAX_FILE_SIZE}
      openRef={openRef}
      className={classes.dropzone}
      onReject={handleRejectedFiles}
      onDrop={handleValidFileDrop}
      useFsAccessApi
      accept={{
        'model/step': acceptedFileTypes,
        'application/p21': ['.p21'],
      }}
    >
      <Stack mih={209} justify="center" align="center" gap={'lg'} className={classes.container}>
        <IconCloudUpload size={40} color={'var(--border-quaternary-color)'} />
        <Box>
          <Text variant={'medium'} size={'lg'} c={'text-secondary'} inline>
            Upload your file
          </Text>
          <Box mt={'sm'} className={classes.dragDrop}>
            <Button px={'md'} py={'sm'}>
              {buttonText}
            </Button>
          </Box>
        </Box>
        <Group>
          <Text size={'sm'} c={'var(--text-primary)'}>
            Compatible Formats: {acceptedFileTypes.join(', ')}
          </Text>
          <Tooltip label={'Please contact us for additional formats'} openDelay={500}>
            <ThemeIcon variant={'transparent'} c={'text-tertiary'} size={'xs'}>
              <IconHelpHexagonFilled />
            </ThemeIcon>
          </Tooltip>
        </Group>
        {fileRejected && (
          <Text size={'sm'} c={'var(--text-alert)'}>
            File type not supported
          </Text>
        )}
      </Stack>
    </Dropzone>
  );
};
