export const capitalizeString = <T extends string>(s: T) => (s[0].toUpperCase() + s.slice(1)) as Capitalize<typeof s>;

export const capitalizeSentence = <T extends string>(s: T) =>
  s.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

/**
 * Function that returns difference between past date and now in a human readable way.
 * @param date - Past Date
 * @returns Human readable difference in days (e.g. Two days ago)
 */
export const getFormattedDateDifference = (date: Date, capitalize = true) => {
  const now = new Date();
  const pastDate = new Date(date);
  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  const diff = Math.round((pastDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
  const formattedString = formatter.format(diff, 'day');
  return capitalize ? capitalizeString(formattedString) : formattedString;
};

export const getFormattedTimeDifference = (date: Date, capitalize = false) => {
  const now = new Date();
  const pastDate = new Date(date);

  const diffInMilliseconds = pastDate.getTime() - now.getTime();
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInHours / 24);

  const formatDays = diffInHours * -1 > 23;

  const unit: Intl.RelativeTimeFormatUnit = formatDays ? 'day' : 'hour';
  const formatter = new Intl.RelativeTimeFormat('en', { style: formatDays ? 'short' : 'narrow', numeric: 'auto' });
  const formattedString = formatter.format(formatDays ? diffInDays : diffInHours, unit);
  return capitalize ? capitalizeString(formattedString) : formattedString;
};

export const getFormattedDateString = (date: Date) => {
  const dateToFormat = new Date(date);
  const formatter = new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formatter.format(dateToFormat.getTime());
};
