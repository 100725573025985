import React from 'react';
import { Group, Radio, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useComparableInstructionsQuery } from '@assemblio/frontend/data-access';

interface VersionSelectionProps {
  productId: string;
  syncProfileSource: string | undefined;
  setSyncProfileSource: (value: string) => void;
  selectedVersions: { [key: string]: string | null };
  handleVersionChange: (id: string, version: string | null) => void;
  versions: { value: string; label: string }[];
}

export const VersionSelection = ({
  productId,
  syncProfileSource,
  setSyncProfileSource,
  selectedVersions,
  handleVersionChange,
  versions,
}: VersionSelectionProps) => {
  const { data, isLoading, isError } = useComparableInstructionsQuery(productId);
  return (
    <Stack gap={0}>
      <Text>Select a product to use as base: </Text>
      <Radio.Group value={syncProfileSource} onChange={setSyncProfileSource}>
        <Stack mx="lg" mt="lg" mb="xl" gap="lg">
          {isLoading && (
            <>
              <Skeleton height="25px" width="100%" />
              <Skeleton height="25px" width="100%" />
            </>
          )}
          {data && (
            <>
              {data.map((ins) => (
                <Group key={`available-instruction-${ins.id}`} justify="space-between" gap={0}>
                  <Group>
                    <Tooltip label={ins.isAvailable ? ins.name : null} openDelay={500} refProp="rootRef">
                      <Radio disabled={!ins.isAvailable} max={ins ? '100%' : '60%'} value={ins.id} label={ins.name} />
                    </Tooltip>
                    {!ins.isAvailable && (
                      <Text size={'xs'} c={'text-secondary'}>
                        ({ins.reason})
                      </Text>
                    )}
                  </Group>
                  {/*<Select*/}
                  {/*  value={selectedVersion}*/}
                  {/*  onChange={setSelectedVersion}*/}
                  {/*  data={versions}*/}
                  {/*  placeholder="Select version"*/}
                  {/*  style={{ border: '2px solid black' }}*/}
                  {/*/>*/}
                </Group>
              ))}
            </>
          )}
          {isError && (
            <Group>
              <IconExclamationCircle />
              <Text>Could not fetch instructions</Text>
            </Group>
          )}
        </Stack>
      </Radio.Group>
    </Stack>
  );
};
