import { Button, Text, Title, Group, Divider, Stack, Modal, ActionIcon } from '@mantine/core';
import classes from './UserSettingsOverlay.module.scss';
import { PersonalInformation } from './components/PersonalInformation';
import { AppearanceMode } from './components/AppearanceMode';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCircleXFilled } from '@tabler/icons-react';
import { useSidebarState } from '../../hooks';
import cx from 'clsx';

interface UserSettingsOverlayProps {
  opened: boolean;
  onClose: () => void;
}

export const UserSettingsOverlay = ({ opened, onClose }: UserSettingsOverlayProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed } = useSidebarState();

  const inEditor = location.pathname.includes('/disassembler') || location.pathname.includes('/assembler');

  const handleCloseUserProfile = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('profile');
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleCloseUserProfile}
      withCloseButton={false}
      radius={'lg'}
      data-collapsed={collapsed}
      centered
      classNames={{
        overlay: classes.modal__overlay,
        content: cx(classes.modal__content, {
          [classes.modal__content__editor]: inEditor,
        }),
        body: classes.modal__body,
        close: classes.modal_close,
      }}
    >
      <Stack h={'100%'} pt={'xl'} className={classes.content} px="xl">
        <ActionIcon
          variant={'transparent'}
          c={'text-primary'}
          className={classes.close}
          onClick={handleCloseUserProfile}
        >
          <IconCircleXFilled />
        </ActionIcon>
        <Stack gap={'lg'}>
          <Text variant={'medium'} size={'sm'} c={'text-secondary'}>
            User settings
          </Text>
          <Title order={1} mb="xs">
            User settings
          </Title>
        </Stack>
        <Divider my="sm" />
        <Group align="center">
          <Text variant={'medium'} size={'md'}>
            Personal Information
          </Text>
        </Group>
        <PersonalInformation />
        <Button
          variant={'secondary'}
          w={'fit-content'}
          px={'md'}
          py={'sm'}
          my={'sm'}
          disabled
          onClick={() => {
            // No functionality needed for now
          }}
        >
          Request change
        </Button>
        <Divider my="sm" p={'xs'} />
        <AppearanceMode />
      </Stack>
    </Modal>
  );
};
