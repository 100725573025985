import { useEffect } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';

export const useProductWebsocketListener = (productId: string | undefined) => {
  const socket = useEventStore((state) => state.socket);

  useEffect(() => {
    if (!productId) return;
    socket.emit('subscribe-product', productId);

    return () => {
      socket.emit('unsubscribe-product', productId);
    };
  }, [productId]);
};
