import { PropsWithChildren, forwardRef } from 'react';
import classes from './TreeItem.module.scss';
import cx from 'clsx';

export interface Props {
  onClick?: () => void;
  className?: string;
  color?: string;
  size?: string;
  disabled?: boolean;
}

export const LightWeightIcon = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ onClick, color, className, size, disabled = false, children }, ref) => (
    <div
      ref={ref}
      className={cx(className, classes['lw-icon'])}
      style={{
        color: color,
        width: size,
        height: size,
      }}
      data-disabled={disabled}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {children}
    </div>
  )
);
