import { ActionIcon, NumberInput, Popover } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import classes from './Annotations.module.scss';
import { IconVectorStroke } from '@assemblio/design-system';

export interface Props {
  onChange?: (size: number) => void;
  size?: number;
}

export const SizeSetter = ({ onChange, size }: Props) => {
  return (
    <Popover>
      <Popover.Target>
        <ActionIcon.Group className={classes.controls__target} style={{ alignItems: 'center' }}>
          <ActionIcon c={'text-secondary'} size="sm" variant="transparent">
            <IconVectorStroke />
          </ActionIcon>
          <ActionIcon c={'text-tertiary'} size="xs" variant="transparent">
            <IconChevronDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Popover.Target>
      <Popover.Dropdown>
        <NumberInput
          style={{ width: '80px' }}
          value={size}
          onChange={(value) => {
            if (typeof value === 'number' && onChange) onChange(value);
          }}
          min={0}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
