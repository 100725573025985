import { PropsWithChildren, useEffect, useState } from 'react';
import { ModalPropsIntersectionType, ModalProps } from './types/modalProps.types';
import { AvailableModals } from './ModalRegistry';
import React from 'react';

interface ModalContextType {
  openModal: <T extends keyof typeof AvailableModals>(modalName: T, params: ModalProps<T>) => void;
  closeModal: () => void;
}

export const ModalContext = React.createContext<ModalContextType>({
  openModal: () => null,
  closeModal: () => null,
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [activeModal, setActiveModal] = useState<React.ReactNode | null>(null);
  const [modalParams, setParams] = useState<ModalPropsIntersectionType | null>(null);
  const [modalName, setModalName] = useState<keyof typeof AvailableModals | null>(null);

  const openModal = <T extends keyof typeof AvailableModals>(modalName: T, params: ModalProps<T>) => {
    setModalName(modalName);
    setParams({
      ...(params as ModalPropsIntersectionType),
      onClose: () => {
        params.onClose && params.onClose();
        setActiveModal(null);
      },
      opened: true,
    });
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  useEffect(() => {
    if (modalName && modalParams) {
      const Component: React.FC<any> = AvailableModals[modalName];
      const ComponentWithProps = <Component {...modalParams} />;
      setActiveModal(ComponentWithProps);
    }
  }, [modalName, modalParams]);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {activeModal}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return React.useContext(ModalContext);
};
