import {
  useAnnotationsQuery,
  useAuthStore,
  useInstructionFileQuery,
  useInstructionPartsQuery,
  useInstructionQuery,
  useProductViewQuery,
  useStepGroupsQuery,
} from '@assemblio/frontend/data-access';
import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { ProjectController, UIController, useUIStore } from '@assemblio/frontend/stores';
import { InstructionDto } from '@assemblio/shared/next-types';
import { Flex, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from '../Common/ErrorPage';
import { OwnershipErrorModal } from '../ProjectExplorer/Dialogs/OwnershipErrorModal/OwnershipErrorModal';

interface DependencyLoadingProps {
  instructionData: InstructionDto;
  component: React.ReactNode;
}

type RoutingParams = {
  instructionId: string;
};

export const DependencyLoading = ({ instructionData, component }: DependencyLoadingProps) => {
  const [loaded, setLoaded] = useState(false);

  const { data: annotationData, isError: annotationsError } = useAnnotationsQuery(instructionData.id);
  const { data: partGroupsData, isError: partError } = useInstructionPartsQuery(instructionData.id);
  const { data: gltf, isError: gltfError } = useInstructionFileQuery(instructionData.fileId);

  const sequenceId = instructionData.instructionSequences[0].id;

  const { data: stepGroupsData, isError: stepGroupError } = useStepGroupsQuery({
    sequenceId,
    reverseOrder: false,
  });

  useDocumentTitle('composer', instructionData.name);

  useEffect(() => {
    if (gltf && stepGroupsData && partGroupsData && annotationData && sequenceId) {
      ProjectController.initAfterLoad(
        partGroupsData,
        gltf,
        sequenceId,
        stepGroupsData,
        instructionData,
        annotationData
      );

      UIController.deselectAllParts();
      _.defer(() => UIController.selectLastStepGroup());
      setLoaded(true);
    }
  }, [instructionData, gltf, stepGroupsData, partGroupsData, annotationData, sequenceId]);

  if (loaded) return component;
  else if (annotationsError || partError || gltfError || stepGroupError) return LoadingTransistionErrorPage;
  else
    return (
      <Flex align={'center'} justify={'center'} h={'100%'}>
        <Loader />
      </Flex>
    );
};

interface NextLoadingTransitionProps {
  component: React.ReactNode;
}

type ProductViewMap = {
  [key in 'editor' | 'viewer' | 'renderer']: 'viewer' | 'disassembler' | 'export';
};

const PRODUCT_QUERY_VIEW_MAP: ProductViewMap = {
  viewer: 'viewer',
  editor: 'disassembler',
  renderer: 'export',
};

export const NextLoadingTransition: React.FC<NextLoadingTransitionProps> = ({ component }) => {
  const { instructionId } = useParams<RoutingParams>() as RoutingParams;
  const view = useUIStore((state) => state.view);
  const userId = useAuthStore((state) => state.userId);
  const [schemaVersionConflict, setSchemaVersionConflict] = useState(false);

  const navigate = useNavigate();
  const [ownershipErrorModalOpened, ownershipErrorModalHandler] = useDisclosure(false);

  const accessData = useProductViewQuery({
    productId: instructionId,
    view: PRODUCT_QUERY_VIEW_MAP[view],
  });

  const { data: instructionData, isLoading } = useInstructionQuery(instructionId);

  const handleClose = () => {
    ownershipErrorModalHandler.close();
    navigate('/explorer');
  };

  useEffect(() => {
    if (view === 'editor' && instructionData && instructionData.owner.id !== userId) {
      ownershipErrorModalHandler.open();
    } else if (instructionData) {
      const sequenceSchemaVersion = instructionData.instructionSequences[0].schemaVersion;

      if (sequenceSchemaVersion !== '0.0.2') {
        setSchemaVersionConflict(true);
      }
    }
  }, [instructionData, userId]);

  if (instructionData && ownershipErrorModalOpened) {
    return (
      <OwnershipErrorModal
        open={ownershipErrorModalOpened}
        onClose={handleClose}
        resourceName={instructionData.name}
        resourceId={instructionData.id}
      />
    );
  } else if (schemaVersionConflict) {
    return LoadingTransistionErrorPage;
  } else if (instructionData) {
    return <DependencyLoading instructionData={instructionData} component={component} />;
  } else if (isLoading)
    return (
      <Flex align={'center'} justify={'center'} h={'100%'}>
        <Loader />
      </Flex>
    );
  else return LoadingTransistionErrorPage;
};

const LoadingTransistionErrorPage = (
  <ErrorPage
    title="Unable to initialize project"
    message={
      <Text>
        The selected project could not be opened. <br /> Please go back to the explorer and try opening the project
        again.
      </Text>
    }
  />
);
