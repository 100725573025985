import React, { useEffect, useState } from 'react';
import { Modal, Button, Group, Text, ScrollArea, Stack, Divider } from '@mantine/core';
import { SearchField } from '@assemblio/design-system';
import classes from './ChangeRequestReviewModal.module.scss';
import { UserCard } from './components/UserCard';
import { IconCircleXFilled } from '@tabler/icons-react';
import { useAuthStore, useTenantUsersQuery } from '@assemblio/frontend/data-access';
import { UserDto } from '@assemblio/shared/dtos';
import { useDebouncedValue } from '@mantine/hooks';

interface ChangeRequestReviewModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  action: 'change' | 'request';
  onSubmitUser?: (userId: string) => void;
}

export const ChangeRequestReviewModal = ({
  opened,
  onClose,
  title,
  action,
  onSubmitUser,
}: ChangeRequestReviewModalProps) => {
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);

  const userId = useAuthStore((state) => state.userId);

  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [debouncedSearchString] = useDebouncedValue(searchString, 200);

  const {
    data: userData,
    isFetching,
    refetch,
  } = useTenantUsersQuery({
    exclude: 'viewer',
    searchBy: debouncedSearchString,
  });

  const handleUserSelect = (user: UserDto) => {
    setSelectedUser(user);
  };

  const handleSubmit = () => {
    if (!selectedUser || !onSubmitUser) return;
    onSubmitUser(selectedUser.id);
    onClose();
  };

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [debouncedSearchString]);

  return (
    <Modal
      mb={'lg'}
      opened={opened}
      onClose={onClose}
      title={title}
      centered
      classNames={{
        header: classes.customModalHeader,
        body: classes.customModalBody,
        title: classes.customModalTitle,
      }}
      closeButtonProps={{
        icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} onClick={onClose} />,
      }}
    >
      <Stack mt="sm" gap={0}>
        <Text variant={'medium'} c={'text-primary'} mb={'sm'} px={'lg'}>
          {action === 'request' ? 'Select Reviewer' : 'Change Reviewer'}
        </Text>
        <SearchField
          classNames={{ input: classes.input }}
          value={searchString}
          onChange={(event) => setSearchString(event.currentTarget.value)}
          placeholder="Search reviewers"
          c={'text-secondary'}
        />

        <ScrollArea.Autosize mah={350}>
          {userData?.items
            .filter((user) => user.id !== userId)
            .map((user) => (
              <UserCard
                key={user.id}
                user={user}
                isSelected={selectedUser?.id === user.id}
                onSelect={handleUserSelect}
              />
            ))}
        </ScrollArea.Autosize>
      </Stack>
      <Divider c={'border-secondary'} />
      <Group className={classes.modalFooter} justify="flex-end" px={'lg'} gap={'md'}>
        <Button variant={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!selectedUser} variant={'primary'}>
          {action === 'request' ? 'Request Review' : 'Change Reviewer'}
        </Button>
      </Group>
    </Modal>
  );
};
