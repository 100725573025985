import { Button, Menu } from '@mantine/core';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { BaseEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { ContextMenuItem } from '@assemblio/design-system';
import classes from '../TextEditor.module.scss';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
}

export const HeadingSwitch = ({ editor }: Props) => {
  const [selected, setSelected] = useState('paragraph');

  const getActiveHeading = useCallback(() => {
    if (editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => _.get(n, 'type') === 'heading',
      });
      if (match) {
        if (_.get(match[0], 'level') !== undefined) {
          return `h${_.get(match[0], 'level')}`;
        }
      }
    }

    return 'paragraph';
  }, [editor]);

  useEffect(() => {
    if (editor) {
      const previousOnChange = editor.onChange;
      editor.onChange = () => {
        setSelected(getActiveHeading());
        previousOnChange && previousOnChange();
      };
      return previousOnChange;
    }
    return _.noop;
  }, [editor, getActiveHeading]);

  const setHeading = (heading: string) => {
    setSelected(heading);
    const newProperties = {};
    if (heading === 'paragraph') {
      _.set(newProperties, 'type', 'paragraph');
      editor && Transforms.unsetNodes<SlateElement>(editor, 'level');
    } else {
      if (heading.length === 2 && _.isNumber(+heading[1])) {
        _.set(newProperties, 'type', 'heading');
        _.set(newProperties, 'level', +heading[1]);
      }
    }
    editor && Transforms.setNodes<SlateElement>(editor, newProperties);
  };
  return editor ? (
    <Menu offset={8}>
      <Menu.Target>
        <Button
          className={classes.controls__target}
          rightSection={<IconChevronDown style={{ height: '70%', width: '70%' }} />}
          style={{ cursor: 'pointer' }}
          variant="transparent"
          color={'text-primary'}
          size={'compact-sm'}
        >
          {selected === 'paragraph' ? 'Paragraph' : 'Heading'}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem
          RightIcon={selected === 'paragraph' ? IconCheck : undefined}
          label={'Paragraph'}
          onClick={() => setHeading('paragraph')}
        />
        <ContextMenuItem
          RightIcon={selected === 'h1' ? IconCheck : undefined}
          label={'Heading'}
          onClick={() => setHeading('h1')}
        />
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
