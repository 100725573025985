import { ActionIcon, ColorPicker, Menu } from '@mantine/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { IconChevronDown } from '@tabler/icons-react';
import { ASSEMBLIO_PART_COLORS } from '@assemblio/frontend/types';
import { IconTextColour } from '@assemblio/design-system';
import classes from '../TextEditor.module.scss';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
  color: string;
}

export const TextColorPicker = ({ editor, color }: Props) => {
  const [currentColor, setCurrentColor] = useState(color);

  const getMarkState = (editor: Editor): string | undefined => {
    const marks = Editor.marks(editor);
    return marks ? _.get(marks, 'color') : undefined;
  };

  const setColor = (color: string) => {
    if (editor) {
      const state = getMarkState(editor);
      if (state) {
        Editor.removeMark(editor, 'color');
      }
      Editor.addMark(editor, 'color', color);
    }
  };

  useEffect(() => {
    if (editor) {
      const previousOnChange = editor.onChange;
      editor.onChange = () => {
        const state = getMarkState(editor);
        if (state) {
          setCurrentColor(state);
        }
        previousOnChange && previousOnChange();
      };
      return previousOnChange;
    }
    return _.noop;
  }, [editor]);

  return editor ? (
    <Menu offset={8}>
      <Menu.Target>
        <ActionIcon.Group className={classes.controls__target} style={{ alignItems: 'center' }}>
          <ActionIcon c={'text-secondary'} size="sm" variant="transparent">
            <IconTextColour />
          </ActionIcon>
          <ActionIcon c={'text-tertiary'} size="xs" variant="transparent">
            <IconChevronDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Menu.Target>
      <Menu.Dropdown>
        <ColorPicker
          onMouseDown={(e) => e.preventDefault()}
          format="hex"
          onChange={setColor}
          value={currentColor}
          swatchesPerRow={6}
          swatches={ASSEMBLIO_PART_COLORS.map((color) => color.code.hex)}
        />
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
