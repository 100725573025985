import { axiosHandler } from '../utils/axios-utils';
import { ArtefactCountDto, ArtefactDto } from '@assemblio/shared/dtos';
import { FetchProductArtefacts } from './types/artefact.request.types';

export const deleteArtefact = async (instructionId: string, artefactId: string): Promise<number> => {
  return axiosHandler('delete', `/instructions/${instructionId}/artefacts/${artefactId}`);
};

export const fetchArtefactsForInstruction = async (instructionId: string): Promise<Array<ArtefactDto>> => {
  return axiosHandler('get', `instructions/${instructionId}/artefacts`);
};

export const fetchV1ArtefactsForProduct = async (data: FetchProductArtefacts): Promise<Array<ArtefactDto>> => {
  return typeof data.productId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `v1/instructions/${data.productId}/artefacts`, { params: data.queryData });
};

export const downloadArtefact = async (instructionId: string, artefactId: string): Promise<Blob> => {
  return axiosHandler('get', `/instructions/${instructionId}/artefacts/${artefactId}`, { responseType: 'blob' });
};

export const fetchArtefactCount = async (instructionId: string | undefined): Promise<ArtefactCountDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `v1/instructions/${instructionId}/artefacts/count`);
};
