import { Button, Checkbox, Combobox, Group, useCombobox } from '@mantine/core';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { useState } from 'react';
import produce from 'immer';
import { FilterItem } from './components/FilterItem/FilterItem';
import { FilterOption } from '../../types';

interface FilterProps {
  filters: FilterOption[];
  previewFilterOptionCount?: number;
}

export const Filter = ({ filters, previewFilterOptionCount = 2 }: FilterProps) => {
  const [activeFilters, setActiveFilters] = useState<string[]>(
    filters.slice(0, previewFilterOptionCount).map((filter) => {
      return filter.value;
    })
  );

  const toggleFilter = (filter: string) => {
    setActiveFilters(
      produce<string[]>(activeFilters, (draft) => {
        const filterIndex = draft.findIndex((item) => item === filter);
        if (filterIndex !== -1) {
          draft.splice(filterIndex, 1);
        } else {
          draft.push(filter);
        }
      })
    );
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = filters.map((filter) => (
    <Combobox.Option value={filter.value} key={filter.value}>
      <Group>
        <Checkbox readOnly checked={activeFilters.findIndex((found) => found === filter.value) >= 0} />
        {filter.Icon && <filter.Icon />}
        <span>{filter.label}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <Group mr={'auto'}>
      {filters
        .filter((filter) => activeFilters.includes(filter.value))
        .map((filter) => {
          return <FilterItem key={filter.value} filter={filter} />;
        })}
      <Combobox
        store={combobox}
        width={250}
        position="bottom-start"
        withArrow
        withinPortal={false}
        positionDependencies={[activeFilters]}
        onOptionSubmit={(val) => toggleFilter(val)}
      >
        <Combobox.Target>
          <Button
            variant={'secondary'}
            leftSection={<IconCirclePlusFilled />}
            onClick={() => combobox.toggleDropdown()}
          >
            Add filter
          </Button>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Group>
  );
};
