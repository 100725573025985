import { ScrollArea } from '@mantine/core';
import { ExplorerFolderListItem } from './components/ExplorerFolderListItem/ExplorerFolderListItem';
import { ExplorerProjectListItem } from './components/ExplorerProjectListItem/ExplorerProjectListItem';
import { FolderContentDto } from '@assemblio/shared/dtos';

interface PopulatedFolderListProps {
  currentFolder: FolderContentDto;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const PopulatedFolderList = ({ currentFolder, onFolderNavigation }: PopulatedFolderListProps) => {
  return (
    <ScrollArea>
      {currentFolder.children?.map((sF, index) => (
        <ExplorerFolderListItem key={`folder-list-item-${index}`} folder={sF} onFolderNavigation={onFolderNavigation} />
      ))}
      {currentFolder.projects?.map((project, index) => (
        <ExplorerProjectListItem key={`project-list-item-${index}`} project={project} />
      ))}
    </ScrollArea>
  );
};
