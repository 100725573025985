export const IconLine = () => {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.533203 0.999952C0.533203 0.558124 0.891375 0.199951 1.3332 0.199951H10.6665C11.1084 0.199951 11.4665 0.558124 11.4665 0.999952C11.4665 1.44178 11.1084 1.79995 10.6665 1.79995H1.3332C0.891375 1.79995 0.533203 1.44178 0.533203 0.999952Z"
        fill="currentColor"
      />
    </svg>
  );
};
