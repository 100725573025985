import { useViewerInfoQuery } from '@assemblio/frontend/data-access';
import { Button, Group, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface OwnershipErrorModalProps {
  resourceName: string;
  resourceId: string;
  open: boolean;
  onClose: () => unknown;
}

export const OwnershipErrorModal = ({ resourceName, resourceId, open, onClose }: OwnershipErrorModalProps) => {
  const { data: kimInfo } = useViewerInfoQuery();

  const handleTransistionToKIM = () => {
    if (kimInfo) {
      window.open(`${kimInfo.baseURL}/assembler/${resourceId}`, '_blank');
    } else {
      notifications.show({
        id: 'reroute-to-kim',
        message: 'Something went wrong. Please try again later',
        color: 'red',
      });
    }
    onClose();
  };

  return (
    <Modal opened={open} withCloseButton onClose={onClose} onClick={(e) => e.stopPropagation()} centered withinPortal>
      <Text fw={700} mb={'lg'}>
        Not allowed
      </Text>
      <Text size="sm" mb={'xl'}>
        Only the owner can edit a product and you are currently not the owner of <b>{resourceName}</b>. {<br />} You can
        still view this instruction using KIM. Open in KIM will open a new tab in your browser.
      </Text>

      <Group align="flex-start" justify="left">
        <Button onClick={() => handleTransistionToKIM()}>Open in KIM</Button>
        <Button variant={'outline'} onClick={() => onClose()}>
          Cancel
        </Button>
      </Group>
    </Modal>
  );
};
