import { ActionIcon, ColorPicker, ColorSwatch, Popover, SimpleGrid } from '@mantine/core';
import { ASSEMBLIO_PART_COLORS } from '@assemblio/frontend/types';
import { IconChevronDown } from '@tabler/icons-react';
import classes from './Annotations.module.scss';

export interface Props {
  onChange: (color: string, persist: boolean) => void;
  color: string;
  targetSwatchWithBorder?: boolean;
}

export const ColorChooser = ({ onChange, color, targetSwatchWithBorder = false }: Props) => {
  return (
    <Popover>
      <Popover.Target>
        <ActionIcon.Group className={classes.controls__target} style={{ alignItems: 'center' }}>
          {targetSwatchWithBorder ? (
            <ActionIcon
              style={{
                borderRadius: '50%',
                backgroundColor: 'var(--elevation-quaternary)',
                border: 'var(--border-quaternary)',
              }}
              size="sm"
              variant="transparent"
            >
              <ColorSwatch
                className={classes.swatch_target}
                withShadow={false}
                color={color}
                size={14}
                radius={'100%'}
              />
            </ActionIcon>
          ) : (
            <ActionIcon size="md" variant="transparent">
              <ColorSwatch
                className={classes.swatch_target}
                withShadow={false}
                color={color}
                size={20}
                radius={'100%'}
              />
            </ActionIcon>
          )}
          <ActionIcon c={'text-tertiary'} size="xs" variant="transparent">
            <IconChevronDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Popover.Target>
      <Popover.Dropdown>
        <ColorPicker
          format={'rgba'}
          onChange={(value) => onChange(value, false)}
          onChangeEnd={(value) => onChange(value, true)}
          value={color}
          defaultValue="rgba(253, 126, 20, 1)"
          swatchesPerRow={6}
          swatches={ASSEMBLIO_PART_COLORS.map((color) => color.code.hex)}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
