import { Header, Sidebar } from './layout';
import { AppShell, SimpleGrid, useMantineTheme } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import classes from './Explorer.module.scss';
import { useState } from 'react';
import { ExplorerTitleProvider } from './providers/ExplorerTitleProvider';
import { FileMetaData } from '@assemblio/frontend/hooks';

interface ExplorerProps {
  uploadFile?: (file: File, meta: FileMetaData) => Promise<boolean>;
  removeFile?: () => void;
  uploadReady?: boolean;
}

export const Explorer = ({ uploadReady, uploadFile, removeFile }: ExplorerProps) => {
  const theme = useMantineTheme();
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  return (
    <ExplorerTitleProvider>
      <AppShell
        layout={'alt'}
        navbar={{
          breakpoint: 'xs',
          width: collapsed ? theme.other.sidebarWidth_collapsed : theme.other.sidebarWidth,
        }}
        classNames={{
          root: classes.root,
          navbar: classes.navbar,
        }}
      >
        <Sidebar collapsed={collapsed} onToggleCollapse={handleToggleCollapse} />

        <AppShell.Main className={classes['content-container']}>
          <SimpleGrid verticalSpacing={0} className={classes.main_grid} h={'100%'} cols={1}>
            <Header removeFile={removeFile} uploadFile={uploadFile} uploadReady={uploadReady} />

            <div className={classes.content_wrapper}>
              <Outlet />
            </div>
          </SimpleGrid>
        </AppShell.Main>
      </AppShell>
    </ExplorerTitleProvider>
  );
};
