import { ExplorerItemType } from '../../../types/items.types';
import { useState } from 'react';
import { useFolderUpdate, useNextProjectUpdate } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../../../../hooks/ExplorerRouteParams.hook';

export const useRenameItem = () => {
  const { folderId } = useExplorerRouteParams();

  const [isLoading, setIsLoading] = useState(false);

  const renameFolderMutation = useFolderUpdate();

  const renameProjectMutation = useNextProjectUpdate();

  const renameItem = (name: string, itemId: string, type: ExplorerItemType) => {
    if (type === ExplorerItemType.Folder) renameFolder(itemId, name);
    if (type === ExplorerItemType.Project) renameProject(itemId, name);
  };

  const renameProject = (itemId: string, name: string) => {
    if (!folderId) return;
    setIsLoading(true);
    renameProjectMutation.mutate(
      {
        projectId: itemId,
        projectData: {
          projectId: itemId,
          name,
          folderId,
        },
      },
      {
        onSettled: () => setIsLoading(false),
      }
    );
  };

  const renameFolder = (itemId: string, name: string) => {
    if (!folderId) return;
    setIsLoading(true);
    renameFolderMutation.mutate(
      {
        parentFolderId: folderId,
        folderId: itemId,
        folderData: {
          name,
          color: '',
          icon: '',
        },
      },
      {
        onSettled: () => setIsLoading(false),
      }
    );
  };

  return { renameItem, isLoading };
};
