import { HorizontalAlignment, VerticalAlignment } from '@assemblio/type/annotation';
import { ActionIcon, Menu } from '@mantine/core';
import { BaseEditor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import {
  IconAlignBoxLeftBottom,
  IconAlignBoxLeftMiddle,
  IconAlignBoxLeftTop,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconCheck,
  IconChevronDown,
} from '@tabler/icons-react';
import { ContextMenuItem } from '@assemblio/design-system';
import classes from '../TextEditor.module.scss';

interface Props {
  editor: (BaseEditor & ReactEditor) | null;
  verticalChange?: (position: VerticalAlignment) => void;
  verticalAlignment?: VerticalAlignment;
  horizontalAlignment: HorizontalAlignment;
}

export const AlignmentSwitch = ({ editor, verticalChange, verticalAlignment, horizontalAlignment }: Props) => {
  const setAlignment = (alignment: HorizontalAlignment) => {
    editor && Transforms.setNodes(editor, { align: alignment });
  };

  const getIcon = (value: string) => {
    switch (value) {
      case 'right':
        return IconAlignRight;
      case 'center':
        return IconAlignCenter;
      case 'top':
        return IconAlignBoxLeftTop;
      case 'middle':
        return IconAlignBoxLeftMiddle;
      case 'bottom':
        return IconAlignBoxLeftBottom;
      default:
        return IconAlignLeft;
    }
  };
  const TargetIcon = getIcon(horizontalAlignment);

  return editor ? (
    <Menu position="bottom" offset={8}>
      <Menu.Target>
        <ActionIcon.Group className={classes.controls__target} style={{ alignItems: 'center' }}>
          <ActionIcon c={'text-secondary'} size="sm" variant="transparent">
            <TargetIcon />
          </ActionIcon>
          <ActionIcon c={'text-tertiary'} size="xs" variant="transparent">
            <IconChevronDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Horizontal</Menu.Label>
        <ContextMenuItem
          RightIcon={horizontalAlignment === 'left' ? IconCheck : undefined}
          onClick={() => setAlignment('left')}
          LeftIcon={getIcon('left')}
          label={'Left'}
        />
        <ContextMenuItem
          RightIcon={horizontalAlignment === 'center' ? IconCheck : undefined}
          onClick={() => setAlignment('center')}
          LeftIcon={getIcon('center')}
          label={'Center'}
        />
        <ContextMenuItem
          RightIcon={horizontalAlignment === 'right' ? IconCheck : undefined}
          onClick={() => setAlignment('right')}
          LeftIcon={getIcon('right')}
          label={'Right'}
        />
        {verticalAlignment && (
          <>
            <Menu.Divider />
            <Menu.Label>Vertical</Menu.Label>

            <ContextMenuItem
              RightIcon={verticalAlignment === 'top' ? IconCheck : undefined}
              onClick={() => verticalChange && verticalChange('top')}
              LeftIcon={getIcon('top')}
              label={'top'}
            />
            <ContextMenuItem
              RightIcon={verticalAlignment === 'middle' ? IconCheck : undefined}
              onClick={() => verticalChange && verticalChange('middle')}
              LeftIcon={getIcon('middle')}
              label={'middle'}
            />
            <ContextMenuItem
              RightIcon={verticalAlignment === 'bottom' ? IconCheck : undefined}
              onClick={() => verticalChange && verticalChange('bottom')}
              LeftIcon={getIcon('bottom')}
              label={'bottom'}
            />
          </>
        )}

        {/*{verticalAlignment && (*/}
        {/*  <SegmentedControl*/}
        {/*    value={verticalAlignment}*/}
        {/*    onChange={(value) => verticalChange && verticalChange(value as VerticalAlignment)}*/}
        {/*    data={[*/}
        {/*      {*/}
        {/*        value: 'top',*/}
        {/*        label: getIcon('top'),*/}
        {/*      },*/}
        {/*      {*/}
        {/*        value: 'middle',*/}
        {/*        label: getIcon('middle'),*/}
        {/*      },*/}
        {/*      {*/}
        {/*        value: 'bottom',*/}
        {/*        label: getIcon('bottom'),*/}
        {/*      },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*)}*/}
      </Menu.Dropdown>
    </Menu>
  ) : null;
};
