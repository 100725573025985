export const IconInvisible = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.84562 1.04633C2.40145 0.747259 3.09449 0.955384 3.39358 1.51121C3.61737 1.92712 3.90921 2.31103 4.25706 2.64941C4.28712 2.67509 4.31608 2.70255 4.34382 2.73178C5.26512 3.5856 6.55298 4.12876 8.00062 4.12876C9.44357 4.12876 10.7278 3.5891 11.6485 2.74005C11.6813 2.70482 11.7158 2.6721 11.7519 2.64192C12.0963 2.30537 12.3855 1.92406 12.6077 1.51121C12.9067 0.955384 13.5998 0.747259 14.1557 1.04633C14.7114 1.34542 14.9195 2.03846 14.6205 2.59429C14.4705 2.87288 14.3016 3.14018 14.1153 3.39482L15.5912 4.63177C16.075 5.0372 16.1385 5.75802 15.7331 6.24178C15.3277 6.72554 14.6069 6.78904 14.1231 6.38362L12.4778 5.00476C12.0709 5.29408 11.6317 5.54436 11.1668 5.75038L11.8717 7.52556C12.1046 8.11218 11.8178 8.77653 11.2312 9.00956C10.6446 9.24247 9.98021 8.95573 9.74727 8.36909L8.94873 6.35801C8.63784 6.39529 8.32136 6.41447 8.00062 6.41447C7.67819 6.41447 7.36007 6.39509 7.04762 6.35743L6.24885 8.36909C6.01591 8.95573 5.35152 9.24247 4.76489 9.00956C4.17826 8.77653 3.89154 8.11218 4.12448 7.52556L4.83011 5.74845C4.36656 5.54269 3.92864 5.29292 3.5228 5.0043L1.87697 6.38362C1.39321 6.78904 0.672384 6.72554 0.26696 6.24178C-0.138464 5.75802 -0.0749593 5.0372 0.4088 4.63177L1.88547 3.39422C1.69935 3.13976 1.53055 2.87266 1.38075 2.59429C1.08167 2.03846 1.28981 1.34542 1.84562 1.04633Z"
        fill="currentColor"
      />
    </svg>
  );
};
