import React, { useState } from 'react';
import { Modal, Button, Group, Text, Stack, Divider, ScrollArea } from '@mantine/core';
import classes from './AddUsersModal.module.scss';
import { AddUserInput } from './components/AddUserInput';

interface UserInput {
  email: string;
  role: string;
}

interface AddUsersModalProps {
  opened: boolean;
  onClose: () => void;
  onAddUsers: (users: UserInput[]) => void;
}

const roleOptions = [
  { value: 'Viewer', label: 'Viewer' },
  { value: 'Editor', label: 'Editor' },
  { value: 'Admin', label: 'Admin' },
];
const numOfLicense = 5;

export const AddUsersModal = ({ opened, onClose, onAddUsers }: AddUsersModalProps) => {
  const [users, setUsers] = useState<UserInput[]>([{ email: '', role: 'Viewer' }]);
  const [errors, setErrors] = useState<string[]>(['']);

  const handleAddUser = () => {
    setUsers([...users, { email: '', role: 'Viewer' }]);
    setErrors([...errors, '']);
  };

  const handleChange = (index: number, field: keyof UserInput, value: string) => {
    const newUsers = [...users];
    newUsers[index][field] = value;
    setUsers(newUsers);

    if (field === 'email') {
      const newErrors = [...errors];
      newErrors[index] = validateEmail(value) ? '' : 'Invalid email address';
      setErrors(newErrors);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    onAddUsers(users.filter((user) => validateEmail(user.email)));
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Add users"
      classNames={{
        content: classes.modalContent,
        body: classes.modalBody,
        title: classes.modalTitle,
      }}
    >
      {/* <Divider /> */}
      <Stack px="md" pb="lg">
        <Text size="md">
          Users will receive an invite email including a link to your Assemblio space and instructions on setting up
          their account.
        </Text>
        <Text size="md">Enter emails:</Text>
        <ScrollArea.Autosize maw={400}>
          {users.map((user, index) => (
            <AddUserInput
              key={index}
              user={user}
              index={index}
              roleOptions={roleOptions}
              error={errors[index]}
              handleChange={handleChange}
            />
          ))}
        </ScrollArea.Autosize>
        <Group justify="space-between" pt={'md'}>
          <Button variant="secondary" onClick={handleAddUser}>
            Invite more users
          </Button>
          <Text size="md" c="text-secondary">
            {numOfLicense} licenses remaining
          </Text>
        </Group>
      </Stack>
      <Divider my="sm" />
      <Group my="md" px={'lg'} justify="end">
        <Button variant="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="secondary" onClick={handleSubmit}>
          Add users
        </Button>
      </Group>
    </Modal>
  );
};
