export const IconVisible = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32901 2.61287C4.60936 1.58809 6.21681 0.714355 8 0.714355C9.78319 0.714355 11.3906 1.58809 12.671 2.61287C13.9582 3.64311 14.9762 4.87506 15.5919 5.7016L15.5966 5.70797C15.8634 6.0769 16 6.53777 16 7.00007C16 7.46237 15.8634 7.9232 15.5966 8.29214L15.5919 8.29857C14.9762 9.12511 13.9582 10.357 12.671 11.3873C11.3906 12.4121 9.78319 13.2858 8 13.2858C6.21681 13.2858 4.60936 12.4121 3.32901 11.3873C2.04183 10.357 1.02373 9.12508 0.408145 8.29854L0.403402 8.29217C0.13652 7.92324 0 7.46237 0 7.00007C0 6.53777 0.136565 6.07694 0.403447 5.708L0.408101 5.70157C1.02369 4.87503 2.04183 3.64311 3.32901 2.61287ZM10.5714 7.00007C10.5714 8.42023 9.42016 9.5715 8 9.5715C6.57984 9.5715 5.42857 8.42023 5.42857 7.00007C5.42857 5.57991 6.57984 4.42864 8 4.42864C9.42016 4.42864 10.5714 5.57991 10.5714 7.00007Z"
        fill="currentColor"
      />
    </svg>
  );
};
