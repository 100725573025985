export const IconCaretUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="16" width="16">
      <g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 16 16)">
        <path
          d="M7.6 9.46667C7.8 9.73333 8.2 9.73333 8.4 9.46667L10.4 6.8C10.6472 6.47038 10.412 6 10 6L6 6C5.58798 6 5.35279 6.47038 5.6 6.8L7.6 9.46667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
