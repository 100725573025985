import { Breadcrumbs } from '@mantine/core';
import classes from '../BreadcrumbNavigation.module.scss';
import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadcrumbItemType } from '../types/BreadcrumbNavigation.types';

interface BreadcrumbListProps {
  items: BreadcrumbItemType[];
}

export const BreadCrumbList = ({ items }: BreadcrumbListProps) => {
  return (
    <Breadcrumbs
      classNames={{
        root: classes.breadcrumbs,
        separator: classes.separator,
      }}
    >
      {items.map((item) => (
        <BreadcrumbItem key={`breadcrumb-${item.name}`} name={item.name} navigationTarget={item.navigationTarget} />
      ))}
    </Breadcrumbs>
  );
};
