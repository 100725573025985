import { Button, Menu, Text } from '@mantine/core';
import { ContextMenuItem } from '@assemblio/design-system';
import { IconCheck, IconChevronDown, IconSortDescending } from '@tabler/icons-react';
import { useFolderDisplayMode } from '../../hooks/folderDisplayMode.hook';
import { SortingOption } from '../../types';

interface SortMenuProps<T extends string | undefined> {
  sortOptions: SortingOption<T>[];
  onSelectSortBy: (sortBy: string) => void;
  sortBy?: string;
  showFolderDisplay?: boolean;
}

export const SortMenu = <T extends string | undefined>({
  sortOptions,
  sortBy,
  onSelectSortBy,
  showFolderDisplay = false,
}: SortMenuProps<T>) => {
  const { view, setView } = useFolderDisplayMode();

  const handleFolderDisplay = (sort: 'mixed' | 'separate') => {
    setView(sort);
  };
  return (
    <Menu width={'200'} closeOnItemClick={false} trigger={'click'} withinPortal position="bottom">
      <Menu.Target>
        <Button
          leftSection={<IconSortDescending style={{ height: '70%', width: '70%' }} />}
          rightSection={<IconChevronDown style={{ height: '70%', width: '70%' }} />}
          variant={'secondary'}
        >
          Sort
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Text size={'xs'} variant={'medium'}>
            SORT BY
          </Text>
        </Menu.Label>
        {sortOptions.map((option) => (
          <ContextMenuItem
            key={option.value}
            label={option.label}
            onClick={() => onSelectSortBy(option.value)}
            RightIcon={sortBy === option.value ? IconCheck : undefined}
          />
        ))}
        {showFolderDisplay && (
          <>
            <Menu.Divider />
            <Menu.Label>
              <Text size={'xs'} variant={'medium'}>
                FOLDERS
              </Text>
            </Menu.Label>
            <ContextMenuItem
              key={'folder-sort-separate'}
              label={'Show on top'}
              onClick={() => handleFolderDisplay('separate')}
              RightIcon={view === 'separate' ? IconCheck : undefined}
            />
            <ContextMenuItem
              key={'folder-sort-mixed'}
              label={'Mixed with products'}
              onClick={() => handleFolderDisplay('mixed')}
              RightIcon={view === 'mixed' ? IconCheck : undefined}
            />
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
