import { MachineController, UIController } from '@assemblio/frontend/stores';
import { Part } from '@assemblio/type/input';
import cx from 'clsx';
import ToggableTextInput from './ToggableTextInput';
import classes from './TreeItem.module.scss';
import { TreeItemActions } from './TreeItemActions';
import useLeafSubscription from './useLeafSubscription';
import { Tree } from '../types/hierarchy.types';
import { IconCube } from '@assemblio/design-system';
import { LightWeightIcon } from './LightWeightIcon';

export const HierarchyLeaf = ({ data }: Tree) => {
  const part = data as Part;
  const { selected, partInformation, elementId } = useLeafSubscription(part);

  const select = (gltfIndex: number) => {
    MachineController.selectTransition(gltfIndex);
  };
  return (
    <div
      key={`part-${part.gltfIndex}`}
      className={cx(classes.itemWrapper, {
        [classes.selected]: selected,
      })}
      data-content={partInformation?.name}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (e.button === 0) {
          select(part.gltfIndex);
        }
      }}
      onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        UIController.setPartContext(part.gltfIndex);
      }}
    >
      <LightWeightIcon className={classes.leaf_icon}>
        <IconCube />
      </LightWeightIcon>
      <div className={classes.mid}>
        <ToggableTextInput
          elementId={elementId}
          isLeaf={true}
          gltfIndex={part.gltfIndex}
          name={partInformation !== undefined ? `${partInformation.name}` : ''}
          visible={partInformation !== undefined && partInformation.visible}
          excluded={partInformation !== undefined && partInformation.excluded}
        />
      </div>
      {partInformation && !partInformation.excluded && (
        <div
          className={cx(classes.rhs, {
            [classes.rhs__selected]: selected || (partInformation !== undefined && partInformation.visible),
          })}
        >
          <TreeItemActions
            isLeaf={true}
            assembly={part}
            visible={partInformation !== undefined && partInformation.visible}
            transparent={partInformation !== undefined && partInformation.transparent}
          />
        </div>
      )}
    </div>
  );
};
