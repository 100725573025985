import { useModalContext } from '@assemblio/frontend/components';
import { useSyncProfilesQuery } from '@assemblio/frontend/data-access';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { SyncProfileState } from '@assemblio/shared/next-types';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { Box, Button, Divider, Group, Text } from '@mantine/core';
import React from 'react';
import { useHasFeatureFlag } from '../../../../hooks/HasFeatureFlag.hook';
import classes from './ActionBar.module.scss';
import { useProductActions } from './hooks/ProductActions.hook';
import { useOpenProduct } from '../../../../hooks';

interface ActionBarProps {
  product: ProductContentDto | undefined;
  isLoading?: boolean;
  isOwner?: boolean;
}

export const ActionBar = ({ isLoading = false, isOwner, product }: ActionBarProps) => {
  const { data: syncProfileData } = useSyncProfilesQuery(product?.id);
  const variantsEnabled = useHasFeatureFlag(FeatureFlag.Variants);
  const { openModal } = useModalContext();
  const [onOpenProduct] = useOpenProduct();
  const { productActions, isLoading: isMutationLoading } = useProductActions(product, syncProfileData, variantsEnabled);

  const showSyncButton = syncProfileData?.some((profile) => profile.to.id === product?.id);

  const syncableProfiles = syncProfileData?.filter((profile) => profile.to.id === product?.id);

  const showResolveSyncButton =
    variantsEnabled && syncableProfiles?.some((profile) => profile.state === SyncProfileState.UNRESOLVED);

  const handleOpenProduct = () => {
    if (!product) return;
    onOpenProduct(product, product.project.id);
  };

  const handleSync = () => {
    if (!product) return;
    openModal('execute-sync-modal', {
      productId: product.id,
      productName: product.name,
      syncableProfiles: syncableProfiles?.map((profile) => profile.from) ?? [],
    });
  };

  const handleResolveSyncIssues = () => {
    if (!product) return;
    const syncRelation = syncableProfiles?.find((profile) => profile.state === SyncProfileState.UNRESOLVED);
    if (!syncRelation) return;
    openModal('resolve-sync-issues-modal', {
      syncRelation,
    });
  };

  return (
    <Box className={classes.container} p="sm">
      <Group gap={'sm'}>
        <>
          {productActions.map((action) => (
            <React.Fragment key={`product-action-${action.label}`}>
              <Button
                classNames={{
                  section: classes.action_button_icon,
                }}
                disabled={isLoading || isMutationLoading || action.disabled}
                onClick={action.onClick}
                leftSection={<action.Icon />}
                variant="ghost"
                size={'compact-md'}
                p="sm"
              >
                <Text size="sm" c={action.disabled ? 'text-disabled' : 'text-primary'} fw="bold">
                  {action.label}
                </Text>
              </Button>
              <Divider orientation="vertical" size="xs" h="16" className={classes.verticalDivider} />
            </React.Fragment>
          ))}
        </>
      </Group>
      <Group>
        {showSyncButton && !showResolveSyncButton && (
          <Button size={'compact-md'} onClick={handleSync} variant={'secondary'}>
            Sync product
          </Button>
        )}
        {showResolveSyncButton && (
          <Button size={'compact-md'} onClick={handleResolveSyncIssues} variant={'secondary'}>
            Resolve issues
          </Button>
        )}
        <Button size={'compact-md'} onClick={handleOpenProduct} variant={'primary'}>
          Open Assembly Plan
        </Button>
      </Group>
    </Box>
  );
};
