import { useLogout } from '@assemblio/frontend/hooks';
import { ActionIcon, Menu } from '@mantine/core';
import { IconUserFilled } from '@tabler/icons-react';
import { ContextMenuItem, IconLogOut, IconUserSettings } from '@assemblio/design-system';
import React from 'react';
import { useUserSettings } from '@assemblio/frontend/explorer';

export const UserSettingsMenu = () => {
  const { openUserSettings, closeUserSettings, settingsOpened } = useUserSettings();
  const logout = useLogout();

  const toggleMenu = settingsOpened ? closeUserSettings : openUserSettings;
  return (
    <Menu width={232} position="bottom-start">
      <Menu.Target>
        <ActionIcon variant={'ghost'}>
          <IconUserFilled />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown py={'sm'}>
        <ContextMenuItem px={'xs'} LeftIcon={IconUserSettings} label="Settings" onClick={toggleMenu} />
        <Menu.Divider />
        <ContextMenuItem px={'xs'} LeftIcon={IconLogOut} label="Logout" onClick={logout} />
      </Menu.Dropdown>
    </Menu>
  );
};
