import { IconChevronRight } from '@tabler/icons-react';
import { Group, Text, Tooltip } from '@mantine/core';
import classes from './ItemPathDisplay.module.scss';

interface ItemPathDisplayProps {
  folder: string;
  project: string;
}

/**
 * Displays the path of an item. Should be used to display the path of an item on a card.
 */
export const ItemPathDisplay = ({ folder, project }: ItemPathDisplayProps) => {
  return (
    <Group wrap={'nowrap'} gap={'xs'} className={classes.path_wrapper}>
      <Tooltip label={folder} openDelay={500}>
        <Text size={'sm'} lineClamp={1}>
          {folder}
        </Text>
      </Tooltip>
      <IconChevronRight size={12} />
      <Tooltip label={project} openDelay={500}>
        <Text size={'sm'} lineClamp={1}>
          {project}
        </Text>
      </Tooltip>
    </Group>
  );
};
