import cx from 'clsx';
import { IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon } from '@mantine/core';
import classes from './ContextMenuButton.module.scss';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

interface ContextMenuButtonProps extends ComponentPropsWithoutRef<'button'> {
  onListItem?: boolean;
}

export const ContextMenuButton = forwardRef<HTMLButtonElement, ContextMenuButtonProps>(
  ({ onListItem = false, ...others }: ContextMenuButtonProps, ref) => {
    return (
      <ActionIcon
        ref={ref}
        data-cy="icon-dots-vertical"
        c="text-primary"
        size={'xs'}
        className={cx(classes.menuButton, {
          [classes['explorerCard__menuButton--list']]: onListItem,
        })}
        variant="transparent"
        {...others}
      >
        <IconDotsVertical />
      </ActionIcon>
    );
  },
);
