import { axiosBaseRequest, useAuthStore } from '@assemblio/frontend/data-access';
import { EventController } from '@assemblio/frontend/stores';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const navigate = useNavigate();
  const clearSession = useAuthStore((state) => state.clearSession);

  return async () => {
    await axiosBaseRequest({
      url: '/auth/sign-out',
      method: 'get',
      withCredentials: true,
    });
    EventController.disconnectSocket();
    clearSession();
    navigate('/login');
  };
};
