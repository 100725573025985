import { Combobox, InputBase, useCombobox, Input, ComboboxItem, ThemeIcon } from '@mantine/core';
import classes from '../../ExecuteSynchronisationModal.module.scss';
import { IconChevronDown, IconChevronUp, IconFileFilled } from '@tabler/icons-react';

interface VariantSelectionProps {
  value: string;
  setValue: (id: string) => void;
  options: ComboboxItem[];
}

export const VariantSelection = ({ value, setValue, options }: VariantSelectionProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const selectedOption = options.find((option) => option.value === value);

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        setValue(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          classNames={{
            input: classes.variant_select_input,
            section: classes.input_section,
          }}
          pointer
          leftSection={
            <ThemeIcon variant={'transparent'} size={'xs'} c={'text-primary'}>
              <IconFileFilled />{' '}
            </ThemeIcon>
          }
          rightSection={
            combobox.dropdownOpened ? (
              <ThemeIcon variant={'transparent'} size={'xs'} c={'text-secondary'}>
                <IconChevronUp />
              </ThemeIcon>
            ) : (
              <ThemeIcon variant={'transparent'} size={'xs'} c={'text-secondary'}>
                <IconChevronDown />
              </ThemeIcon>
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
        >
          {selectedOption?.label || <Input.Placeholder>Pick value</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.map((item) => (
            <Combobox.Option value={item.value} key={item.value}>
              {item.label}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
