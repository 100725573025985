export const IconFastForward = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85958 0.173097L15.7166 4.17303C15.8029 4.22334 15.8746 4.2954 15.9243 4.38203C15.9741 4.46866 16.0003 4.56683 16.0003 4.66674C16.0003 4.76666 15.9741 4.86482 15.9243 4.95145C15.8746 5.03808 15.8029 5.11014 15.7166 5.16045L8.85958 9.16039C8.77274 9.21106 8.67407 9.23792 8.57352 9.23826C8.47298 9.2386 8.37413 9.21241 8.28695 9.16233C8.19976 9.11225 8.12734 9.04005 8.07699 8.95302C8.02663 8.86599 8.00014 8.76722 8.00016 8.66668V0.666804C8.00014 0.56626 8.02663 0.467489 8.07699 0.380463C8.12734 0.293436 8.19976 0.221236 8.28695 0.171153C8.37413 0.12107 8.47298 0.0948768 8.57352 0.0952181C8.67407 0.0955595 8.77274 0.122423 8.85958 0.173097Z"
        fill="currentColor"
      />
      <path
        d="M0.859415 0.173097L7.71645 4.17303C7.80278 4.22334 7.8744 4.2954 7.92418 4.38203C7.97397 4.46866 8.00016 4.56683 8.00016 4.66674C8.00016 4.76666 7.97397 4.86482 7.92418 4.95145C7.8744 5.03808 7.80278 5.11014 7.71645 5.16045L0.859415 9.16039C0.772575 9.21106 0.673903 9.23792 0.57336 9.23826C0.472817 9.2386 0.373963 9.21241 0.286781 9.16233C0.199599 9.11225 0.127174 9.04005 0.0768214 8.95302C0.0264683 8.86599 -2.97733e-05 8.76722 2.51057e-08 8.66668V0.666804C-2.97733e-05 0.56626 0.0264683 0.467489 0.0768214 0.380463C0.127174 0.293436 0.199599 0.221236 0.286781 0.171153C0.373963 0.12107 0.472817 0.0948768 0.57336 0.0952181C0.673903 0.0955595 0.772575 0.122423 0.859415 0.173097Z"
        fill="currentColor"
      />
    </svg>
  );
};
