import { useAnnotationCreate, useAnnotationImageCreate } from '@assemblio/frontend/data-access';
import { AnnotationController, useProjectStore, useUIStore } from '@assemblio/frontend/stores';
import { AnnotationType } from '@assemblio/type/annotation';
import { Button, Menu } from '@mantine/core';
import {
  IconChevronDown,
  IconCircleFilled,
  IconPhotoFilled,
  IconRectangleFilled,
  IconSTurnRight,
} from '@tabler/icons-react';
import { useRef } from 'react';
import { annotationCreators } from './helper/annotation.helper';
import { AnnotationImageDto } from '@assemblio/shared/next-types';
import { notifications } from '@mantine/notifications';
import classes from '../Viewport.module.scss';
import { ContextMenuItem, IconAddAnnotation } from '@assemblio/design-system';

export const AnnotationChooser = () => {
  const fileInput = useRef<HTMLInputElement>(null);

  const annotationCreateMutation = useAnnotationCreate();
  const imageAnnotationCreateMutation = useAnnotationImageCreate();

  const selectedStep = useUIStore((store) => store.selectedStep);

  const createAnnotation = (type: AnnotationType) => {
    const instructionId = useProjectStore.getState().instructionId;

    const create = annotationCreators[type];
    const annotation = create ? create() : undefined;

    if (annotation && selectedStep) {
      AnnotationController.addAnnotation(annotation);
      AnnotationController.addAnnotationToStep(selectedStep.id, annotation.id);
      annotationCreateMutation.mutate(
        {
          id: instructionId,
          data: {
            stepId: selectedStep.id,
            annotation,
          },
        },
        {
          onError: (_e, values) => {
            notifications.show({
              id: `add-annotation-error`,
              message: 'Annotation could not be added to Step',
              color: 'red',
            });
            AnnotationController.removeAnnotationFromStep(values.data.stepId, values.data.annotation.id);
            AnnotationController.removeAnnotation(values.data.annotation.id);
          },
        }
      );
    }
  };

  const createImageAnnotation = (files: FileList | undefined) => {
    if (files && selectedStep) {
      const instructionId = useProjectStore.getState().instructionId;

      const create = annotationCreators[AnnotationType.IMAGE];
      const annotation = create ? create() : undefined;

      if (annotation) {
        imageAnnotationCreateMutation.mutate(
          {
            id: instructionId,
            data: {
              stepId: selectedStep.id,
              annotation,
              file: files[0],
            },
          },
          {
            onSuccess: (result) => {
              (annotation as AnnotationImageDto).file = {
                id: result.id,
                originalName: result.originalName,
              };
              AnnotationController.addAnnotation(annotation);
              AnnotationController.addAnnotationToStep(selectedStep.id, annotation.id);
            },
          }
        );
      }
    } else {
      console.log('none');
    }
  };

  return (
    <>
      <input
        data-cy="upload-annotation-image"
        type="file"
        name="File"
        accept=".png, .jpeg, .jpg"
        ref={fileInput}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            createImageAnnotation(e.target.files);
          }
          e.target.value = '';
        }}
        style={{ display: 'none' }}
      />
      <Menu>
        <Menu.Target>
          <Button
            className={classes.controls__annotation_button}
            leftSection={<IconAddAnnotation />}
            rightSection={<IconChevronDown />}
          >
            Annotation
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <ContextMenuItem label={'Image'} onClick={() => fileInput?.current?.click()} LeftIcon={IconPhotoFilled} />
          <ContextMenuItem
            label={'Arrow'}
            onClick={() => createAnnotation(AnnotationType.ARROW)}
            LeftIcon={IconSTurnRight}
          />
          <ContextMenuItem
            label={'Rectangle'}
            onClick={() => createAnnotation(AnnotationType.RECT)}
            LeftIcon={IconRectangleFilled}
          />
          <ContextMenuItem
            label={'Ellipse'}
            onClick={() => createAnnotation(AnnotationType.ELLIPSE)}
            LeftIcon={IconCircleFilled}
          />
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
