import { Text, Box, Group } from '@mantine/core';
import classes from '../../../../ExplorerFolderListView.module.scss';
import { IconFileFilled } from '@tabler/icons-react';
import { ProjectOverviewDto } from '@assemblio/shared/dtos';
import { IconProject } from '@assemblio/design-system';

interface ProjectListItemProps {
  project: ProjectOverviewDto;
}

export const ExplorerProjectListItem = ({ project }: ProjectListItemProps) => {
  return (
    <Box px={'lg'} py={'sm'} className={classes.listItem}>
      <Group>
        <IconProject />
        <Text variant={'medium'} size={'sm'}>
          {project.name}
        </Text>
      </Group>
    </Box>
  );
};
