import React from 'react';
import classes from './Resizable.module.scss';
import cx from 'clsx';

export type ResizeHandleSide = 'e' | 'w';

interface ResizeHandleProps {
  height: number;
  handleSide: ResizeHandleSide;
  handleAxis?: string;
  isDragging?: boolean;
}

export const ResizeHandle = React.forwardRef<HTMLDivElement, ResizeHandleProps>((props, ref) => {
  const { height, handleSide, handleAxis, ...restProps } = props;
  return (
    <div
      data-dragging={props.isDragging ? 'true' : 'false'}
      ref={ref}
      style={{
        height: height,
      }}
      className={cx([
        classes.customHandle,
        { [classes['customHandle--right']]: handleSide === 'e' },
        { [classes['customHandle--left']]: handleSide === 'w' },
      ])}
      {...restProps}
    />
  );
});
