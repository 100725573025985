import { ActionIcon } from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import classes from './Resizable.module.scss';
import cx from 'clsx';
interface CollapseSectionButtonProps {
  right?: boolean;
  onClick: () => unknown;
  collapsed: boolean;
}

export const CollapseSectionButton = ({ right = false, collapsed = false, onClick }: CollapseSectionButtonProps) => {
  const openIcon = right ? <IconChevronsRight /> : <IconChevronsLeft />;

  const collapsedIcon = !right ? <IconChevronsRight /> : <IconChevronsLeft />;

  return (
    <ActionIcon
      size="sm"
      variant="transparent"
      color="text-secondary"
      data-collapsed={collapsed}
      className={cx(classes.collapseIcon, {
        [classes['collapseIcon--left']]: !right,
        [classes['collapseIcon--right']]: right,
      })}
      onClick={onClick}
    >
      {collapsed ? collapsedIcon : openIcon}
    </ActionIcon>
  );
};
