import { useMemo } from 'react';
import { ComboboxItem } from '@mantine/core';
import { ExplorerItemType, ExplorerItems, ExplorerItem } from '../types/items.types';

export type SelectItem = ComboboxItem & {
  type: ExplorerItemType;
};

type ItemGroup<T extends ExplorerItemType> = {
  type: T;
  items: SelectItem[];
};

type SelectData = {
  isEmpty: boolean;
  folders: ItemGroup<ExplorerItemType.Folder>;
  projects: ItemGroup<ExplorerItemType.Project>;
  byValue: { [key: string]: SelectItem };
};

export const useItemSearch = (searchTerm: string, items: ExplorerItems) => {
  const getItemType = (id: string): ExplorerItemType => {
    return formattedData.byValue[id].type;
  };

  const getItemName = (id: string) => {
    return formattedData.byValue[id].label;
  };

  const getFilteredItems: {
    filteredFolders: ExplorerItem[];
    filteredProjects: ExplorerItem[];
  } = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    if (Array.isArray(items)) {
      const filtered = items.filter((item) => item.name?.toLowerCase().includes(lowerCaseSearchTerm));
      return {
        filteredFolders: filtered.filter((item) => item.type === ExplorerItemType.Folder),
        filteredProjects: filtered.filter((item) => item.type === ExplorerItemType.Project),
      };
    } else {
      return {
        filteredFolders: items.folders.filter((folder) => folder.name?.toLowerCase().includes(lowerCaseSearchTerm)),
        filteredProjects: items.projects.filter((project) => project.name?.toLowerCase().includes(lowerCaseSearchTerm)),
      };
    }
  }, [searchTerm]);

  const formattedData: SelectData = useMemo(() => {
    const { filteredFolders, filteredProjects } = getFilteredItems;
    const foldersGroup: ItemGroup<ExplorerItemType.Folder> = {
      items: filteredFolders.map((folder) => ({
        value: folder.id,
        label: folder.name,
        type: ExplorerItemType.Folder,
      })),
      type: ExplorerItemType.Folder,
    };

    const projectsGroup: ItemGroup<ExplorerItemType.Project> = {
      items: filteredProjects.map((project) => ({
        value: project.id,
        label: project.name,
        type: ExplorerItemType.Project,
      })),
      type: ExplorerItemType.Project,
    };

    const byValue: { [key: string]: SelectItem } = {};

    foldersGroup.items.forEach((item) => {
      byValue[item.value] = item;
    });
    projectsGroup.items.forEach((item) => {
      byValue[item.value] = item;
    });

    const isEmpty = foldersGroup.items.length === 0 && projectsGroup.items.length === 0;

    return { folders: foldersGroup, projects: projectsGroup, byValue, isEmpty };
  }, [searchTerm, items]);

  return {
    results: {
      isEmpty: !!searchTerm && formattedData.isEmpty,
      projects: formattedData.projects,
      folders: formattedData.folders,
    },
    isLoading: false,
    getItemType,
    getItemName,
  };
};
