import { useEffect, useState } from 'react';
import { ActionIcon, Button, Checkbox, Combobox, Group, ScrollArea, Skeleton, useCombobox } from '@mantine/core';
import produce from 'immer';
import { IconCircleXFilled } from '@tabler/icons-react';
import { SearchField } from '@assemblio/design-system';
import classes from './FilterItem.module.scss';
import { useSearchParams } from 'react-router-dom';
import { FilterItemType, FilterOption } from '../../../../types';

export const FilterItem = ({ filter }: { filter: FilterOption }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedOptions, setSelectedOptions] = useState<string[] | undefined>(undefined);
  const [search, setSearch] = useState('');

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleClearFilter = () => {
    setSelectedOptions([]);
    searchParams.delete(filter.value);
    setSearchParams(searchParams);
  };

  const renderFilterOption = (option: FilterItemType) => {
    return (
      <Combobox.Option value={option.value} key={option.value}>
        <Group key={option.value}>
          <Checkbox readOnly checked={selectedOptions?.includes(option.value) ?? false} />
          <span>{option.label}</span>
        </Group>
      </Combobox.Option>
    );
  };

  const toggleOption = (option: string) => {
    if (selectedOptions === undefined) return;
    const filterOption = selectedOptions.find((item) => item === option);
    setSelectedOptions(
      produce<string[]>(selectedOptions, (draft) => {
        if (filterOption) {
          draft.splice(selectedOptions.indexOf(filterOption), 1);
        } else {
          draft.push(option);
        }
      })
    );
  };

  //Set initial selected options from query string
  useEffect(() => {
    const presetQueryParams = (searchParams.get(filter.value) ?? '').split(',');

    // Toggle Query if filter is part of url parameters
    if (filter.options === undefined) {
      if (presetQueryParams.length > 0) {
        filter.onToggleOption(true);
      }
      return;
    }

    if (selectedOptions !== undefined) return;
    const initialOptions = presetQueryParams.reduce((acc, curr) => {
      const toAdd = filter.options?.find((item) => item.value === curr);
      if (toAdd) acc.push(toAdd.value);
      return acc;
    }, [] as string[]);
    setSelectedOptions(initialOptions);
  }, [filter.options]);

  // Update Query string when selectedOptions change
  useEffect(() => {
    if (selectedOptions === undefined) {
      return;
    }
    searchParams.delete(filter.value);
    if (selectedOptions.length > 0) {
      const params = new URLSearchParams([
        ...searchParams,
        [filter.value, selectedOptions.map((option) => option).join(',')],
      ]);
      setSearchParams(params, {
        replace: true,
      });
    }
    if (selectedOptions.length === 0) {
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, [selectedOptions]);

  const getComboBoxOptions = () => {
    if (!filter.options) {
      return Array.from({ length: 3 }).map((_, index) => (
        <Combobox.Option key={`skeleton-loader-${index}`} value={`skeleton-loader-${index}`}>
          <Skeleton h={'2rem'} w={'5rem'} />
        </Combobox.Option>
      ));
    } else {
      return filter.options
        .filter((option) => option.label.toLowerCase().includes(search.trim().toLowerCase()))
        .map((option) => renderFilterOption(option));
    }
  };

  return (
    <Combobox
      store={combobox}
      width={250}
      position="bottom-start"
      withArrow
      withinPortal={false}
      positionDependencies={[selectedOptions]}
      onOptionSubmit={(val) => toggleOption(val)}
      onOpen={() => filter.onToggleOption(true)}
    >
      <Combobox.Target>
        <Button.Group className={classes.filterItem}>
          <Button
            leftSection={filter.Icon ? <filter.Icon style={{ width: '70%', height: '70%' }} /> : null}
            variant={'secondary'}
            onClick={() => combobox.toggleDropdown()}
          >
            {filter.label}
          </Button>
          {selectedOptions && selectedOptions.length > 0 && (
            <ActiveFilterDisplay
              selectedOptions={filter.options?.filter((option) => selectedOptions.includes(option.value)) ?? []}
              onClear={handleClearFilter}
              onClick={() => combobox.toggleDropdown()}
            />
          )}
        </Button.Group>
      </Combobox.Target>

      <Combobox.Dropdown>
        <ScrollArea.Autosize mah={'300px'}>
          <SearchField placeholder={'Search'} onChange={(e) => setSearch(e.currentTarget.value)} value={search} />
          <Combobox.Options>{getComboBoxOptions()}</Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
};

interface ActiveFilterDisplayProps {
  selectedOptions: FilterItemType[];
  onClear: () => void;
  onClick: () => void;
}

const ActiveFilterDisplay = ({ selectedOptions, onClear, onClick }: ActiveFilterDisplayProps) => {
  return (
    <>
      <Button variant={'secondary'} onClick={onClick}>
        {selectedOptions[0].label} {selectedOptions.length > 1 ? `+${selectedOptions.length - 1}` : null}
      </Button>
      <Button px={'xs'} variant={'secondary'}>
        <ActionIcon variant={'transparent'} c={'text-secondary'} onClick={onClear}>
          <IconCircleXFilled />
        </ActionIcon>
      </Button>
    </>
  );
};
