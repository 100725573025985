import { PropsWithChildren } from 'react';
import { Collapse, Group, Stack, Switch } from '@mantine/core';

interface SwitchCollapseProps {
  label: string;
  selected: boolean;
  onSelect: () => void;
  disabled?: boolean;
}

export const SwitchCollapse = ({
  children,
  label,
  onSelect,
  selected,
  disabled,
}: PropsWithChildren<SwitchCollapseProps>) => {
  return (
    <Stack gap={'xs'}>
      <Group justify={'space-between'}>
        {label}
        <Switch size={'xs'} color={'var(--text-brand)'} checked={selected} onClick={onSelect} disabled={disabled} />
      </Group>
      {children && <Collapse in={selected}>{children}</Collapse>}
    </Stack>
  );
};
