export const ComposerColors: Colors = {
  'neutral-dark-mode': [
    '#F7F7F8',
    '#F2F2F3',
    '#B4B7BB',
    '#81868D',
    '#686C73',
    '#5A5D63',
    '#44464B',
    '#35383B',
    '#26282B',
    '#18191B',
  ],
  'neutral-light-mode': [
    '#F4F5F6',
    '#EEF0F2',
    '#DCDFE5',
    '#C1C7D2',
    '#AFB6C0',
    '#818CA2',
    '#647087',
    '#4E5769',
    '#393F4C',
    '#1E2229',
  ],
  'alpha-black': [
    '#0000000A',
    '#00000014',
    '#0000001F',
    '#00000033',
    '#00000066',
    '#00000066',
    '#00000099',
    '#00000099',
    '#000000CC',
    '#000000CC',
  ],
  'alpha-white': [
    '#FFFFFF0D',
    '#FFFFFF0D',
    '#FFFFFF33',
    '#FFFFFF33',
    '#FFFFFF66',
    '#FFFFFF66',
    '#FFFFFF99',
    '#FFFFFF99',
    '#FFFFFFCC',
    '#FFFFFFCC',
  ],

  purple: [
    '#8C63F1',
    '#8C63F1',
    '#8C63F1',
    '#7C4DEF',
    '#6F3BED',
    '#6928EB',
    '#5622BE',
    '#431C91',
    '#301665',
    '#301665',
  ],
  'alpha-purple': [
    '#6928EB1A',
    '#6928EB1A',
    '#6928EB33',
    '#6928EB33',
    '#6928EB66',
    '#6928EB66',
    '#6928EB99',
    '#6928EB99',
    '#6928EB99',
    '#6928EB99',
  ],
};

type Colors = {
  [key: string]: readonly [string, string, string, string, string, string, string, string, string, string, ...string[]];
};
