export const IconVectorStroke = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.571289 1.42868C0.571289 0.955289 0.955045 0.571533 1.42843 0.571533H14.5713C15.0447 0.571533 15.4284 0.955289 15.4284 1.42868C15.4284 1.90206 15.0447 2.28582 14.5713 2.28582H1.42843C0.955045 2.28582 0.571289 1.90206 0.571289 1.42868ZM2.28557 9.14296C1.3388 9.14296 0.571289 9.91047 0.571289 10.8572V13.7144C0.571289 14.6611 1.3388 15.4287 2.28557 15.4287H13.7141C14.6609 15.4287 15.4284 14.6611 15.4284 13.7144V10.8572C15.4284 9.91047 14.6609 9.14296 13.7141 9.14296H2.28557ZM0.571289 4.85725C0.571289 3.91047 1.3388 3.14296 2.28557 3.14296H13.7141C14.6609 3.14296 15.4284 3.91047 15.4284 4.85725V6.57153C15.4284 7.51831 14.6609 8.28582 13.7141 8.28582H2.28557C1.3388 8.28582 0.571289 7.51831 0.571289 6.57153V4.85725Z"
        fill="currentColor"
      />
    </svg>
  );
};
