import { useStepGroupDelete, useStepGroupRename } from '@assemblio/frontend/data-access';
import { SequenceController, StepGroupController, UIController, useUIStore } from '@assemblio/frontend/stores';
import { StepGroup as StepGroupDef } from '@assemblio/shared/next-types';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { ActionIcon, Box, Collapse, Group, LoadingOverlay, Tooltip } from '@mantine/core';
import { IconCirclePlusFilled, IconMenu2, IconTrashFilled } from '@tabler/icons-react';
import { DroppableType } from './StepBoard';
import { StepList } from './StepList';
import ToggableTextInput from './UI/ToggableTextInput';
import { useCreateEmptyStep } from '@assemblio/frontend/hooks';
import { useStepGroupDisplayState } from './Hooks/StepGroupDisplayState';
import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';
import classes from './Styles/StepGroup.module.scss';
import cx from 'clsx';
import { IconCaretDown, IconCaretRight } from '@assemblio/design-system';
interface StepGroupProps {
  stepGroup: StepGroupDef;
  index: number;
  stepGroupsLength: number;
}

export const StepGroup = ({ stepGroup, index, stepGroupsLength }: StepGroupProps) => {
  const [isDeleting, setDeleting] = useState(false);
  const isEditor = useUIStore((state) => state.view === 'editor');

  const { selected, annotationHighlight, isInteractable, collapsed, toggle } = useStepGroupDisplayState(stepGroup);

  const stepGroupRenameMutation = useStepGroupRename();
  const stepGroupDeleteMutation = useStepGroupDelete();
  const { createEmptyStep } = useCreateEmptyStep();

  const handleNameChange = (id: string, oldName: string, newName: string) => {
    StepGroupController.renameStepGroup(id, newName);
    stepGroupRenameMutation.mutate(
      { id, data: { name: newName } },
      {
        onError: () => {
          StepGroupController.renameStepGroup(id, oldName);
        },
      }
    );
  };

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, stepGroupId: string) => {
    event.stopPropagation();
    setDeleting(true);

    //Not using optimistic update for delete operation as callbacks for .mutate functions wont fire if component was unmounted already
    stepGroupDeleteMutation.mutate(stepGroupId, {
      onSuccess: () => {
        StepGroupController.deleteStepGroup(stepGroupId);
        notifications.show({
          id: 'step-group-delete-sucess',
          message: 'Step Group deleted',
          color: 'green',
        });
      },
      onSettled: () => {
        setDeleting(false);
      },
    });
  };

  return (
    <Draggable draggableId={stepGroup.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Group
          data-cy="group-context"
          data-selected={selected}
          ref={provided.innerRef}
          {...provided.draggableProps}
          gap={0}
          className={cx(classes.stepGroup, {
            [classes['stepGroup--dragging']]: snapshot.isDragging,
            [classes['stepGroup--annotationHighlight']]: annotationHighlight,
            [classes['stepGroup--disabled']]: !isInteractable,
          })}
          onClick={() => handleClick(stepGroup.id)}
        >
          <LoadingOverlay
            loaderProps={{
              size: 'xs',
            }}
            visible={isDeleting}
          />
          <Group justify={'space-between'} w={'100%'} wrap="nowrap" gap={0}>
            <Group gap={0} wrap="nowrap">
              {isEditor && (
                <Box className={classes.dragHandle} {...provided.dragHandleProps}>
                  <IconMenu2 size={12} />
                </Box>
              )}
              <ActionIcon
                data-cy="sequencer-stepGroup-dropdown"
                size="sm"
                variant="transparent"
                c={'text-tertiary'}
                ml={isEditor ? 0 : 'md'}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                {!collapsed ? <IconCaretDown /> : <IconCaretRight />}
              </ActionIcon>
              <ToggableTextInput
                id={stepGroup.id}
                name={stepGroup.name}
                selected={selected}
                disabled={!isInteractable}
                isToggable={isEditor}
                size={'sm'}
                onNameChange={handleNameChange}
              />
            </Group>
            {isEditor && (
              <Group wrap="nowrap" gap={5} justify={'right'} pr={'lg'}>
                {stepGroup.steps.length === 0 && stepGroupsLength > 1 && (
                  <ActionIcon
                    p={1}
                    variant={'transparent'}
                    c={'text-secondary'}
                    size={'xs'}
                    onClick={(event) => handleRemove(event, stepGroup.id)}
                  >
                    <IconTrashFilled />
                  </ActionIcon>
                )}
                <Tooltip label="Add empty step">
                  <ActionIcon
                    //align PlusIcon with SettingsIcon of Step
                    variant={'transparent'}
                    c={'text-secondary'}
                    disabled={!isInteractable}
                    size={'sm'}
                    onClick={() => createEmptyStep(stepGroup.id)}
                  >
                    <IconCirclePlusFilled style={{ width: '70%', height: '70%' }} data-cy="add-empty-step" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}
          </Group>
          <Collapse in={!collapsed} w={'100%'}>
            <StepList
              key={`stepGroupList-${stepGroup.id}`}
              steps={stepGroup.steps}
              listId={stepGroup.id}
              listType={DroppableType.Step}
            />
          </Collapse>
        </Group>
      )}
    </Draggable>
  );
};

const handleClick = (stepGroupId: string) => {
  if (UIController.isAnimating()) return;
  SequenceController.setSelectedStepGroup(stepGroupId);
};
