export const IconCaretRight = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.46667 8.4C9.73333 8.2 9.73333 7.8 9.46667 7.6L6.8 5.6C6.47038 5.35279 6 5.58798 6 6L6 10C6 10.412 6.47038 10.6472 6.8 10.4L9.46667 8.4Z"
        fill="currentColor"
      />
    </svg>
  );
};
