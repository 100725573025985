export const viewCube = `# Blender 4.2.1 LTS
# www.blender.org
mtllib viewcube-groups.mtl
o top
v 0.733333 1.000000 -0.733333
v 1.000000 1.000000 -1.000000
v 0.733333 1.000000 -1.000000
v 1.000000 0.733333 -1.000000
v 0.733333 0.733333 -1.000000
v -1.000000 1.000000 -1.000000
v -0.733333 1.000000 -1.000000
v -1.000000 1.000000 -0.733333
v -1.000000 0.733333 -1.000000
v -1.000000 1.000000 1.000000
v -0.733333 0.733333 1.000000
v 1.000000 1.000000 1.000000
v 0.733333 1.000000 1.000000
v 1.000000 1.000000 0.733333
v 0.733333 1.000000 0.733333
v 1.000000 0.733333 0.733333
v 1.000000 -1.000000 1.000000
v 0.733333 -1.000000 1.000000
v 1.000000 -1.000000 -1.000000
v 0.733333 -0.733333 -1.000000
v 1.000000 -0.733333 -0.733333
v -1.000000 -1.000000 -1.000000
v -0.733333 -1.000000 -1.000000
v -1.000000 -1.000000 -0.733333
v -0.733333 -1.000000 -0.733333
v -1.000000 -0.733333 -1.000000
v -1.000000 -1.000000 1.000000
v -1.000000 -0.733333 1.000000
v -1.000000 -0.733333 0.733333
v 1.000000 -1.000000 0.733333
v 1.000000 -1.000000 -0.733333
v 0.733333 -1.000000 -0.733333
v 1.000000 -0.733333 0.733333
v 0.733333 -1.000000 -1.000000
v -0.733333 -0.733333 -1.000000
v -1.000000 -1.000000 0.733333
v -1.000000 -0.733333 -0.733333
v -0.733333 0.733333 -1.000000
v 1.000000 -0.733333 -1.000000
v 1.000000 0.733333 1.000000
v 1.000000 -0.733333 1.000000
v -1.000000 0.733333 1.000000
v -0.733333 -0.733333 1.000000
v -1.000000 0.733333 0.733333
v -0.733333 1.000000 -0.733333
v 1.000000 1.000000 -0.733333
v 1.000000 0.733333 -0.733333
v -0.733333 1.000000 1.000000
v -0.733333 1.000000 0.733333
v 0.733333 0.733333 1.000000
v -1.000000 1.000000 0.733333
v -1.000000 0.733333 -0.733333
v -0.733333 -1.000000 1.000000
v -0.733333 -1.000000 0.733333
v 0.733333 -0.733333 1.000000
v 0.733333 -1.000000 0.733333
vn -0.0000 1.0000 -0.0000
vn -0.0000 -0.0000 -1.0000
vn 1.0000 -0.0000 -0.0000
vn -1.0000 -0.0000 -0.0000
vn -0.0000 -0.0000 1.0000
vn -0.0000 -1.0000 -0.0000
vt 0.999900 0.999900
vt 0.000100 0.999900
vt 0.000100 0.000100
vt 0.999900 0.000100
vt 0.291667 0.666667
vt 0.375000 0.666667
vt 0.375000 0.750000
vt 0.291667 0.750000
vt 0.375000 0.000000
vt 0.458333 0.000000
vt 0.458333 0.083333
vt 0.375000 0.083333
vt 0.541667 0.750000
vt 0.625000 0.750000
vt 0.625000 0.833333
vt 0.541667 0.833333
vt 0.791667 0.666667
vt 0.875000 0.666667
vt 0.875000 0.750000
vt 0.791667 0.750000
vt 0.291667 0.500000
vt 0.375000 0.500000
vt 0.375000 0.583333
vt 0.291667 0.583333
vt 0.541667 0.000000
vt 0.625000 0.000000
vt 0.625000 0.083333
vt 0.541667 0.083333
vt 0.541667 0.166667
vt 0.625000 0.166667
vt 0.625000 0.250000
vt 0.541667 0.250000
vt 0.541667 0.666667
vt 0.625000 0.666667
vt 0.791667 0.500000
vt 0.875000 0.500000
vt 0.875000 0.583333
vt 0.791667 0.583333
vt 0.541667 0.916667
vt 0.625000 0.916667
vt 0.625000 1.000000
vt 0.541667 1.000000
vt 0.541667 0.500000
vt 0.625000 0.500000
vt 0.625000 0.583333
vt 0.541667 0.583333
vt 0.375000 0.166667
vt 0.458333 0.166667
vt 0.458333 0.250000
vt 0.375000 0.250000
vt 0.541667 0.416667
vt 0.625000 0.416667
vt 0.458333 0.500000
vt 0.458333 0.583333
vt 0.375000 0.916667
vt 0.458333 0.916667
vt 0.458333 1.000000
vt 0.375000 1.000000
vt 0.625000 0.333333
vt 0.541667 0.333333
vt 0.458333 0.750000
vt 0.458333 0.833333
vt 0.375000 0.833333
vt 0.125000 0.666667
vt 0.208333 0.666667
vt 0.208333 0.750000
vt 0.125000 0.750000
vt 0.458333 0.333333
vt 0.375000 0.333333
vt 0.125000 0.500000
vt 0.208333 0.500000
vt 0.208333 0.583333
vt 0.125000 0.583333
vt 0.708333 0.666667
vt 0.708333 0.750000
vt 0.375000 0.416667
vt 0.458333 0.416667
vt 0.708333 0.500000
vt 0.708333 0.583333
vt 0.458333 0.666667
vt 0.999900 0.000100
vt 0.999900 0.999900
vt 0.000100 0.999900
vt 0.000100 0.000100
vt 0.000100 0.000100
vt 0.999900 0.000100
vt 0.999900 0.999900
vt 0.000100 0.999900
vt 0.999900 0.000100
vt 0.000100 0.999900
vt 0.000100 0.000100
s 0
g top
usemtl top-mtl
f 1/1/1 45/2/1 49/3/1 15/4/1
g right-top-back
usemtl back-right-top-mtl
f 5/5/2 3/6/2 2/7/2 4/8/2
f 2/9/1 3/10/1 1/11/1 46/12/1
f 4/13/3 2/14/3 46/15/3 47/16/3
g left-top-back
usemtl back-left-top-mtl
f 52/17/4 8/18/4 6/19/4 9/20/4
f 9/21/2 6/22/2 7/23/2 38/24/2
f 7/25/1 6/26/1 8/27/1 45/28/1
g left-top-front
usemtl front-left-top-mtl
f 49/29/1 51/30/1 10/31/1 48/32/1
f 11/33/5 48/34/5 10/14/5 42/13/5
f 42/35/4 10/36/4 51/37/4 44/38/4
g right-top-front
usemtl front-right-top-mtl
f 16/39/3 14/40/3 12/41/3 40/42/3
f 40/43/5 12/44/5 13/45/5 50/46/5
f 14/47/1 15/48/1 13/49/1 12/50/1
g right-bottom-front
usemtl front-right-bottom-mtl
f 56/51/6 30/52/6 17/44/6 18/43/6
f 17/22/5 41/53/5 55/54/5 18/23/5
f 30/55/3 33/56/3 41/57/3 17/58/3
g right-bottom-back
usemtl back-right-bottom-mtl
f 34/32/6 19/31/6 31/59/6 32/60/6
f 19/7/3 39/61/3 21/62/3 31/63/3
f 34/64/2 20/65/2 39/66/2 19/67/2
g left-bottom-back
usemtl back-left-bottom-mtl
f 22/50/6 23/49/6 25/68/6 24/69/6
f 22/70/2 26/71/2 35/72/2 23/73/2
f 24/34/4 37/74/4 26/75/4 22/14/4
g left-bottom-front
usemtl front-left-bottom-mtl
f 36/76/6 54/77/6 53/53/6 27/22/6
f 27/44/4 28/78/4 29/79/4 36/45/4
f 53/6/5 43/80/5 28/61/5 27/7/5
g right-bottom
usemtl right-bottom-mtl
f 32/60/6 31/59/6 30/52/6 56/51/6
f 31/63/3 21/62/3 33/56/3 30/55/3
g bottom-back
usemtl back-bottom-mtl
f 23/49/6 34/32/6 32/60/6 25/68/6
f 23/73/2 35/72/2 20/65/2 34/64/2
g left-bottom
usemtl left-bottom-mtl
f 24/69/6 25/68/6 54/77/6 36/76/6
f 36/45/4 29/79/4 37/74/4 24/34/4
g left-back
usemtl back-left-mtl
f 26/71/2 9/21/2 38/24/2 35/72/2
f 37/74/4 52/17/4 9/20/4 26/75/4
g right-back
usemtl back-right-mtl
f 39/61/3 4/13/3 47/16/3 21/62/3
f 20/65/2 5/5/2 4/8/2 39/66/2
g right-front
usemtl front-right-mtl
f 41/53/5 40/43/5 50/46/5 55/54/5
f 33/56/3 16/39/3 40/42/3 41/57/3
g left-front
usemtl front-left-mtl
f 28/78/4 42/35/4 44/38/4 29/79/4
f 43/80/5 11/33/5 42/13/5 28/61/5
g top-back
usemtl back-top-mtl
f 38/24/2 7/23/2 3/6/2 5/5/2
f 3/10/1 7/25/1 45/28/1 1/11/1
g right-top
usemtl right-top-mtl
f 46/12/1 1/11/1 15/48/1 14/47/1
f 47/16/3 46/15/3 14/40/3 16/39/3
g top-front
usemtl front-top-mtl
f 50/46/5 13/45/5 48/34/5 11/33/5
f 15/48/1 49/29/1 48/32/1 13/49/1
g left-top
usemtl left-top-mtl
f 44/38/4 51/37/4 8/18/4 52/17/4
f 45/28/1 8/27/1 51/30/1 49/29/1
g bottom-front
usemtl front-bottom-mtl
f 54/77/6 56/51/6 18/43/6 53/53/6
f 18/23/5 55/54/5 43/80/5 53/6/5
g front
usemtl front-mtl
f 55/81/5 50/82/5 11/83/5 43/84/5
g bottom
usemtl bottom-mtl
f 25/85/6 32/86/6 56/87/6 54/88/6
g right
usemtl right-mtl
f 21/81/3 47/82/3 16/83/3 33/84/3
g back
usemtl back-mtl
f 35/89/2 38/82/2 5/90/2 20/91/2
g left
usemtl left-mtl
f 29/89/4 44/82/4 52/90/4 37/91/4
`;
