import React from 'react';
import { Box, ScrollArea } from '@mantine/core';
import { Mock_VersionInformation } from './types/utils.types';
import { VersionItem } from './components/VersionItem';

interface VersionHistoryProps {
  versions: Mock_VersionInformation[];
}

export const VersionHistoryTab = ({ versions }: VersionHistoryProps) => {
  return (
    <ScrollArea style={{ height: 300 }}>
      <Box>
        {versions.map((version) => (
          <VersionItem key={version.version} versionInfo={version} />
        ))}
      </Box>
    </ScrollArea>
  );
};
