import { Menu } from '@mantine/core';
import { ContextMenuButton, ContextMenuItem, IconRenameFilled } from '@assemblio/design-system';
import { IconRefresh, IconStar, IconStarFilled, IconTrashFilled } from '@tabler/icons-react';
import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { useAuthStore, useInstructionSetFavorite } from '@assemblio/frontend/data-access';
import { useDeleteProduct, useRenameProduct } from '../../../../hooks/';
import { useEffect } from 'react';
import { useExplorerRouteParams } from '../../../../hooks/ExplorerRouteParams.hook';
import { useModalContext } from '@assemblio/frontend/components';
import { useHasFeatureFlag } from '../../../../hooks/HasFeatureFlag.hook';
import { FeatureFlag } from '@assemblio/type/feature-flag';

interface ProductContextMenuProps {
  product: ProductOverviewDto;
  disabled?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductContextMenu = ({ product, setLoading, disabled = false }: ProductContextMenuProps) => {
  const { projectId } = useExplorerRouteParams();

  const { renameProduct, isLoading } = useRenameProduct();
  const { deleteProduct, isLoading: isDeleteMutationLoading } = useDeleteProduct();
  const { openModal } = useModalContext();
  const variantsEnabled = useHasFeatureFlag(FeatureFlag.Variants);

  const setFavoriteMutation = useInstructionSetFavorite();

  const userId = useAuthStore((state) => state.userId);

  const isMenuDisabled = disabled;

  const isOwner = product.owner.id === userId;

  const onRename = () => {
    renameProduct(product.name, product.id);
  };

  const openCreateSyncProfileModal = () => {
    openModal('create-sync-profile-modal', {
      product,
    });
  };

  const handleUnsyncProduct = () => {
    openModal('delete-sync-profile-modal', {
      product,
    });
  };

  const onDelete = () => {
    deleteProduct(product.name, product.id);
  };

  const handleToggleFavorite = () => {
    setFavoriteMutation.mutate({
      productId: product.id,
      projectId,
      action: product.isFavorite ? 'remove' : 'add',
      usedBy: projectId ? 'project' : 'favorites',
    });
  };

  useEffect(() => {
    setLoading(isDeleteMutationLoading || isLoading);
  }, [isDeleteMutationLoading, isLoading]);

  return (
    <Menu disabled={isMenuDisabled} trigger={'hover'} withinPortal position="bottom-start">
      <Menu.Target>
        <ContextMenuButton />
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem label={'Rename'} onClick={onRename} LeftIcon={IconRenameFilled} />
        {variantsEnabled && (
          <ContextMenuItem
            label={'Sync with another product'}
            onClick={openCreateSyncProfileModal}
            LeftIcon={IconRefresh}
          />
        )}
        {variantsEnabled && product.sync.toTarget && (
          <ContextMenuItem label={'Unsync Product'} onClick={handleUnsyncProduct} LeftIcon={IconRefresh} />
        )}
        <ContextMenuItem
          label={product.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          onClick={handleToggleFavorite}
          LeftIcon={product.isFavorite ? IconStarFilled : IconStar}
        />
        <ContextMenuItem
          label={'Delete'}
          onClick={onDelete}
          LeftIcon={IconTrashFilled}
          disabled={!isOwner}
          tooltipLabel={isOwner ? undefined : 'Only the owner can delete a product'}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
