import { ActionIcon, Group, Text } from '@mantine/core';
import { IconFileFilled, IconX } from '@tabler/icons-react';
import classes from './ActiveFile.module.scss';

interface ActiveFileProps {
  fileName: string;
  onClear: () => void;
}

export const ActiveFile = ({ fileName, onClear }: ActiveFileProps) => {
  return (
    <Group py={'sm'} justify={'space-between'}>
      <Group maw={'90%'} gap={'sm'} align={'center'}>
        <IconFileFilled className={classes.file_icon} />
        <Text maw={'90%'} lineClamp={1} size={'sm'} variant={'medium'}>
          {fileName}
        </Text>
      </Group>
      <ActionIcon c={'text-primary'} variant={'transparent'} onClick={onClear} aria-label="Clear file" size="xs">
        <IconX />
      </ActionIcon>
    </Group>
  );
};
