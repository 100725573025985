import { ActionIcon, Divider, Menu } from '@mantine/core';
import {
  IconAdjustmentsFilled,
  IconCopy,
  IconCopyOff,
  IconEye,
  IconTrash,
  IconVideo,
  IconVideoOff,
} from '@tabler/icons-react';
import classes from '../../Sequencer.module.scss';
import { useUIStore } from '@assemblio/frontend/stores';

interface StepMenuProps {
  onRemoveStep: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onResetCamera: () => void;
  onSetCamera: () => void;
  onSetCameraPositionToStep: () => void;
  onCopyCameraSettings: () => void;
  onPasteCameraSettings: () => void;
  isStepSelected: boolean;
  disabled: boolean;
}

export const StepMenu = ({
  onRemoveStep,
  onResetCamera,
  onSetCamera,
  onSetCameraPositionToStep,
  onCopyCameraSettings,
  onPasteCameraSettings,
  isStepSelected,
  disabled,
}: StepMenuProps) => {
  const disablePasteItem = useUIStore((state) => state.cameraClipboard) ? disabled : true;

  return (
    <Menu
      width={180}
      position={'bottom-end'}
      classNames={{
        item: classes.stepMenu__item,
      }}
      offset={0}
      withinPortal
    >
      <Menu.Target>
        <ActionIcon
          disabled={disabled}
          variant="transparent"
          c={'text-secondary'}
          data-cy="Icon-adjustments-horizontal"
        >
          <IconAdjustmentsFilled size={18} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Camera</Menu.Label>
        <Menu.Item data-cy="reset-camera" onClick={onResetCamera} disabled={disabled}>
          Reset
        </Menu.Item>
        <Menu.Item data-cy="set-camera" onClick={onSetCamera} disabled={disabled}>
          Set
        </Menu.Item>
        <Menu.Item data-cy="view-camera" onClick={onSetCameraPositionToStep} disabled={disabled}>
          View
        </Menu.Item>
        <Menu.Item data-cy="copy-camera" onClick={onCopyCameraSettings} disabled={disabled}>
          Copy
        </Menu.Item>
        <Menu.Item data-cy="paste-camera" onClick={onPasteCameraSettings} disabled={disablePasteItem}>
          Paste
        </Menu.Item>
        <Divider />
        <Menu.Item data-cy="delete-step" onClick={onRemoveStep} disabled={disabled}>
          Delete step
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
