import { Center, Text } from '@mantine/core';
import classes from './ItemCountBadge.module.scss';

interface ItemCountBadgeProps {
  count: number;
}

export const ItemCountBadge = ({ count }: ItemCountBadgeProps) => {
  return (
    <Center p={'xs'} className={classes.count}>
      <Text size={'sm'} data-cy="itemCountBadge">
        {count}
      </Text>
    </Center>
  );
};
