import { useQuery } from '@tanstack/react-query';
import { getKIMInfo, getViewerInfo } from './info.requests';

export const useKIMInfoQuery = () => {
  return useQuery(['kim-info'], () => getKIMInfo(), {
    retry: false,
  });
};

export const useViewerInfoQuery = () => {
  return useQuery(['viewer-info'], () => getViewerInfo(), {
    retry: false,
  });
};
