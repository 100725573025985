export const IconRotation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98969 1.42858C7.99313 1.42857 7.99656 1.42857 8 1.42857C11.0258 1.42857 13.5741 3.47365 14.3377 6.25698C12.6771 7.65729 10.2835 8.56152 7.4148 8.57354C7.08307 8.57493 6.75997 8.56438 6.44528 8.54254C6.41585 8.18786 6.40067 7.82654 6.40067 7.45959C6.40067 5.14058 7.00776 3.03979 7.98969 1.42858ZM5.86874 1.78189C3.59481 2.5611 1.88363 4.55254 1.50649 6.98496C2.43953 7.57059 3.50175 8.02343 4.70974 8.29317C4.69425 8.01773 4.68639 7.73973 4.68639 7.45959C4.68639 5.39295 5.11405 3.44706 5.86874 1.78189ZM1.49653 8.94857C1.88659 11.6465 3.91575 13.8141 6.54181 14.409C5.77481 13.1586 5.21633 11.6854 4.92242 10.0828C3.66566 9.86305 2.52553 9.47374 1.49653 8.94857ZM8.73199 14.5311C11.9424 14.1753 14.4553 11.5069 14.5675 8.22891C12.6584 9.51905 10.1972 10.2762 7.42198 10.2878C7.1815 10.2888 6.94451 10.2842 6.71104 10.2742C7.09418 11.9608 7.80977 13.4265 8.73199 14.5311ZM8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0Z"
        fill="currentColor"
      />
    </svg>
  );
};
