import { useAuthStore, useFolderCreate, useFolderQuery } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';

export const useCreateFolder = () => {
  const [isLoading, setIsLoading] = useState(false);

  const tenantId = useAuthStore((state) => state.tenant.id);
  const { folderId } = useExplorerRouteParams();
  const createFolderMutation = useFolderCreate();

  const createFolder = async (name: string, parentFolderId?: string) => {
    setIsLoading(true);
    try {
      await createFolderMutation.mutateAsync({
        folderData: {
          color: '#fff',
          name: name,
          icon: undefined,
          tenantId: tenantId,
          parentFolderId: parentFolderId || folderId,
        },
      });
      notifications.show({
        id: 'add-folder-success',
        message: 'Folder has been created',
        color: 'green',
      });
    } catch (e) {
      notifications.show({
        id: 'add-folder-error',
        message: 'Folder could not be created',
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createFolder,
    isLoading,
  };
};
