import { Button, Group, Skeleton, Table, ActionIcon, Text, Center, Loader, Avatar } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import { useState } from 'react';
import { ProductContextMenu } from '../../../ProductCard/components/ProductContextMenu/ProductContextMenu';
import { ProductOverviewDto } from '@assemblio/shared/dtos';
import classes from './ProductRow.module.scss';
import { getFormattedDateDifference } from '../../../../util';
import { Tag } from '@assemblio/design-system';
import { useAuthStore } from '@assemblio/frontend/data-access';

interface ProductRowProps {
  product: ProductOverviewDto;
  onOpenDetails: (id: string) => void;
  onOpenProduct: (product: ProductOverviewDto) => void;
  onToggleFavorite: (id: string, isFavorite: boolean) => void;
}

export const ProductRow = ({ product, onOpenProduct, onOpenDetails, onToggleFavorite }: ProductRowProps) => {
  const userId = useAuthStore((state) => state.userId);
  const { id, name, isFavorite, owner, state, updatedAt, project, folder } = product;
  const [isLoading, setIsLoading] = useState(true);

  const getOwnerName = () => {
    return owner.id === userId ? 'You' : owner.fullName;
  };
  return (
    <Table.Tr key={id} className={classes.row}>
      <Table.Td>
        {isLoading && (
          <div className={classes.loading_overlay}>
            <Center h={'100%'}>
              <Loader />
            </Center>
          </div>
        )}
        <Group>
          <ActionIcon
            size={'sm'}
            c={isFavorite ? 'text-alert' : 'text-primary'}
            onClick={() => onToggleFavorite(id, isFavorite)}
            variant={'transparent'}
          >
            {isFavorite ? <IconStarFilled /> : <IconStar />}
          </ActionIcon>

          <Text lineClamp={1} variant={'medium'}>
            {name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text lineClamp={1} variant={'medium'}>
          {folder.name}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text lineClamp={1} variant={'medium'}>
          {project.name}
        </Text>
      </Table.Td>
      <Table.Td>
        <Group wrap={'nowrap'}>
          <Avatar name={owner.fullName} color={'initials'} />
          <Text lineClamp={1} variant={'medium'}>
            {getOwnerName()}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text variant={'medium'}>{getFormattedDateDifference(updatedAt)}</Text>
      </Table.Td>
      <Table.Td>
        <Tag state={state} />
      </Table.Td>
      <Table.Td>
        <Group wrap={'nowrap'} justify={'end'} align={'center'}>
          <Button onClick={() => onOpenProduct(product)} variant={'secondary'}>
            Open
          </Button>
          <Button onClick={() => onOpenDetails(id)} variant={'secondary'}>
            Details
          </Button>
          <ProductContextMenu product={product} setLoading={setIsLoading} />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};

export const ProductRowSkeleton = () => (
  <Table.Tr>
    <Table.Td>
      <Skeleton h={'2rem'} w={'15rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td />
    <Table.Td />
  </Table.Tr>
);
