import { useV1FolderQuery, useV1RootFolderQuery } from '@assemblio/frontend/data-access';
import { FolderContentQueryDto } from '@assemblio/shared/dtos';
import { ExplorerItemType } from './types/items.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { Divider, Group, Loader, Stack } from '@mantine/core';
import { useItemSearch } from './hooks/itemSearch.hook';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { ItemContainer } from './components/ItemContainer/ItemContainer';
import { Filter, ItemSearch, SortMenu, ViewToggleButton, useSortingOptions } from '../../components';
import { usePageTitle } from './hooks/pageTitle.hook';
import { ErrorScreen } from '../../components/ErrorScreen/ErrorScreen';
import { FolderContentSortProps } from '@assemblio/type/folder';
import { QueryOrder } from '@assemblio/type/common';
import { FOLDER_QUERY_SORTING_OPTIONS } from './constants/folderQuerySortingOptions';
import { useExplorerItems } from './hooks/explorerItems.hook';
import { useFolderQueryFilters } from './hooks/folderQueryFilters.hook';

export const AssemblyPlans = () => {
  const navigate = useNavigate();
  const { folderId, rootFolderId } = useExplorerRouteParams();
  const { sortingValue, handleSort } = useSortingOptions<FolderContentSortProps>(FOLDER_QUERY_SORTING_OPTIONS);
  const [searchParams] = useSearchParams();

  const { filterProps } = useFolderQueryFilters();

  const { isLoading: rootIsLoading, isError: rootIsError } = useV1RootFolderQuery();

  const queryData: FolderContentQueryDto = useMemo((): FolderContentQueryDto => {
    return {
      orderBy: sortingValue?.queryValue,
      orderType: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
      owners: searchParams.get('owner') ?? undefined,
    };
  }, [sortingValue, searchParams]);

  const {
    data: currentFolderData,
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useV1FolderQuery({
    folderId,
    queryData,
  });

  usePageTitle(currentFolderData, rootFolderId);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);

  const { items } = useExplorerItems({
    folders: currentFolderData?.children ?? [],
    projects: currentFolderData?.projects ?? [],
    sortValue: sortingValue?.queryValue,
    sort: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
  });

  const { results, getItemType, getItemName } = useItemSearch(debouncedSearchTerm, items);

  const handleOpenItem = (id: string, type: ExplorerItemType) => {
    type === 'Folder' ? navigate(`/explorer/${id}`) : navigate(`/explorer/project/${id}`);
  };

  const handleSearchResultClick = (id: string) => {
    setSearchTerm(getItemName(id));
    handleOpenItem(id, getItemType(id));
  };

  const searchResults = [
    {
      group: 'Folders',
      items: results.folders.items ?? [],
    },
    {
      group: 'Projects',
      items: results.projects.items ?? [],
    },
  ];

  useEffect(() => {
    refetch();
  }, [sortingValue, searchParams]);

  if (isError || rootIsError) {
    return <ErrorScreen />;
  }

  return (
    <Stack h={'100%'} gap={'xl'}>
      <Group px={'xl'} wrap={'nowrap'} align={'center'} justify={'flex-start'}>
        <Filter filters={filterProps} previewFilterOptionCount={1} />
        {isRefetching && <Loader size={'xs'} color={'text-primary'} />}
        <ItemSearch
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          isLoading={false}
          isEmpty={results.isEmpty}
          results={searchResults}
          onResultClick={handleSearchResultClick}
        />
        <Divider orientation={'vertical'} />
        <SortMenu<FolderContentSortProps>
          sortOptions={FOLDER_QUERY_SORTING_OPTIONS}
          onSelectSortBy={handleSort}
          sortBy={sortingValue?.value}
          showFolderDisplay
        />
        <ViewToggleButton />
      </Group>
      <ItemContainer
        onItemClick={handleOpenItem}
        isLoading={isLoading || rootIsLoading || isRefetching}
        isError={isError || rootIsError}
        sortBy={sortingValue}
        onSort={handleSort}
        items={items}
      />
    </Stack>
  );
};
