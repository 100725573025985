import { useState } from 'react';

export const useSidebarState = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  return { collapsed, toggleCollapse };
};
