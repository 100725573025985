export const IconChangeUser = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_218_20469)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.28571 3.42857C2.28571 1.53503 3.82074 0 5.71429 0C7.60783 0 9.14286 1.53503 9.14286 3.42857C9.14286 5.32211 7.60783 6.85714 5.71429 6.85714C3.82074 6.85714 2.28571 5.32211 2.28571 3.42857ZM0 13.7143C0 10.5584 2.55838 8 5.71429 8C7.28141 8 8.70118 8.63083 9.73353 9.65242L7.44397 11.9469C7.14723 12.2442 6.95511 12.6299 6.89651 13.0459L6.64142 14.8571H0.571429C0.255838 14.8571 0 14.6013 0 14.2857V13.7143ZM13.973 8.14944C13.8773 8.05369 13.7474 7.99993 13.612 8C13.4766 8.00007 13.3467 8.05397 13.2511 8.14982L8.45518 12.9559C8.37709 13.0342 8.32654 13.1357 8.31111 13.2451L8.00499 15.4186C7.9827 15.5769 8.03586 15.7365 8.14861 15.8497C8.26136 15.963 8.42067 16.0169 8.57904 15.9953L10.7525 15.6994C10.863 15.6843 10.9656 15.6335 11.0444 15.5546L15.8505 10.7485C16.0498 10.5493 16.0498 10.2262 15.8505 10.027L13.973 8.14944Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_20469">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
