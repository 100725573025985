export const IconColourPicker = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_239_128625)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.52399 0.358593C10.0919 0.121877 10.7011 0 11.3164 0C11.9317 0 12.5409 0.121877 13.1088 0.358593C13.6758 0.594903 14.1904 0.940985 14.6231 1.37691C15.059 1.8096 15.4051 2.32423 15.6414 2.89119C15.8782 3.45912 16 4.06831 16 4.6836C16 5.29889 15.8782 5.90808 15.6414 6.47601C15.4049 7.04344 15.0584 7.55845 14.6219 7.99135L14.6208 7.9925L13.6959 8.9175L14.8762 10.0979C15.2667 10.4885 15.2667 11.1216 14.8762 11.5121C14.4857 11.9026 13.8526 11.9026 13.4621 11.5121L12.2816 10.3317L9.47975 13.1336C8.86283 13.7515 8.06382 14.155 7.20034 14.2846C6.51597 14.3874 5.81978 14.3138 5.17646 14.075L3.78651 15.4649L3.78501 15.4664C3.44121 15.8082 2.97615 16 2.49138 16C2.00663 16 1.54155 15.8082 1.19776 15.4664L1.19626 15.4649L0.535082 14.8038L0.533582 14.8023C0.191826 14.4584 0 13.9934 0 13.5086C0 13.0239 0.191826 12.5587 0.533582 12.215L0.535082 12.2135L1.92503 10.8235C1.68621 10.1802 1.61263 9.48402 1.71538 8.79966C1.84502 7.93623 2.24847 7.13723 2.86633 6.52033L5.66829 3.71838L4.48765 2.53775C4.09712 2.14722 4.09712 1.51406 4.48765 1.12353C4.87817 0.733005 5.51134 0.733005 5.90186 1.12353L7.0825 2.30417L8.0075 1.37917L8.00866 1.378C8.44157 0.941559 8.95657 0.595098 9.52399 0.358593ZM6.67843 4.72854L3.87616 7.53081C3.47481 7.93152 3.21233 8.45091 3.12811 9.01176C3.04391 9.57262 3.14202 10.1457 3.408 10.6466C3.55522 10.9238 3.50418 11.2647 3.28222 11.4866L1.54673 13.2222C1.47135 13.2983 1.42857 13.4015 1.42857 13.5086C1.42857 13.6157 1.47082 13.7184 1.54614 13.7945L2.2049 14.4533C2.28099 14.5286 2.38427 14.5714 2.49138 14.5714C2.59849 14.5714 2.70127 14.5291 2.77735 14.4538L4.51335 12.7178C4.7353 12.4958 5.07617 12.4448 5.35342 12.592C5.85431 12.8579 6.42738 12.9561 6.98824 12.8719C7.54909 12.7877 8.06808 12.5256 8.46879 12.1242L11.2715 9.32157L6.67843 4.72854Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_239_128625">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
