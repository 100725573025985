import {
  AlignmentSwitch,
  HeadingSwitch,
  MarkButton,
  TextColorPicker,
  TextEditor,
} from '@assemblio/frontend/components';
import { useStepSetText } from '@assemblio/frontend/data-access';
import { StepController } from '@assemblio/frontend/stores';
import { TextElement } from '@assemblio/shared/dtos';
import { Step } from '@assemblio/shared/next-types';
import { MAXIMUMSTEPTEXTLENGTH } from '@assemblio/type/step';
import { Button, Flex, Group, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBold, IconCheck, IconDeviceFloppy, IconItalic, IconUnderline } from '@tabler/icons-react';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import classes from './StepTextEditor.module.scss';

interface Props {
  step: Step;
}

const emptyText: TextElement[] = [
  {
    type: 'paragraph',
    align: 'left',
    children: [
      {
        text: '',
      },
    ],
  },
];

export const StepTextEditor = ({ step }: Props) => {
  const editorRef = useRef<BaseEditor & ReactEditor>(null);
  const lengthSpanRef = useRef<HTMLSpanElement>(null);
  const setStepTextMutation = useStepSetText();

  const initialText = step.text ? step.text.elements : emptyText;
  const text = useRef<TextElement[]>(initialText);
  const [dirty, setDirty] = useState(false);
  const [, setEditorReady] = useState(false);

  const setStepTextLocal = (value: TextElement[]) => {
    if (!_.isEqual(text.current, value)) {
      setDirty(true);
      text.current = value;
    }
  };

  const save = () => {
    if (dirty) {
      setStepTextMutation.mutate(
        { id: step.id, data: { text: { elements: text.current } } },
        {
          onSuccess: () => {
            setDirty(false);
            StepController.setStepText(step.id, { elements: text.current });
            notifications.show({
              id: 'save-step-text-success',
              message: 'Text has been saved',
              color: 'green',
            });
          },
          onError: () => {
            setDirty(true);
            notifications.show({
              id: 'save-step-text-error',
              message: 'Text could not be saved',
              color: 'red',
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    _.defer(() => {
      if (editorRef.current && !ReactEditor.isFocused(editorRef.current)) {
        ReactEditor.focus(editorRef.current);
      }
    });
    setEditorReady(true);
  }, [editorRef]);

  const onLengthChange = (length: number) => {
    if (lengthSpanRef.current) {
      lengthSpanRef.current.innerText = `${length}/${MAXIMUMSTEPTEXTLENGTH}`;
    }
  };

  return (
    <Flex style={{ height: '50vh' }} align={'stretch'} direction={'column'} gap="0">
      <Group px={'md'}>
        <MarkButton editor={editorRef.current} format="italic" icon={<IconItalic />} />
        <MarkButton editor={editorRef.current} format="underline" icon={<IconUnderline />} />
        <MarkButton editor={editorRef.current} format="bold" icon={<IconBold />} />
        <HeadingSwitch editor={editorRef.current} />
        {/*
          <ListSwitch editor={editorRef.current} />
          */}
        <AlignmentSwitch editor={editorRef.current} horizontalAlignment="left" />
        <TextColorPicker editor={editorRef.current} color={'#ffffff'} />
      </Group>

      <TextEditor
        onChange={(value) => setStepTextLocal(value)}
        onLengthChange={onLengthChange}
        verticalAlignment={'top'}
        horizontalAlignment={'left'}
        text={step.text}
        ref={editorRef}
        maxLength={MAXIMUMSTEPTEXTLENGTH}
        textClassName={classes.textEditor}
        debounced={false}
      />

      <Group className={classes.footer} p={'lg'} justify={'end'}>
        <Text size="xs">
          <span ref={lengthSpanRef} />
        </Text>
        <Button
          leftSection={dirty ? <IconDeviceFloppy size={20} /> : <IconCheck size={20} />}
          variant={'primary'}
          onClick={save}
          disabled={!dirty}
          loading={setStepTextMutation.isLoading}
        >
          {dirty ? 'Save' : 'No Changes'}
        </Button>
      </Group>
    </Flex>
  );
};
