export const IconFileOpen = () => {
  return (
    <svg width="133" height="86" viewBox="0 0 133 86" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.98932 19.1015C1.28144 15.6754 3.91007 12.4678 7.40849 12.4887L127.033 13.2049C130.058 13.223 132.5 15.6801 132.5 18.7048V79.9999C132.5 83.0374 130.038 85.4999 127 85.4999H20.1879C17.5793 85.4999 15.3295 83.6674 14.8017 81.1127L1.98932 19.1015Z"
        fill="var(--elevation-quinary)"
        stroke="var(--border-tertiary-color)"
      />
      <path
        d="M14.9219 6C14.9219 2.96243 17.3843 0.5 20.4219 0.5H53.5045C54.7969 0.5 56.048 0.955134 57.0384 1.78555L60.9662 5.07908C62.1366 6.06048 63.6152 6.59836 65.1426 6.59836H127C130.038 6.59836 132.5 9.0608 132.5 12.0984V80C132.5 83.0375 130.038 85.5 127 85.5H20.4219C17.3843 85.5 14.9219 83.0375 14.9219 80V6Z"
        fill="var(--elevation-quinary)"
        stroke="var(--border-tertiary-color)"
      />
    </svg>
  );
};
