import { BackgroundImage } from '@mantine/core';
import classes from '../ProductCard.module.scss';
import { InstructionImageSize, InstructionState } from '@assemblio/type/instruction';
import { Tag } from '@assemblio/design-system';
import { useProductImage } from '../../../hooks';

interface ProductImageProps {
  productId: string;
  state: InstructionState;
  onClick?: () => void;
}

export const ProductImage = ({ productId, state, onClick }: ProductImageProps) => {
  const { imageSrc } = useProductImage(productId, InstructionImageSize.MEDIUM);

  return (
    <BackgroundImage onClick={onClick} className={classes.image} src={imageSrc}>
      <Tag state={state} m={'md'} />
    </BackgroundImage>
  );
};
