import { ActionIcon, Menu } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconCheck, IconChevronDown } from '@tabler/icons-react';
import classes from './Annotations.module.scss';
import { ContextMenuItem, IconLine } from '@assemblio/design-system';

const Marker_Options = ['arrow', 'none'] as const;
type MarkerTuple = typeof Marker_Options;
type Marker = MarkerTuple[number];

export interface Props {
  onChange?: (marker: Marker) => void;
  value: Marker;
  start: boolean;
}

export const MarkerChooser = ({ onChange, start, value }: Props) => {
  const ArrowIcon = start ? <IconArrowLeft /> : <IconArrowRight />;
  return (
    <Menu offset={8}>
      <Menu.Target>
        <ActionIcon.Group className={classes.controls__target} style={{ alignItems: 'center' }}>
          <ActionIcon c={'text-secondary'} size="sm" variant="transparent">
            {value === 'arrow' ? ArrowIcon : <IconLine />}
          </ActionIcon>
          <ActionIcon c={'text-tertiary'} size="xs" variant="transparent">
            <IconChevronDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem
          RightIcon={value === 'none' ? IconCheck : undefined}
          LeftIcon={IconLine}
          label={'None'}
          onClick={() => onChange && onChange('none')}
        />
        <ContextMenuItem
          RightIcon={value === 'arrow' ? IconCheck : undefined}
          LeftIcon={IconArrowLeft}
          label={'Arrow'}
          onClick={() => onChange && onChange('arrow')}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
