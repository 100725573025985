import { Button, Modal, Stack, TextInput, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormEvent } from 'react';

interface RenameExplorerItemModalProps {
  opened: boolean;
  onClose: () => void;
  name: string;
  onRename: (newName: string) => void;
}

export const RenameExplorerItemModal = ({ opened, onClose, name, onRename }: RenameExplorerItemModalProps) => {
  const form = useForm({
    initialValues: { folderName: '' },
    validate: {
      folderName: (value) => {
        if (value.trim().length < 3 || value.trim().length > 55) {
          return 'Name must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleRename = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    onRename(form.values.folderName);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal title={`Rename ${name}`} opened={opened} onClose={onClose} centered>
      <form onSubmit={handleRename}>
        <Stack px={'lg'} mb={'lg'}>
          <TextInput placeholder={name} {...form.getInputProps('folderName')} required />
          <Group justify={'flex-end'}>
            <Button variant={'secondary'} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant={'primary'} type={'submit'}>
              Rename
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
