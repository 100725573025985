import React, { useState } from 'react';
import { Stack, Button, Modal, Text, Divider, Group, ThemeIcon, Radio } from '@mantine/core';
import { IconFileFilled, IconInfoHexagonFilled } from '@tabler/icons-react';
import { useInstructionSyncProfileDelete, useSyncProfilesQuery } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import classes from './DeleteSyncProfileModal.module.scss';
import { ProductContentDto, ProductOverviewDto } from '@assemblio/shared/dtos';
import { SyncProfileState } from '@assemblio/shared/next-types';
import { RadioItemWarning } from '../../../../../shared/components/src/lib/ProjectExplorer/Dialogs/components/RadioItemUnavailableWarning';
import { DescriptionBox } from '../../components';

interface DeleteSyncProfileModalProps {
  product: ProductOverviewDto | ProductContentDto;
  opened: boolean;
  onClose: () => unknown;
}

export const DeleteSyncProfileModal = ({ product, opened, onClose }: DeleteSyncProfileModalProps) => {
  const { projectId } = useExplorerRouteParams();
  const [profileToDelete, setProfileToDelete] = useState<string | undefined>(undefined);

  const deleteSyncProfileMutation = useInstructionSyncProfileDelete();

  const { data } = useSyncProfilesQuery(product.id);

  const handleDelete = () => {
    if (!profileToDelete) return;
    deleteSyncProfileMutation.mutate({
      sourceId: profileToDelete,
      targetId: product.id,
      projectId,
    });
    onClose();
  };

  const handleCloseModal = () => {
    setProfileToDelete(undefined);
    onClose();
  };

  const deletableProfiles = data?.filter((profile) => {
    return profile.from.id !== product.id;
  });

  return (
    <Modal size="lg" opened={opened} title="Unsync product" onClose={handleCloseModal} centered>
      <Stack px={'lg'} py={0}>
        <Text>
          You're about to unsync{' '}
          <Group className={classes.product_highlight} gap={'xs'}>
            <ThemeIcon size={'xs'} c={'text-primary'} variant={'transparent'}>
              <IconFileFilled />
            </ThemeIcon>
            <Text span fw={'bold'}>
              {product.name}
            </Text>
          </Group>
          . Are you sure you want to proceed?
        </Text>

        <DescriptionBox Icon={<IconInfoHexagonFilled />}>
          <Text>
            After unlinking a product, you won't be able to synchronize assembly steps from the base product anymore.
            You can always re-link the product later.
          </Text>
          <Text>The current parts and steps for the product will not be affected.</Text>
          <Text>If you have any questions or need support please contact support@assemblio.de.</Text>
        </DescriptionBox>

        <Text>Select a base product to unlink: </Text>

        <Radio.Group value={profileToDelete} onChange={setProfileToDelete}>
          <Stack mx={'lg'} mt={'lg'} mb={'xl'} gap={'lg'}>
            {deletableProfiles?.map((profile) => {
              const isDeletable = !(
                profile.state === SyncProfileState.PROCESSING || profile.state === SyncProfileState.INITIALISING
              );
              return (
                <Group key={`sync-profile-to-delete-${profile.from.id}`} justify="space-between" gap={0}>
                  <Radio
                    label={profile.from.name}
                    value={profile.from.id}
                    disabled={!isDeletable}
                    maw={!isDeletable ? '60%' : '100%'}
                    classNames={{
                      labelWrapper: classes.variantRadioList__label,
                    }}
                  />
                  {!isDeletable && <RadioItemWarning reason={'Product is still processing'} />}
                </Group>
              );
            })}
          </Stack>
        </Radio.Group>
      </Stack>

      <Divider />

      <Group p="lg" style={{ justifyContent: 'flex-end' }}>
        <Button variant="secondary" mr={'sm'} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!profileToDelete} onClick={handleDelete}>
          Delete synchronisation profile
        </Button>
      </Group>
    </Modal>
  );
};
