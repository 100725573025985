import { Combobox, useCombobox, InputBase, ComboboxItem } from '@mantine/core';
import { IconChevronDown, IconProps } from '@tabler/icons-react';
import { DrowpDownItem } from '@assemblio/design-system';
import classes from './InlineSelect.module.scss';
import { ComponentType, useMemo } from 'react';

interface ComboboxItemWithIcon extends ComboboxItem {
  icon?: ComponentType<IconProps>;
}

interface InlineSelectProps {
  value?: string;
  data?: ComboboxItemWithIcon[];
  onChange: (newTargetId: string | null) => void;
}

export const InlineSelect = ({ value, data, onChange }: InlineSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = data?.map((item) => (
    <DrowpDownItem
      textProps={{ fw: item.value === value ? 'bold' : undefined }}
      LeftIcon={item.icon}
      value={item.value}
      label={item.label}
    />
  ));

  const selectedOption = useMemo(() => options?.find((option) => option.props.value === value), [value]);

  return (
    <Combobox
      store={combobox}
      classNames={{
        options: classes.options,
      }}
      withinPortal={false}
      onOptionSubmit={(val) => {
        onChange(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          classNames={{
            root: classes.root,
            input: classes.input,
            section: classes.section,
          }}
          maw={'50%'}
          component="button"
          type="button"
          pointer
          rightSection={<IconChevronDown size={12} />}
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents="none"
          multiline
        >
          {selectedOption || 'Select target'}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options?.filter((item) => item.props.value !== value)}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
