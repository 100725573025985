export const IconCube = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65654 0.596189C7.24187 0.395143 6.75797 0.395015 6.34319 0.595838L1.44888 2.9655C1.24632 3.06358 1.07257 3.20342 0.935936 3.37176C0.951277 3.37772 0.966504 3.38434 0.981579 3.39161L7.00085 6.29405L13.0184 3.39489C13.0329 3.38795 13.0474 3.3816 13.0621 3.37585C12.9253 3.2067 12.7511 3.0662 12.548 2.96771L7.65654 0.596189ZM0.599298 9.67092L0.599121 4.60055L6.37243 7.38438V13.4001C6.36355 13.396 6.35471 13.3919 6.3459 13.3877L1.45124 11.0275C0.930378 10.7763 0.599305 10.2491 0.599298 9.67092ZM7.62749 13.4002C7.63642 13.3961 7.64532 13.3919 7.65418 13.3877L12.5488 11.0275C13.0697 10.7763 13.4008 10.2488 13.4008 9.67055L13.3973 4.6055L7.62749 7.38527V13.4002Z"
        fill="currentColor"
      />
    </svg>
  );
};
