import { useQuery } from '@tanstack/react-query';
import { fetchInstructionImage } from './instruction-image.requests';
import { InstructionImageQueryDto } from '@assemblio/shared/dtos';

export interface InstructionImageQueryProps {
  productId?: string;
  query: InstructionImageQueryDto;
  enabled?: boolean;
}

export const useInstructionImageQuery = (props: InstructionImageQueryProps) => {
  return useQuery(
    ['instruction-image', props.productId, props.query.size],
    () => {
      return fetchInstructionImage(props.productId, props.query);
    },
    {
      enabled: props.enabled,
      retry: false,
    }
  );
};
