import { useInstructionDelete } from '@assemblio/frontend/data-access';
import { useModalContext } from '@assemblio/frontend/components';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useExplorerRouteParams } from './ExplorerRouteParams.hook';

export const useDeleteProduct = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { openModal } = useModalContext();
  const { projectId } = useExplorerRouteParams();
  const navigate = useNavigate();
  const deleteProductMutation = useInstructionDelete();

  const deleteProduct = (productName: string, productId: string, navigationTarget?: string) => {
    openModal('confirmation-modal', {
      onConfirm: () => handleDeleteProduct(productId, navigationTarget),
      label: `Delete Product ${productName}`,
      message: `Are you sure you want to delete ${productName}? This will also delete all exported manuals and cannot be undone.`,
      buttonLabel: 'Delete',
    });
  };

  const handleDeleteProduct = (productId: string, navigationTarget?: string) => {
    setIsLoading(true);
    deleteProductMutation.mutate(
      {
        projectId: projectId ?? '',
        instructionId: productId,
      },
      {
        onSuccess: () => {
          if (navigationTarget) {
            navigate(navigationTarget);
          }
          notifications.show({
            id: 'delete-instruction-success',
            message: 'Product has been deleted',
            color: 'green',
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return { deleteProduct, isLoading };
};
