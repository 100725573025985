import React from 'react';
import { Group, TextInput, Select } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import classes from '../AddUsersModal.module.scss';

interface UserInput {
  email: string;
  role: string;
}

interface AddUserInputProps {
  user: UserInput;
  index: number;
  roleOptions: { value: string; label: string }[];
  error: string;
  handleChange: (index: number, field: keyof UserInput, value: string) => void;
}

export const AddUserInput = ({ user, index, roleOptions, error, handleChange }: AddUserInputProps) => {
  return (
    <Group className={classes.userInputGroup}>
      <TextInput
        w={'65%'}
        placeholder="Email address"
        value={user.email}
        onChange={(event) => handleChange(index, 'email', event.currentTarget.value)}
        error={error}
        required
        classNames={{ input: classes.input }}
        c="text-tertiary"
      />
      <Select
        w={'31%'}
        rightSection={<IconChevronDown size="16" color="var(--text-primary)" />}
        data={roleOptions}
        value={user.role}
        onChange={(value) => handleChange(index, 'role', value!)}
        classNames={{ input: classes.select }}
      />
    </Group>
  );
};
