import { useState } from 'react';
import { useAuthStore, useCreateEmptyProject } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';
import { notifications } from '@mantine/notifications';

export const useCreateProject = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { folderId } = useExplorerRouteParams();
  const userId = useAuthStore((state) => state.userId);
  const createProjectMutation = useCreateEmptyProject();

  const createProject = (name: string) => {
    if (!folderId) return;
    setIsLoading(true);
    createProjectMutation.mutate(
      {
        name,
        folderId,
        ownerId: userId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'create-project-success',
            message: 'Project has been created',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'create-project-error',
            message: 'Project could not be created',
            color: 'red',
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return {
    createProject,
    isLoading,
  };
};
