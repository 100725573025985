import { usePlaybackState } from '@assemblio/frontend/hooks';
import { AnimationController, MachineController, useAnimationStore } from '@assemblio/frontend/stores';
import { ActionIcon, Card, Group, Stack } from '@mantine/core';
import {
  IconArrowBarToRight,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerSkipBackFilled,
  IconRepeat,
  IconRepeatOff,
} from '@tabler/icons-react';
import { Seeker } from './Seeker';
import React, { useState } from 'react';
import classes from './PlaybackControls.module.scss';
import { useHover } from '@mantine/hooks';
import cx from 'clsx';
import { IconFastForward, IconRewind } from '@assemblio/design-system';
interface PlaybackControlsProps {
  showRepeatButton?: boolean;
}

export const PlaybackControls = ({ showRepeatButton = true }: PlaybackControlsProps) => {
  const [collapsed, setCollapsed] = useState(false);

  const { hovered, ref } = useHover();

  const loopAnimation = useAnimationStore((state) => state.loop);

  const playbackState = usePlaybackState();

  const actionIconSize = 'sm';

  const playActionIconSize = 'md';

  const toggleCollapse = (e: React.MouseEvent) => {
    setCollapsed(!collapsed);
  };

  return (
    <Group
      ref={ref}
      className={cx(classes.wrapper, {
        [classes['wrapper--collapsed']]: collapsed,
      })}
    >
      <Card
        py={'lg'}
        px={'xl'}
        radius={'md'}
        className={cx(classes.wrapper__card, {
          [classes['wrapper__card--collapsed']]: collapsed,
        })}
      >
        <Stack justify="center" align="center" h={'100%'} w={'100%'}>
          <Group wrap="nowrap" justify="space-between" gap="0" w={'100%'}>
            <ActionIcon
              data-cy="icon-player-stop-button"
              color={'text-secondary'}
              variant="transparent"
              style={{
                background: 'transparent',
              }}
              disabled={playbackState === 'stopped'}
              size={actionIconSize}
              onClick={() => MachineController.stopAnimation()}
            >
              <IconPlayerSkipBackFilled size={16} />
            </ActionIcon>
            <Group
              gap={'sm'}
              className={cx(classes.wrapper__card__actions, {
                [classes['wrapper__card__actions--collapsed']]: collapsed,
              })}
            >
              <ActionIcon
                data-cy="icon-player-skip-back"
                color={'text-secondary'}
                variant="transparent"
                size={actionIconSize}
                onClick={() => MachineController.skipBackward()}
              >
                <IconRewind />
              </ActionIcon>
              {playbackState === 'playing' ? (
                <ActionIcon
                  data-cy="icon-player-pause-button"
                  color={'text-secondary'}
                  variant="transparent"
                  size={playActionIconSize}
                  onClick={() => MachineController.pauseAnimation()}
                >
                  <IconPlayerPauseFilled size={'16'} />
                </ActionIcon>
              ) : (
                <ActionIcon
                  data-cy="icon-player-play-button"
                  color={'text-secondary'}
                  variant="transparent"
                  style={{ opacity: 1 }}
                  size={playActionIconSize}
                  onClick={() => MachineController.playAnimation()}
                >
                  <IconPlayerPlayFilled size={16} />
                </ActionIcon>
              )}
              <ActionIcon
                data-cy="icon-player-skip-forward"
                color={'text-secondary'}
                variant="transparent"
                size={actionIconSize}
                onClick={() => MachineController.skipForward()}
              >
                <IconFastForward />
              </ActionIcon>
            </Group>
            {showRepeatButton && (
              <ActionIcon
                data-looping={loopAnimation}
                variant="transparent"
                color={'text-secondary'}
                size={actionIconSize}
                onClick={() => AnimationController.toggleLoopAnimations()}
              >
                {loopAnimation ? <IconRepeat size={16} /> : <IconRepeatOff size={16} />}
              </ActionIcon>
            )}
          </Group>
          {!collapsed && <Seeker />}
        </Stack>
      </Card>
      <ActionIcon
        color="gray.6"
        variant="subtle"
        size={'xs'}
        className={cx(classes.wrapper__collapseButton, {
          [classes['wrapper__collapseButton--hidden']]: !hovered,
          [classes['wrapper__collapseButton--collapsed']]: collapsed,
        })}
        onClick={toggleCollapse}
      >
        <IconArrowBarToRight />
      </ActionIcon>
    </Group>
  );
};
