import { useViewerInfoQuery } from '@assemblio/frontend/data-access';
import { Button, Divider, Group, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface OwnershipErrorModalProps {
  resourceName: string;
  resourceId: string;
  opened: boolean;
  onClose: () => unknown;
}

export const OwnershipErrorModal = ({ resourceName, resourceId, opened, onClose }: OwnershipErrorModalProps) => {
  const { data: viewerInfo } = useViewerInfoQuery();

  const handleTransistionToKIM = () => {
    if (viewerInfo) {
      window.open(`${viewerInfo.baseURL}/assembler/${resourceId}`, '_blank');
    } else {
      notifications.show({
        id: 'reroute-to-kim',
        message: 'Something went wrong. Please try again later',
        color: 'red',
      });
    }
    onClose();
  };

  return (
    <Modal
      title={'Not allowed'}
      opened={opened}
      withCloseButton
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      centered
      withinPortal
    >
      <Text px={'lg'} mb={'xl'}>
        Only the owner can edit a product and you are currently not the owner of <b>{resourceName}</b>. {<br />} You can
        still view this instruction using the smart viewer. Open Viewer will open a new tab in your browser.
      </Text>

      <Divider />
      <Group p={'lg'} w={'100%'} justify={'flex-end'}>
        <Button variant={'secondary'} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={() => handleTransistionToKIM()}>
          Open Viewer
        </Button>
      </Group>
    </Modal>
  );
};
