import React, { useState } from 'react';
import { Stack, Button, Modal, Text, Divider, Group, ThemeIcon } from '@mantine/core';
import { IconFileFilled, IconInfoHexagonFilled } from '@tabler/icons-react';
import { useInstructionCreateSyncProfile } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import classes from './CreateSyncProfileModal.module.scss';
import { VersionSelection } from './component/VersionSelection/VersionSelection';
import { ProductContentDto, ProductOverviewDto } from '@assemblio/shared/dtos';
import { DescriptionBox } from '../../components';

interface CreateSyncProfileModalProps {
  product: ProductOverviewDto | ProductContentDto;
  opened: boolean;
  onClose: () => unknown;
}

export const CreateSyncProfileModal = ({ product, opened, onClose }: CreateSyncProfileModalProps) => {
  const { projectId } = useExplorerRouteParams();
  const [syncProfileSource, setSyncProfileSource] = useState<string | undefined>(undefined);
  const [selectedVersions, setSelectedVersions] = useState<{ [key: string]: string | null }>({});

  const createSyncProfileMutation = useInstructionCreateSyncProfile();

  const handleCreate = () => {
    if (!syncProfileSource) return;
    createSyncProfileMutation.mutate({
      from: syncProfileSource,
      to: product.id,
      projectId: projectId,
    });
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSyncProfileSource(undefined);
    onClose();
  };

  const versions = [
    { value: 'v1.0', label: 'V1.0' },
    { value: 'v2.0', label: 'V2.0' },
  ];

  const handleVersionChange = (id: string, version: string | null) => {
    setSelectedVersions((prev) => ({ ...prev, [id]: version }));
  };

  return (
    <Modal
      size="600"
      opened={opened}
      title="Create Synchronisation Profile"
      onClose={handleCloseModal}
      centered
      classNames={{
        title: classes.customModalTitle,
      }}
    >
      <Stack px={'lg'} py={0}>
        <Text>
          Sync{' '}
          <Group className={classes.product_highlight} gap={'xs'}>
            <ThemeIcon size={'xs'} c={'text-primary'} variant={'transparent'}>
              <IconFileFilled />
            </ThemeIcon>
            <Text span fw={'bold'}>
              {product.name}
            </Text>
          </Group>{' '}
          to another product in this project.
        </Text>

        <DescriptionBox Icon={<IconInfoHexagonFilled />}>
          <Text>
            After a Synchronisation Profile is created, you can set the steps from one product to another. You can only
            create sync profiles between products in the same project.
          </Text>
          <Text>If you have any questions or need support please contact support@assemblio.de.</Text>
        </DescriptionBox>

        <VersionSelection
          productId={product.id}
          syncProfileSource={syncProfileSource}
          setSyncProfileSource={setSyncProfileSource}
          selectedVersions={selectedVersions}
          handleVersionChange={handleVersionChange}
          versions={versions}
        />
      </Stack>

      <Divider />

      <Group p="lg" style={{ justifyContent: 'flex-end' }}>
        <Button variant="secondary" mr={'sm'} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!syncProfileSource} onClick={handleCreate}>
          Create synchronisation profile
        </Button>
      </Group>
    </Modal>
  );
};
