import { useQuery } from '@tanstack/react-query';
import { fetchArtefactCount, fetchArtefactsForInstruction, fetchV1ArtefactsForProduct } from './artefact.requests';
import { FetchProductArtefacts } from './types/artefact.request.types';

export const useInstructionArtefactsQuery = (instructionId: string) => {
  return useQuery(['artefacts', instructionId], () => fetchArtefactsForInstruction(instructionId), {
    refetchOnWindowFocus: false,
  });
};
export const useV1ProductArtefactsQuery = (data: FetchProductArtefacts) => {
  return useQuery(['artefacts', data.productId], () => fetchV1ArtefactsForProduct(data), {
    refetchOnWindowFocus: false,
    enabled: Boolean(data.productId),
  });
};

export const useInstructionArtefactsCountQuery = (instructionId: string | undefined) => {
  return useQuery(['artefact-count', instructionId], () => fetchArtefactCount(instructionId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(instructionId),
  });
};
