import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Popover, Slider, Space, Tooltip } from '@mantine/core';
import { IconRuler } from '@tabler/icons-react';
import classes from './Viewport.module.scss';

export const RotationSnapSettings = () => {
  const action = useUIStore((state) => state.transformGizmo.action);
  const snappingAngle = useUIStore((state) => state.transformGizmo.snappingAngle);
  return (
    <Popover position="bottom" width={200} disabled={action !== 'rotate'}>
      <Popover.Target>
        <Tooltip offset={8} position="bottom" label="Set rotation snapping value">
          <ActionIcon
            className={classes.controls__icon}
            c={action !== 'rotate' ? 'text-disabled' : 'text-secondary'}
            size="sm"
            variant="transparent"
            disabled={action !== 'rotate'}
          >
            <IconRuler />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Slider
          step={5}
          min={0}
          max={15}
          value={snappingAngle}
          marks={[
            { value: 0, label: 'Free' },
            { value: 5, label: '5°' },
            { value: 10, label: '10°' },
            { value: 15, label: '15°' },
          ]}
          onChange={(value) => {
            UIController.setSnappingAngle(value);
          }}
        />
        <Space h="md" />
      </Popover.Dropdown>
    </Popover>
  );
};
