import { Group, Tooltip, Checkbox, ThemeIcon } from '@mantine/core';
import { IconInfoHexagonFilled } from '@tabler/icons-react';
import React from 'react';

interface CheckboxWithHintProps {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  hintText?: string;
}

export const CheckboxWithHint = ({ onChange, checked, label, hintText }: CheckboxWithHintProps) => {
  return (
    <Group ml={'sm'} gap={0} mb={'xs'}>
      <Checkbox
        mr={'sm'}
        w={'200px'}
        size={'xs'}
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.checked)}
        label={label}
      />
      {hintText && (
        <Tooltip label={hintText}>
          <ThemeIcon variant={'transparent'} size={'xs'} c={'text-primary'}>
            <IconInfoHexagonFilled />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  );
};
