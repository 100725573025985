import { Tooltip, useComputedColorScheme } from '@mantine/core';

interface ImageMarkProps {
  variant?: 'white' | 'dark';
  accented?: boolean;
  size?: number;
  withCircle?: boolean;
}

/**
 * Assemblio Image Mark.
 * If no variant is set the Image Mark color will change according to the current color scheme.
 * @param variant 'white' or 'dark'
 * @param accented If true the left line of the Logo will be colored in brand Purple
 * @param size Height and Width in px
 * @param withCircle
 * @returns
 */
export const ImageMark = ({ size = 36, accented = true, withCircle = false, variant }: ImageMarkProps) => {
  const colorScheme = useComputedColorScheme();
  const usedVariant = typeof variant === 'undefined' ? (colorScheme === 'dark' ? 'white' : 'dark') : variant;
  const buildVersion = process.env['NX_PUBLIC_BUILD_VERSION'];
  const accentColor = accented ? '#6A00EB' : usedVariant === 'white' ? 'white' : '#0C0712';
  const color = usedVariant === 'white' ? 'white' : '#0C0712';
  return (
    <Tooltip label={buildVersion ?? 'local'} openDelay={3000}>
      <svg width={size} height={size} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
        {withCircle && (
          <rect
            x="3"
            y="3"
            width="174"
            height="174"
            rx="87"
            stroke={usedVariant === 'white' ? 'white' : '#0C0712'}
            strokeWidth="6"
          />
        )}
        <path d="M83.0801 35.6809L88.5518 45.1583L44.0587 122.223H33.1152L83.0801 35.6809Z" fill={accentColor} />
        <path d="M88.8281 63.436L136.057 145.239H147.001L94.2999 53.9586L88.8281 63.436Z" fill={color} />
        <path d="M82.4531 88.4835L93.3966 88.4835L106.622 111.391L95.6789 111.391L82.4531 88.4835Z" fill={color} />
      </svg>
    </Tooltip>
  );
};
