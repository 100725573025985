export const IconRewind = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.14075 0.173097L0.283714 4.17303C0.197389 4.22334 0.125762 4.2954 0.0759802 4.38203C0.026198 4.46866 0 4.56683 0 4.66674C0 4.76666 0.026198 4.86482 0.0759802 4.95145C0.125762 5.03808 0.197389 5.11014 0.283714 5.16045L7.14075 9.16039C7.22759 9.21106 7.32626 9.23792 7.42681 9.23826C7.52735 9.2386 7.6262 9.21241 7.71338 9.16233C7.80057 9.11225 7.87299 9.04005 7.92334 8.95302C7.9737 8.86599 8.00019 8.76722 8.00016 8.66668V0.666804C8.00019 0.56626 7.9737 0.467489 7.92334 0.380463C7.87299 0.293436 7.80057 0.221236 7.71338 0.171153C7.6262 0.12107 7.52735 0.0948768 7.42681 0.0952181C7.32626 0.0955595 7.22759 0.122423 7.14075 0.173097Z"
        fill="currentColor"
      />
      <path
        d="M15.1406 0.173097L8.28355 4.17303C8.19722 4.22334 8.1256 4.2954 8.07582 4.38203C8.02603 4.46866 7.99983 4.56683 7.99983 4.66674C7.99983 4.76666 8.02603 4.86482 8.07582 4.95145C8.1256 5.03808 8.19722 5.11014 8.28355 5.16045L15.1406 9.16039C15.2274 9.21106 15.3261 9.23792 15.4266 9.23826C15.5272 9.2386 15.626 9.21241 15.7132 9.16233C15.8004 9.11225 15.8728 9.04005 15.9232 8.95302C15.9735 8.86599 16 8.76722 16 8.66668V0.666804C16 0.56626 15.9735 0.467489 15.9232 0.380463C15.8728 0.293436 15.8004 0.221236 15.7132 0.171153C15.626 0.12107 15.5272 0.0948768 15.4266 0.0952181C15.3261 0.0955595 15.2274 0.122423 15.1406 0.173097Z"
        fill="currentColor"
      />
    </svg>
  );
};
