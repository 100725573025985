export const IconTextColour = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13.5001C2 12.6717 2.67157 12.0001 3.5 12.0001H12.5C13.3284 12.0001 14 12.6717 14 13.5001C14 14.3285 13.3284 15.0001 12.5 15.0001H3.5C2.67157 15.0001 2 14.3285 2 13.5001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3038 9.31115C6.3038 9.62346 6.05063 9.87663 5.73833 9.87663C5.42602 9.87663 5.17285 9.62346 5.17285 9.31115V4.78734C5.17285 4.03747 5.47074 3.31832 6.00097 2.78808C6.53121 2.25784 7.25036 1.95996 8.00023 1.95996C8.7501 1.95996 9.46926 2.25784 9.99949 2.78808C10.5297 3.31832 10.8276 4.03747 10.8276 4.78734V9.31115C10.8276 9.62346 10.5744 9.87663 10.2621 9.87663C9.94983 9.87663 9.69666 9.62346 9.69666 9.31115V6.48377H6.3038V9.31115ZM6.80068 3.58779C7.11882 3.26964 7.55031 3.09091 8.00023 3.09091C8.45015 3.09091 8.88165 3.26964 9.19979 3.58779C9.51793 3.90593 9.69666 4.33742 9.69666 4.78734V5.35282H6.3038V4.78734C6.3038 4.33742 6.48253 3.90593 6.80068 3.58779Z"
        fill="currentColor"
      />
    </svg>
  );
};
