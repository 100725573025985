import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconBubbleText, IconBubble } from '@tabler/icons-react';
import classes from './Viewport.module.scss';

export const SidebarTextRendererButton = () => {
  const showSidebarTextRenderer = useUIStore((state) => state.showSidebarTextRenderer);
  const tooltipLabel = showSidebarTextRenderer ? 'Hide Step Text in Viewport' : 'Show Step Text in Viewport';
  return (
    <Tooltip offset={8} position="bottom" label={tooltipLabel}>
      <ActionIcon
        style={{
          pointerEvents: 'auto',
        }}
        color={'text-secondary'}
        size="sm"
        className={classes.controls__icon}
        variant="transparent"
        onClick={() => UIController.setShowSidebarTextRenderer(!showSidebarTextRenderer)}
      >
        {showSidebarTextRenderer ? <IconBubble /> : <IconBubbleText />}
      </ActionIcon>
    </Tooltip>
  );
};
