import { Menu } from '@mantine/core';
import classes from '../../../pages/assembly-plans/components/ItemContextMenu/ItemContextMenu.module.scss';
import { ContextMenuButton, ContextMenuItem } from '@assemblio/design-system';
import { IconDownload, IconTrashFilled } from '@tabler/icons-react';
import { downloadArtefact, useArtefactDelete } from '@assemblio/frontend/data-access';
import { saveAs } from 'file-saver';
import { useExplorerRouteParams } from '../../../hooks/ExplorerRouteParams.hook';
import { useModalContext } from '@assemblio/frontend/components';

interface ArtefactContextMenuProps {
  artefactId: string;
  name: string;
  disabled?: boolean;
}

export const ArtefactContextMenu = ({ artefactId, name, disabled }: ArtefactContextMenuProps) => {
  const { openModal } = useModalContext();
  const { productId } = useExplorerRouteParams();

  const deleteArtefactMutation = useArtefactDelete();

  const handleDelete = () => {
    if (!productId) return;
    deleteArtefactMutation.mutate({ instructionId: productId, artefactId: artefactId });
  };

  const handleDeleteArtefact = () => {
    openModal('confirmation-modal', {
      label: 'Delete Artefact',
      message: 'Are you sure you want to delete this artefact?',
      onConfirm: handleDelete,
    });
  };

  const handleDownloadArtefact = () => {
    if (!productId) return;
    downloadArtefact(productId, artefactId).then((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, name);
    });
  };

  return (
    <Menu
      disabled={disabled}
      position="bottom-start"
      withinPortal
      trapFocus={false}
      classNames={{
        itemLabel: classes.menuButton__itemLabel,
      }}
      trigger={'hover'}
    >
      <Menu.Target>
        <ContextMenuButton />
      </Menu.Target>

      <Menu.Dropdown data-cy="menu-dropdown">
        <ContextMenuItem LeftIcon={IconDownload} label={'Download'} onClick={handleDownloadArtefact} />

        <ContextMenuItem LeftIcon={IconTrashFilled} label={'Delete'} onClick={handleDeleteArtefact} />
      </Menu.Dropdown>
    </Menu>
  );
};
