import React from 'react';
import { Box, Group, Text, Stack } from '@mantine/core';
import { Mock_VersionInformation } from '../types/utils.types';
import classes from '../VersionHistoryTab.module.scss';
import { IconCircleDot, IconCircleDotFilled } from '@tabler/icons-react';

interface VersionItemProps {
  versionInfo: Mock_VersionInformation;
}

export const VersionItem = ({ versionInfo }: VersionItemProps) => {
  const { version, type, timestamp, state, action, comment, issuer } = versionInfo;
  const isMajor = type === 'Major';

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' };
    const [month, day] = date.toLocaleDateString('en-US', options).split(' ');
    return `${day} ${month}`;
  };

  return (
    <Stack className={classes.versionItem} gap={'xs'}>
      <Group className={classes.versionHeader}>
        <Box className={`${classes.versionDotWrapper} ${comment ? classes.withComment : ''}`}>
          <IconCircleDot size={30} className={classes.versionDot} />
        </Box>
        <Box className={`${classes.versionBox} ${isMajor ? classes.major : classes.minor}`}>
          <Text fw={600} size={'xs'} className={isMajor ? classes.major : classes.minor}>{`Version ${version}`}</Text>
        </Box>
        <Text size={'xs'} fw={400}>
          {formatDate(timestamp)}
        </Text>
        <IconCircleDotFilled size={5} />
        <Text size={'xs'} fw={400}>
          {state}
        </Text>
      </Group>
      <Stack className={classes.versionDetails} gap={'xs'}>
        <Text size="sm">
          <span style={{ fontWeight: 'bold' }}>
            {issuer.firstName} {issuer.lastName}
          </span>{' '}
          {action.toLowerCase()} this file
        </Text>
        {comment && (
          <Text size={'xs'} className={classes.comment}>
            {comment}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
