import { Box, Combobox, Group, Text } from '@mantine/core';
import classes from '../ItemSearch.module.scss';
import { IconSearchResultProduct } from '@assemblio/design-system';

interface SearchResultProps {
  name: string;
  id: string;
  user?: string;
  date?: Date;
}

export const SearchResult = ({ name, id, user, date }: SearchResultProps) => {
  return (
    <Combobox.Option key={id} value={id} className={classes.option}>
      <Group justify="space-between" align="center" gap="md" px="lg" py="sm">
        <Group>
          <Box className={classes.box} p={'xs'}>
            <IconSearchResultProduct />
          </Box>
          <div className={classes.info}>
            <Text className={classes.name} size="sm" variant="medium">
              {name}
            </Text>
            {user && (
              <Text c="text-secondary" size="xs" fw={400}>
                {user}
              </Text>
            )}
          </div>
        </Group>
        {date && (
          <Text c="text-secondary" size="xs" fw={400}>
            {date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
          </Text>
        )}
      </Group>
    </Combobox.Option>
  );
};
