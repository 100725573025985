import React, { PropsWithChildren, ReactNode } from 'react';
import { Group, ThemeIcon, Stack, Text } from '@mantine/core';
import classes from './DescriptionBox.module.scss';

interface DescriptionBoxProps {
  Icon: ReactNode;
}

export const DescriptionBox = ({ children, Icon }: PropsWithChildren<DescriptionBoxProps>) => {
  return (
    <Group
      className={classes.description__box}
      justify={'flex-start'}
      align={'flex-start'}
      p={'md'}
      wrap={'nowrap'}
      gap={'sm'}
    >
      <ThemeIcon size={'sm'} mt={'xs'} c={'text-primary'} variant={'transparent'}>
        {Icon}
      </ThemeIcon>
      <Stack>{children}</Stack>
    </Group>
  );
};
