import { Button, Group, Skeleton, Stack, Title } from '@mantine/core';
import classes from './Header.module.scss';
import { useModalContext } from '@assemblio/frontend/components';
import { ExplorerItemType } from '../../pages/assembly-plans/types/items.types';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { useContext } from 'react';
import { ExplorerTitleContext } from '../../providers/ExplorerTitleProvider';
import { VersionBadge } from './components/VersionBadge/VersionBadge';
import { ItemCountBadge } from '../../components';
import { BreadcrumbNavigation } from './components/BreadcrumbNavigation/BreadcrumbNavigation';
import cx from 'clsx';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import { useUIStore } from '@assemblio/frontend/stores';
import { FileMetaData } from '@assemblio/frontend/hooks';
import { Tag } from '@assemblio/design-system';

interface HeaderProps {
  uploadFile?: (file: File, meta: FileMetaData) => Promise<boolean>;
  removeFile?: () => void;
  uploadReady?: boolean;
}

export const Header = ({ uploadReady, uploadFile, removeFile }: HeaderProps) => {
  const isViewer = useUIStore.getState().view === 'viewer';

  const { openModal } = useModalContext();
  const { projectId, productId } = useExplorerRouteParams();

  const { versionTag, itemCount, title, state } = useContext(ExplorerTitleContext);

  const showAddFolderButton = !projectId && !productId;

  const showAddProductButton = !!projectId && !productId;

  const showAddProjectButton = !projectId && !productId;

  const openAddFolderModal = () => {
    openModal('create-explorer-item', { type: ExplorerItemType.Folder });
  };

  const openAddProjectModal = () => {
    openModal('create-explorer-item', { type: ExplorerItemType.Project });
  };

  const openCreateProductModal = () => {
    if (uploadFile && removeFile) {
      openModal('create-product', {
        submitFileUpload: uploadFile,
        removeFile,
        uploadDisabled: !uploadReady,
      });
    }
  };

  return (
    <Stack
      className={cx(classes.container, {
        [classes.container__product_details]: !!productId,
      })}
      gap={'sm'}
    >
      <Group wrap={'nowrap'} align={'end'} justify={'space-between'}>
        <Stack>
          <BreadcrumbNavigation />
          <Group gap={'md'} wrap={'nowrap'} data-cy="headerText">
            {title ? (
              <Title order={1} lineClamp={1}>
                {title}
              </Title>
            ) : (
              <Skeleton w={'300px'} h={'2.5rem'} />
            )}
            {itemCount && <ItemCountBadge count={itemCount} />}
            {state && <Tag state={state} />}
            {versionTag && <VersionBadge version={versionTag} />}
          </Group>
        </Stack>
        {!isViewer && (
          <Group wrap={'nowrap'}>
            {showAddFolderButton && (
              <Button leftSection={<IconCirclePlusFilled />} variant={'secondary'} onClick={openAddFolderModal}>
                Add Folder
              </Button>
            )}
            {showAddProductButton && (
              <Button
                disabled={!uploadReady}
                leftSection={<IconCirclePlusFilled />}
                variant={'primary'}
                onClick={openCreateProductModal}
              >
                Add Product
              </Button>
            )}
            {showAddProjectButton && (
              <Button leftSection={<IconCirclePlusFilled />} variant={'primary'} onClick={openAddProjectModal}>
                Add Project
              </Button>
            )}
          </Group>
        )}
      </Group>
    </Stack>
  );
};
