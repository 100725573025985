import { AnimationController, MachineController } from '@assemblio/frontend/stores';
import { Group, Slider } from '@mantine/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import classes from './PlaybackControls.module.scss';
export const Seeker = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    return AnimationController.on('reset', () => {
      AnimationController.on('update', (time: number, duration: number) => {
        setProgress(+((time / duration) * 100).toFixed(1));
      });
    });
  });

  return (
    <Group justify="center" style={{ width: '100%' }}>
      <Slider
        style={{ flex: '1 1 auto' }}
        max={100}
        min={0}
        value={progress}
        scale={(value) => Math.round(value)}
        label={null}
        size={'xs'}
        classNames={{
          root: classes.seeker,
          track: classes.seekerTrack,
          thumb: classes.seekerThumb,
        }}
        step={0.1}
        precision={2}
        onChange={(percentage) => {
          AnimationController.setLoopAnimations(false);
          if (AnimationController.getAnimationLength() === 0) {
            MachineController.playAnimation();
            _.defer(() => {
              MachineController.pauseAnimation();
              AnimationController.seek(percentage / 100.0);
            });
          } else {
            AnimationController.seek(percentage / 100.0);
          }
        }}
      />
    </Group>
  );
};
