import { KIMInfoDto } from '@assemblio/shared/next-types';
import { axiosHandler } from '../utils/axios-utils';

export const getKIMInfo = async (): Promise<KIMInfoDto> => {
  return axiosHandler('get', `/app/info/kim`);
};

export const getViewerInfo = async (): Promise<KIMInfoDto> => {
  return axiosHandler('get', `v1/app/info/viewer`);
};
