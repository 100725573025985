import { createContext, PropsWithChildren, useState } from 'react';
import { TitleExtension } from '../types';
import { InstructionState } from '@assemblio/type/instruction';

interface ExplorerTitleContextType {
  title: string | undefined;
  setHeaderTitle: (title: string | undefined, extension?: TitleExtension) => void;
  versionTag: string | undefined;
  itemCount: number | undefined;
  state: InstructionState | undefined;
}

export const ExplorerTitleContext = createContext<ExplorerTitleContextType>({
  title: undefined,
  versionTag: undefined,
  itemCount: undefined,
  state: undefined,
  setHeaderTitle: (title: string | undefined, extension?: TitleExtension) => null,
});

export const ExplorerTitleProvider = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [versionTag, setVersionTag] = useState<string | undefined>(undefined);
  const [itemCount, setItemCount] = useState<number | undefined>(undefined);
  const [productState, setProductState] = useState<InstructionState | undefined>(undefined);
  const setHeaderTitle = (title: string | undefined, extension?: TitleExtension) => {
    setTitle(title);
    setVersionTag(extension?.versionTag);
    setItemCount(extension?.itemCount);
    setProductState(extension?.state);
  };

  return (
    <ExplorerTitleContext.Provider value={{ title, setHeaderTitle, versionTag, itemCount, state: productState }}>
      {children}
    </ExplorerTitleContext.Provider>
  );
};
