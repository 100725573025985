import { axiosHandlerWithResponse } from '../utils/axios-utils';
import { InstructionImageQueryDto } from '@assemblio/shared/dtos';

export const fetchInstructionImage = async (
  id: string | undefined,
  query: InstructionImageQueryDto
): Promise<Blob | null> => {
  if (typeof id === 'undefined') {
    return Promise.reject(new Error('Invalid id'));
  }

  const response = await axiosHandlerWithResponse('get', `v1/instructions/${id}/image`, {
    params: query,
    responseType: 'blob',
  });

  if (response.status === 204 || response.status === 404) {
    return null;
  }

  return response.data;
};
