import { FC, useEffect, useState } from 'react';
import { useModalContext } from '@assemblio/frontend/components';
import { ProductContentDto } from '@assemblio/shared/dtos';
import {
  IconCopy,
  IconDownload,
  IconRefresh,
  IconStar,
  IconStarFilled,
  IconTableOptions,
  IconTrashFilled,
  IconUnlink,
} from '@tabler/icons-react';
import { useDeleteProduct, useRenameProduct } from '../../../../../hooks';
import {
  useAuthStore,
  useCreateProductRevision,
  useInstructionSetFavorite,
  useProductOwnerChange,
} from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useExplorerRouteParams } from '../../../../../hooks/ExplorerRouteParams.hook';
import { IconChangeUser, IconEdit, IconRequestReview } from '@assemblio/design-system';
import { SyncProfileRelationDto } from '@assemblio/shared/next-types';
import { InstructionState } from '@assemblio/type/instruction';
import { useUIStore } from '@assemblio/frontend/stores';
import { useHasFeatureFlag } from '../../../../../hooks/HasFeatureFlag.hook';
import { FeatureFlag } from '@assemblio/type/feature-flag';

interface ProductAction {
  label: string;
  Icon: FC;
  onClick: () => void;
  disabled?: boolean;
  show?: boolean;
}

export const useProductActions = (
  product: ProductContentDto | undefined,
  syncProfiles?: SyncProfileRelationDto[],
  variantsEnabled?: boolean
) => {
  const isViewer = useUIStore.getState().view === 'viewer';

  const reviewsEnabled = useHasFeatureFlag(FeatureFlag.BetaReview);

  const { projectId } = useExplorerRouteParams();
  const { openModal } = useModalContext();
  const currentUserId = useAuthStore((state) => state.userId);
  const [isLoading, setIsLoading] = useState(false);

  const showUnsyncProductAction = variantsEnabled && syncProfiles?.some((profile) => profile.from.id !== product?.id);

  const editableProfiles = syncProfiles?.filter((profile) => profile.from.id === product?.id);

  const { renameProduct, isLoading: isRenameMutationLoading } = useRenameProduct();
  const { deleteProduct, isLoading: isDeleteMutationLoading } = useDeleteProduct();
  const createRevisionMutation = useCreateProductRevision();
  const changeOwnerMutation = useProductOwnerChange();
  const setFavoriteMutation = useInstructionSetFavorite();

  const onDelete = () => {
    if (!product) return;
    deleteProduct(product.name, product.id, `/v2/explorer/project/${product.project.id}`);
  };

  const onRename = () => {
    if (!product) return;
    renameProduct(product.name, product.id);
  };

  const showBasedOnState = (state: InstructionState | undefined): boolean => {
    const allowedStates = [InstructionState.DRAFT, InstructionState.REQUEST_CHANGE];
    return !!(state && allowedStates.includes(state));
  };

  const handleEditSyncProfile = () => {
    openModal('edit-sync-profile', {
      syncRelations: editableProfiles,
    });
  };

  const handleRequestReview = (userId: string) => {
    if (!product) return;
    createRevisionMutation.mutate(
      {
        instructionId: product.id,
        reviewerId: userId,
        requesteeId: currentUserId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'request-review',
            message: 'Review requested',
            color: 'green',
          });
        },
      }
    );
  };
  const handleChangeOwner = (userId: string) => {
    if (!product) return;
    changeOwnerMutation.mutate(
      {
        productId: product.id,
        newOwnerId: userId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'change-owner',
            message: 'Owner has been changed',
          });
        },
      }
    );
  };

  const handleToggleFavorite = () => {
    if (!product) return;
    setFavoriteMutation.mutate({
      productId: product.id,
      projectId,
      action: product.isFavorite ? 'remove' : 'add',
      usedBy: 'details',
    });
  };

  const openCreateSyncProfileModal = () => {
    if (!product) return;
    openModal('create-sync-profile-modal', {
      product,
    });
  };
  const openDeleteSyncProfileModal = () => {
    if (!product) return;
    openModal('delete-sync-profile-modal', {
      product,
    });
  };

  const openExportModal = () => {
    if (!product) return;
    openModal('export-modal', {
      instructionId: product.id,
      name: product.name,
    });
  };

  const openChangeOwnerModal = () => {
    openModal('create-request-review-modal', {
      title: 'Change Owner',
      action: 'change',
      onSubmitUser: handleChangeOwner,
    });
  };

  const openRequestReviewModal = () => {
    openModal('create-request-review-modal', {
      title: 'Request Review',
      action: 'request',
      onSubmitUser: handleRequestReview,
    });
  };

  const productActions: ProductAction[] = [
    {
      label: 'Rename',
      Icon: IconEdit,
      onClick: onRename,
      show: showBasedOnState(product?.state) && !isViewer,
    },
    {
      label: 'Duplicate',
      Icon: IconCopy,
      onClick: () => null,
      show: false,
    },
    {
      label: `${product?.isFavorite ? 'Remove from' : 'Add to'} Favorites`,
      Icon: product?.isFavorite ? IconStarFilled : IconStar,
      onClick: handleToggleFavorite,
    },
    {
      label: 'Change Owner',
      Icon: IconChangeUser,
      onClick: openChangeOwnerModal,
      show: !isViewer && showBasedOnState(product?.state),
    },
    {
      label: 'Create sync profile',
      Icon: IconRefresh,
      onClick: openCreateSyncProfileModal,
      show: variantsEnabled && !isViewer,
    },
    {
      label: 'Edit sync profile',
      Icon: IconTableOptions,
      onClick: handleEditSyncProfile,
      show: variantsEnabled && editableProfiles && editableProfiles.length > 0,
    },
    {
      label: 'Unsync product',
      Icon: IconUnlink,
      onClick: openDeleteSyncProfileModal,
      show: !isViewer && showUnsyncProductAction,
    },
    {
      label: 'Request Review',
      Icon: IconRequestReview,
      onClick: openRequestReviewModal,
      disabled: !product?.hasSteps,
      show: !isViewer && reviewsEnabled && showBasedOnState(product?.state),
    },
    {
      label: 'Export',
      Icon: IconDownload,
      onClick: openExportModal,
      disabled: !product?.hasSteps,
    },
    {
      label: 'Delete',
      Icon: IconTrashFilled,
      onClick: onDelete,
      show: !isViewer && showBasedOnState(product?.state),
    },
  ];

  useEffect(() => {
    setIsLoading(isRenameMutationLoading || isDeleteMutationLoading);
  }, [isRenameMutationLoading, isDeleteMutationLoading]);

  return { productActions: productActions.filter((action) => action.show !== false), isLoading };
};
