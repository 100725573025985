import { useProductExport, useSequencesForExportQuery } from '@assemblio/frontend/data-access';
import { Button, Divider, Group, Loader, Modal, ScrollArea, Stack, Text } from '@mantine/core';
import { useDocumentTitle } from '@assemblio/frontend/hooks';
import { useProjectStore } from '@assemblio/frontend/stores';
import { DocumentExportType } from '@assemblio/shared/next-types';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { SelectableSequence } from './components/SelectableSequence';
import { VideoExporterSettings } from './components/VideoExportSettings';
import { ExportOptions, VideoFormatPreset } from './types/export-modal.types';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { useHasFeatureFlag } from '../../hooks/HasFeatureFlag.hook';
import { useStepGroupSelection } from './hooks/stepGroupSelection.hook';
import { SwitchCollapse } from './components/SwitchCollapse';
import { Input } from '@assemblio/design-system';
import { CodecEnum, ResolutionEnum, VideoExportTypes } from '@assemblio/type/export';
import { notifications } from '@mantine/notifications';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { useNavigate } from 'react-router-dom';

interface ExportModalProps {
  instructionId: string;
  opened: boolean;
  onClose: () => void;
  name?: string;
}

// This needs correcting, because it makes no sense.
// Suggestion: change value to "wmp" | "recommended"?
const VIDEO_FORMAT_PRESETS: VideoFormatPreset[] = [
  {
    label: 'Recommended',
    value: { codec: CodecEnum.AVI, container: VideoExportTypes.MP4 },
  },
  {
    label: 'Windows Media Player',
    value: { codec: CodecEnum.MJPEG, container: VideoExportTypes.AVI },
  },
];

export const ExportModal = ({ instructionId, opened, onClose, name }: ExportModalProps) => {
  const { productId } = useExplorerRouteParams();
  const navigate = useNavigate();
  const [selectedExportOption, setSelectedExportOption] = useState<ExportOptions[]>([]);

  const [resolution, setResolution] = useState<ResolutionEnum>(ResolutionEnum['1920x1080']);

  const [format, setFormat] = useState<VideoFormatPreset>(VIDEO_FORMAT_PRESETS[0]);

  const exportQuery = useSequencesForExportQuery({
    instructionId,
    modalOpened: opened,
  });

  const exportMutation = useProductExport();

  const { selection, setSelection, handleStepGroupSelection, selectAll, deselectAll } = useStepGroupSelection();

  const isWordFeatureEnabled = useHasFeatureFlag(FeatureFlag.WordExport);

  const instructionName = name ?? useProjectStore.getState().name;
  useDocumentTitle('exporter', instructionName);

  const removeInvalidCharacters = (value: string): string => value.replace(/[^\w\s-_$€£*@#%&`A-Za-zÀ-ÿ]/gi, '-');
  const isInvalidCharacters = (value: string): boolean => /[^\w\s-_$€£*@#%&`A-Za-zÀ-ÿ]/i.test(value);

  const exportForm = useForm({
    initialValues: {
      name: removeInvalidCharacters(instructionName),
    },
    onValuesChange: (value) => {
      if (isInvalidCharacters(value.name)) exportForm.setFieldValue('name', removeInvalidCharacters(value.name));
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'File name is required'),
    },
  });

  const handleFormatSelection = (value: string) => {
    const presetToUse = VIDEO_FORMAT_PRESETS.find((preset) => preset.label === value);
    if (presetToUse) setFormat(presetToUse);
  };

  const toggleExportOption = (option: ExportOptions) => {
    if (selectedExportOption.includes(option)) {
      setSelectedExportOption(selectedExportOption.filter((o) => o !== option));
    } else {
      setSelectedExportOption([...selectedExportOption, option]);
    }
  };

  const handleSubmit = async (fileName: string) => {
    if (!selection) return;
    if (!exportQuery.data) return;

    exportMutation
      .mutateAsync({
        instructionId,
        fileName,
        selection: [
          {
            sequenceId: exportQuery.data[0].id,
            stepGroupIds: selection.filter((sG) => sG.selected).map((sG) => sG.stepGroupId),
          },
        ],
        exports: selectedExportOption.map((option) => {
          return {
            type:
              option === 'Video'
                ? format.value.codec === 'avi'
                  ? VideoExportTypes.AVI
                  : VideoExportTypes.MP4
                : option,
            codec: option === 'Video' ? format.value.codec : undefined,
            resolution: option === 'Video' ? resolution : undefined,
          };
        }),
      })
      .then(() =>
        notifications.show({
          message: `Started export to ${selectedExportOption.map((option) => (option === 'Video' ? 'Video' : option.toUpperCase())).join(', ')}`,
          autoClose: 3000,
        })
      )
      .catch((err) => console.error('Could not start export', err))
      .finally(() => handleClose());
  };

  const resetExportStates = () => {
    setResolution(ResolutionEnum['1920x1080']);
    setFormat(VIDEO_FORMAT_PRESETS[0]);
    setSelectedExportOption([]);
    setSelection(undefined);
    exportForm.reset();
  };

  const handleClose = () => {
    if (productId) navigate(`/explorer/product/${productId}/artefacts`, { replace: true });
    resetExportStates();
    onClose();
  };

  useEffect(() => {
    if (!opened) return;
    if (!selection && exportQuery.data) {
      setSelection(
        exportQuery.data[0].stepGroups.map((sG) => {
          return {
            stepGroupId: sG.id,
            selected: true,
          };
        })
      );
    }
  }, [exportQuery.data, selection, opened]);

  useEffect(() => {
    exportForm.setFieldValue('name', removeInvalidCharacters(instructionName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionName]);
  if (exportQuery.error) {
    return <div>An error has occurred while loading the project</div>;
  } else {
    return (
      <Modal
        data-cy="export-modal"
        onClose={handleClose}
        opened={opened}
        size={500}
        closeOnClickOutside
        centered
        title={`Export ${instructionName}`}
      >
        <form onSubmit={exportForm.onSubmit((values) => handleSubmit(values.name))}>
          <Stack pt={0} p={'lg'} gap={'lg'}>
            <Input
              data-cy="name-export-modal"
              w={'100%'}
              label="File Name"
              mb={'xs'}
              {...exportForm.getInputProps('name')}
            />

            <Stack>
              <Text size={'sm'} c={'text-secondary'}>
                Include
              </Text>
              <ScrollArea.Autosize mah={'40vh'}>
                {!selection ? (
                  <Loader />
                ) : (
                  <SelectableSequence
                    selection={selection}
                    stepGroups={exportQuery.data ? exportQuery.data[0].stepGroups : []}
                    onStepGroupSelection={handleStepGroupSelection}
                    onDeselectAll={deselectAll}
                    onSelectAll={selectAll}
                  />
                )}
              </ScrollArea.Autosize>
            </Stack>

            <Stack gap={0}>
              <Text size={'sm'} mb={'xs'} c={'text-secondary'}>
                Format
              </Text>

              <SwitchCollapse
                selected={selectedExportOption.includes('Video')}
                onSelect={() => toggleExportOption('Video')}
                label={'Video'}
              >
                <VideoExporterSettings
                  presets={VIDEO_FORMAT_PRESETS}
                  resolution={resolution}
                  format={format}
                  setResolution={setResolution}
                  onFormatSelection={handleFormatSelection}
                />
              </SwitchCollapse>
              <Divider my={'xs'} />

              <SwitchCollapse
                label={'PDF'}
                selected={selectedExportOption.includes(DocumentExportType.PDF)}
                onSelect={() => toggleExportOption(DocumentExportType.PDF)}
              />
              <Divider my={'xs'} />

              <SwitchCollapse
                label={'Word'}
                disabled={!isWordFeatureEnabled}
                selected={selectedExportOption.includes(DocumentExportType.DOCX)}
                onSelect={() => toggleExportOption(DocumentExportType.DOCX)}
              />
              <Divider my={'xs'} />
            </Stack>
          </Stack>
          <Divider />
          <Group p={'lg'} justify={'flex-end'}>
            <Button onClick={handleClose} variant={'secondary'}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'primary'}
              disabled={
                selection?.every((sG) => !sG.selected) || !exportForm.isValid || selectedExportOption.length === 0
              }
              loading={exportMutation.isLoading}
            >
              Export
            </Button>
          </Group>
        </form>
      </Modal>
    );
  }
};
