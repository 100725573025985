import { AnnotationController, useAnnotationStore, useUIStore } from '@assemblio/frontend/stores';
import { Portal } from '@mantine/core';

const TOOLBAR_OFFSET = 10;

const TOOLBAR_HEIGHT = 40;

interface Props {
  annotationId: string;
  toolbar: JSX.Element;
  toolbarPosition: 'top' | 'bottom';
  toolbarCoordinates: [number, number, number, number] | undefined;
  showToolbar: boolean;
  initialMode: 'editing' | 'display';
  children: JSX.Element;
}

export const SVGAnnotation = ({
  annotationId,
  toolbar,
  toolbarPosition,
  toolbarCoordinates,
  showToolbar,
  children,
}: Props): JSX.Element => {
  const { visible, mode, highlight } = useAnnotationStore((state) => {
    const annotationState = state.annotationMap.get(annotationId);
    return (
      annotationState || {
        visible: true,
        mode: 'editing',
        highlight: false,
      }
    );
  });
  const view = useUIStore.getState().view;
  const isAnimating = useUIStore((state) => state.isAnimating);
  const disabled = view === 'viewer' || isAnimating;

  return (
    <>
      {showToolbar && toolbarCoordinates && (
        <div
          style={{
            height: toolbarCoordinates[0],
            minWidth: toolbarCoordinates[1],
            position: 'absolute',
            left: toolbarCoordinates[2],
            //TODO: investigate (ask greg) why top is offset by ~70px
            top: (toolbarCoordinates ? toolbarCoordinates[3] : 0) - 70,
          }}
        >
          <div
            style={{
              position: 'relative',
              minWidth: toolbarCoordinates[1],
              height: `${TOOLBAR_HEIGHT}px`,
              zIndex: '5',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: toolbarPosition === 'top' ? `${TOOLBAR_HEIGHT + TOOLBAR_OFFSET}px` : undefined,
              top: toolbarPosition === 'bottom' ? `calc(100% + ${TOOLBAR_OFFSET}px)` : undefined,
            }}
          >
            {toolbar}
          </div>
        </div>
      )}
      <svg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          float: 'left',
          pointerEvents: 'none',
          filter: highlight ? 'drop-shadow(0 0 4px rgb(194,39,134))' : 'initial',
          zIndex: highlight || mode === 'editing' ? 2 : 'initial',
        }}
        viewBox={'-1 -1 2 2'}
        preserveAspectRatio={'xMidYMid meet'}
        onPointerDown={(e) => mode === 'editing' && e.stopPropagation()}
        onMouseEnter={(e) => {
          e.stopPropagation();
          AnnotationController.setHighlight(annotationId, true);
        }}
        onMouseLeave={() => {
          AnnotationController.setHighlight(annotationId, false);
        }}
      >
        {mode === 'editing' && <Portal target={'#toolbar'}>{toolbar}</Portal>}
        <g
          visibility={visible || highlight ? 'initial' : 'hidden'}
          style={{ pointerEvents: 'visiblePainted' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!disabled) {
              AnnotationController.editAnnotation(annotationId);
            }
          }}
        >
          {children}
        </g>
      </svg>
    </>
  );
};
