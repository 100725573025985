import React from 'react';
import { Text, Stack, Group, ThemeIcon } from '@mantine/core';
import classes from '../ExecuteSynchronisationModal.module.scss';
import { IconInfoHexagonFilled, IconFileFilled } from '@tabler/icons-react';
import { SyncProfileDto } from '@assemblio/shared/next-types';
import { DescriptionBox, VariantNameDisplay } from '../../../components';
import { VariantSelection } from './VariantSelection/VariantSelection';

interface SyncInstructionContentProps {
  sourceProfiles: SyncProfileDto[];
  baseVariant: SyncProfileDto;
  setBaseVariant: (variant: SyncProfileDto) => void;
  targetInstructionName: string;
}

export const SyncInstructionContent = ({
  sourceProfiles,
  targetInstructionName,
  baseVariant,
  setBaseVariant,
}: SyncInstructionContentProps) => {
  const handleSelectVariant = (id: string) => {
    const baseVariant = sourceProfiles.find((profile) => profile.id === id);
    if (!baseVariant) return;
    setBaseVariant(baseVariant);
  };

  return (
    <Stack className={classes.syncInstructionContent}>
      <Group align={'baseline'} gap={'xs'}>
        <Text>Sync</Text>
        {sourceProfiles.length > 1 ? (
          <VariantSelection
            value={baseVariant.id}
            setValue={handleSelectVariant}
            options={sourceProfiles.map((profile) => {
              return {
                value: profile.id,
                label: profile.name,
              };
            })}
          />
        ) : (
          <VariantNameDisplay name={baseVariant.name} />
        )}
        <Text>with</Text>
        <VariantNameDisplay name={targetInstructionName} />
      </Group>

      <DescriptionBox Icon={<IconInfoHexagonFilled />}>
        <Text>
          Synchronising will try to transfer all steps from{' '}
          <VariantNameDisplay name={baseVariant.name} showIcon={false} /> to{' '}
          <VariantNameDisplay name={targetInstructionName} showIcon={false} />.
        </Text>
        <Text>This action cannot be undone.</Text>
      </DescriptionBox>
    </Stack>
  );
};
