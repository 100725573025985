import { Stack, Text } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { FileDropzone } from './components/FileDropzone/FileDropzone';
import { ActiveFile } from './components/ActiveFile/ActiveFile';
import { useHasFeatureFlag } from '../../../../hooks/HasFeatureFlag.hook';

interface FileUploadProps {
  activeFile: File | null;
  onFileChange: (file: File | null) => void;
}

export const FileUpload = ({ activeFile, onFileChange }: FileUploadProps) => {
  const isJTFeatureEnabled = useHasFeatureFlag(FeatureFlag.JTFileType);

  const { acceptedFileTypes, buttonText } = getFileInputProperties(isJTFeatureEnabled);

  const handleFileDrop = (files: FileWithPath[]) => {
    onFileChange(files[0]);
  };

  const handleClearFile = () => {
    onFileChange(null);
  };

  return (
    <Stack gap={'sm'} mb={'sm'}>
      <Text variant={'medium'} size={'sm'} c={'var(--text-secondary)'}>
        Source File
      </Text>
      {activeFile === null ? (
        <FileDropzone acceptedFileTypes={acceptedFileTypes} handleFileDrop={handleFileDrop} buttonText={buttonText} />
      ) : (
        <ActiveFile fileName={activeFile.name} onClear={handleClearFile} />
      )}
    </Stack>
  );
};

const getFileInputProperties = (
  isJTFeatureEnabled: boolean
): {
  acceptedFileTypes: string[];
  buttonText: string;
} => {
  const baseFileTypes = ['.step', '.stp', '.p21'];
  if (isJTFeatureEnabled) {
    return {
      acceptedFileTypes: [...baseFileTypes, '.jt'],
      buttonText: 'Select CAD File',
    };
  } else {
    return {
      acceptedFileTypes: baseFileTypes,
      buttonText: 'Select STEP File',
    };
  }
};
