import { MachineController, UIController } from '@assemblio/frontend/stores';
import { Assembly } from '@assemblio/type/input';
import { Box, Collapse, Stack } from '@mantine/core';
import cx from 'clsx';
import { Tree } from '../types/hierarchy.types';
import { HierarchyLeaf } from './HierarchyLeaf';
import ToggableTextInput from './ToggableTextInput';
import classes from './TreeItem.module.scss';
import { TreeItemActions } from './TreeItemActions';
import useBranchSubscription from './useBranchSubscription';
import { IconGroup } from '@assemblio/design-system';
import { IconCaretDown, IconCaretRight } from '@assemblio/design-system';
import { LightWeightIcon } from './LightWeightIcon';

export const HierarchyBranch = ({ data, isLeaf, children }: Tree) => {
  const assembly = data as Assembly;
  const { selected, expanded, visible, transparent, excluded, name } = useBranchSubscription(assembly);

  const select = (assemblyGltfIndex: number) => {
    MachineController.selectTransition(assemblyGltfIndex, true);
  };

  const expand = (assemblyGltfIndex: number) => {
    if (UIController.isAssemblyExpanded(assemblyGltfIndex)) {
      UIController.collapseAssembly(assemblyGltfIndex);
    } else {
      UIController.expandAssembly(assemblyGltfIndex);
    }
  };

  const childElements = children.map((child) => {
    return child.isLeaf ? (
      <HierarchyLeaf key={'leaf-' + child.data.id} data={child.data} isLeaf={child.isLeaf} children={child.children} />
    ) : (
      <HierarchyBranch
        key={'branch-' + child.data.id}
        data={child.data}
        isLeaf={child.isLeaf}
        children={child.children}
      />
    );
  });

  return (
    <Box ml={'10px'}>
      <div
        key={`assembly-${assembly.id}`}
        className={cx(classes.hierarchy_branch, classes.itemWrapper, {
          [classes.selected]: selected,
          [classes.selected__branch]: expanded,
        })}
        data-content={name}
        onClick={() => select(assembly.gltfIndex)}
        onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          UIController.setAssemblyContext(assembly.gltfIndex);
        }}
      >
        <LightWeightIcon color={'var(--text-tertiary)'} onClick={() => expand(assembly.gltfIndex)}>
          {expanded ? <IconCaretDown /> : <IconCaretRight />}
        </LightWeightIcon>
        <LightWeightIcon color={'var(--text-primary)'}>
          <IconGroup />
        </LightWeightIcon>

        <div className={classes.mid}>
          <ToggableTextInput
            elementId={assembly.id}
            isLeaf={false}
            gltfIndex={assembly.gltfIndex}
            name={name}
            visible={visible}
            excluded={excluded}
          />
        </div>
        {!excluded && (
          <div
            className={cx(classes.rhs, {
              [classes.rhs__selected]: selected || visible,
            })}
          >
            <TreeItemActions isLeaf={false} assembly={assembly} visible={visible} transparent={transparent} />
          </div>
        )}
      </div>
      <Collapse className={classes.collapse} in={expanded}>
        <Stack gap={'0'}>{childElements}</Stack>
      </Collapse>
    </Box>
  );
};
