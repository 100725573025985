import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Menu, Tooltip } from '@mantine/core';
import { ContextMenuItem, IconRotation, IconTranslation } from '@assemblio/design-system';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import classes from './Viewport.module.scss';

export const TransformActionSwitch = () => {
  const action = useUIStore((state) => state.transformGizmo.action);
  return (
    <Menu position="bottom" width={190} offset={8}>
      <Menu.Target>
        <Tooltip offset={8} position="bottom" label="Switch between Rotation and Translation">
          <Button
            className={classes.controls__icon}
            rightSection={<IconChevronDown />}
            variant={'ghost'}
            size={'compact-sm'}
            c={'text-secondary'}
          >
            {action === 'rotate' ? <IconRotation /> : <IconTranslation />}
          </Button>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem
          onClick={() => UIController.setTransformAction('translate')}
          label={'Translation'}
          LeftIcon={IconTranslation}
          RightIcon={action === 'translate' ? IconCheck : undefined}
        />
        <ContextMenuItem
          onClick={() => UIController.setTransformAction('rotate')}
          label={'Rotation'}
          LeftIcon={IconRotation}
          RightIcon={action === 'rotate' ? IconCheck : undefined}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
