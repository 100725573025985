export const IconTranslation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85728 1.143C1.85728 0.748508 1.53749 0.428711 1.143 0.428711C0.748508 0.428711 0.428711 0.748508 0.428711 1.143V14.8573C0.428711 15.2518 0.748508 15.5716 1.143 15.5716H14.8573C15.2518 15.5716 15.5716 15.2518 15.5716 14.8573C15.5716 14.4628 15.2518 14.143 14.8573 14.143H8.96811C8.62844 10.3736 5.62667 7.37184 1.85728 7.03217V1.143ZM1.85728 9.04326V14.143H6.95702C6.63316 11.4792 4.52108 9.36712 1.85728 9.04326Z"
        fill="currentColor"
      />
    </svg>
  );
};
