import {
  SequenceController,
  StepController,
  StepGroupController,
  UIController,
  useSequenceStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { StepGroup } from '@assemblio/shared/next-types';
import type { DraggableLocation, DropResult, DroppableProvided } from '@hello-pangea/dnd';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Box } from '@mantine/core';
import { StepGroup as StepGroupComponent } from './StepGroup';
import { useMemo } from 'react';
import { useStepGroupCreate, useStepGroupMove, useStepMove } from '@assemblio/frontend/data-access';
import _ from 'lodash';
import { notifications } from '@mantine/notifications';
import { Button } from '@mantine/core';
import { IconCirclePlusFilled } from '@tabler/icons-react';

export const DroppableType = {
  StepGroup: 'STEPGROUP',
  Step: 'STEP',
};

interface StepBoardProps {
  searchTerm: string;
  scrollToBottom: () => void;
}

export const StepBoard = ({ searchTerm, scrollToBottom }: StepBoardProps) => {
  const isEditor = useUIStore((state) => state.view === 'editor');
  const isInteractable = useUIStore((state) => !state.isAnimating);
  let stepGroups = useSequenceStore((state) => state.stepGroups);

  const createStepGroupMutation = useStepGroupCreate();
  const moveStepGroupMutation = useStepGroupMove();
  const moveStepMutation = useStepMove();

  const handleAddStepGroup = () => {
    const { stepGroup, prevStepGroupId } = SequenceController.addStepGroupAtEnd();
    if (stepGroup && prevStepGroupId) {
      createStepGroupMutation.mutate(
        {
          id: stepGroup.id,
          name: stepGroup.name,
          instructionSequenceId: stepGroup.instructionSequenceId,
          previousStepGroupId: prevStepGroupId,
        },
        {
          onError: () => {
            StepGroupController.deleteStepGroup(stepGroup.id);
            UIController.selectLastStepGroup();
          },
        }
      );
    }
    UIController.selectLastStepGroup();
  };

  stepGroups = useMemo(() => {
    if (!isEditor) {
      const clone = _.cloneDeep(stepGroups);
      clone.reverse().forEach((group) => {
        group.steps = _.cloneDeep(group.steps).reverse();
      });
      return clone;
    }

    return stepGroups;
  }, [stepGroups, isEditor]);

  stepGroups = useMemo(() => {
    return getFilteredStepList(stepGroups, searchTerm);
  }, [stepGroups, searchTerm]);

  const onDragEnd = (result: DropResult): void => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source: DraggableLocation = result.source;
    const destination: DraggableLocation = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === DroppableType.StepGroup) {
      const canBeMoved = StepGroupController.canStepGroupBeMoved(source.index, destination.index);
      if (canBeMoved) {
        const { movedStepGroupId, newPrev } = StepGroupController.reorder(source.index, destination.index);

        if (movedStepGroupId && newPrev !== undefined) {
          moveStepGroupMutation.mutate({
            id: movedStepGroupId,
            data: { newPrev },
          });
          // TODO Add Error Handling
        }
      } else {
        notifications.show({
          id: 'step-group-move-forbidden',
          message:
            'Group cannot be moved to this position because dependent parts were found in the sequence. Please delete the steps with dependent parts first.',
          color: 'red',
        });
      }
    } else if (result.type === DroppableType.Step && source && destination) {
      const canBeMoved = StepController.canStepBeMoved(
        { groupId: source.droppableId, index: source.index },
        { groupId: destination.droppableId, index: destination.index }
      );
      if (canBeMoved) {
        const { movedStepId, newPrevStepId } = StepController.reorder(
          { groupId: source.droppableId, index: source.index },
          { groupId: destination.droppableId, index: destination.index }
        );

        if (movedStepId && newPrevStepId !== undefined) {
          moveStepMutation.mutate({
            id: movedStepId,
            data: {
              newPrev: newPrevStepId,
              stepGroupId: destination.droppableId,
            },
          });
          // TODO Add Error Handling
        }
      } else {
        notifications.show({
          id: 'step-move-forbidden',
          message:
            'Step cannot be moved to this position because dependent parts were found in the sequence. Please delete the steps with dependent parts first.',
          color: 'red',
        });
      }
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <DragDropContext enableDefaultSensors={isEditor} onDragEnd={onDragEnd}>
        <Droppable droppableId="board" type={DroppableType.StepGroup} direction="vertical">
          {(provided: DroppableProvided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {stepGroups.map((stepGroup: StepGroup, index: number) => (
                <StepGroupComponent
                  key={stepGroup.id}
                  index={index}
                  stepGroup={stepGroup}
                  stepGroupsLength={stepGroups.length}
                />
              ))}
              {provided.placeholder}
              {isEditor && (
                <Box py={'sm'} px={'sm'}>
                  <Button
                    variant={'secondary'}
                    leftSection={<IconCirclePlusFilled size={16} color={'white'} />}
                    justify={'center'}
                    onClick={() => handleAddStepGroup()}
                    fullWidth
                    disabled={!isInteractable}
                  >
                    Add group
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const getFilteredStepList = (stepGroups: StepGroup[], searchTerm: string): StepGroup[] => {
  if (searchTerm.length === 0) return stepGroups;
  const trimmedSearchTerm = searchTerm.trim().toLowerCase();
  return stepGroups.map((stepGroup) => ({
    ...stepGroup,
    steps: stepGroup.steps.filter((step) => step.name.toLowerCase().includes(trimmedSearchTerm)),
  }));
};
