import { Group, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';
import classes from './ProductInformationTable.module.scss';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { getFormattedDateString } from '../../../../../../../../util';

interface ProductInformationTableProps {
  product: ProductContentDto | undefined;
  isLoading?: boolean;
}

const INFORMATION_ITEM_COUNT = 6;

export const ProductInformationTable = ({ product, isLoading = false }: ProductInformationTableProps) => {
  return (
    <Stack h={'100%'} p={'xl'} className={classes.table__wrapper} align={'start'} w={'30%'} gap={'xs'}>
      <Text fw={'bold'} mb={'xl'}>
        Properties
      </Text>
      <Group wrap={'nowrap'} gap={'xl'}>
        {!product || isLoading ? (
          <InformationTableSkeleton />
        ) : (
          <SimpleGrid cols={2} spacing={'xs'} verticalSpacing={'xl'}>
            <ProductInformationRow title={'Folder'} content={product.folder.name} />
            <ProductInformationRow title={'Project'} content={product.project.name} />
            <ProductInformationRow
              title={'Last edited'}
              content={getFormattedDateString(product.lastEditedAt ?? new Date())}
            />
            <ProductInformationRow
              title={product.sourceFile.type.toUpperCase() + ' file'}
              content={product.sourceFile.name ?? '-'}
            />
            <ProductInformationRow title={'Created by'} content={product.createdBy.fullName} />
            <ProductInformationRow title={'Owned by'} content={product.ownedBy.fullName} />
          </SimpleGrid>
        )}
      </Group>
    </Stack>
  );
};

const ProductInformationRow = ({ title, content }: { title: string; content: string }) => {
  return (
    <>
      <Text mb={'md'} mr={'lg'} c={'text-secondary'} size={'sm'} lineClamp={1}>
        {title}
      </Text>
      <Text mb={'md'} variant={'medium'} size={'sm'} lineClamp={1}>
        {content}
      </Text>
    </>
  );
};

const InformationTableSkeleton = () => {
  return (
    <>
      <Stack gap={'lg'} w={'30%'} maw={'100px'}>
        {Array.from({ length: INFORMATION_ITEM_COUNT }).map((_, index) => (
          <Skeleton key={`informationSkeleton-${index}`} h={'2rem'} w={'100px'} />
        ))}
      </Stack>
      <Stack gap={'lg'}>
        {Array.from({ length: INFORMATION_ITEM_COUNT }).map((_, index) => (
          <Skeleton key={`informationSkeleton-${index}`} h={'2rem'} w={'150px'} />
        ))}
      </Stack>
    </>
  );
};
