import { TextInput, TextInputProps } from '@mantine/core';
import classes from './Input.module.scss';

export const Input = ({ ...props }: TextInputProps) => {
  return (
    <TextInput
      {...props}
      classNames={{
        label: classes.label,
        input: classes.input,
      }}
    />
  );
};
