import { useContext, useEffect } from 'react';
import { ExplorerTitleContext } from '../providers/ExplorerTitleProvider';
import { TitleExtension } from '../types';

export const useExplorerTitle = (newTitle: string | undefined, extension?: TitleExtension) => {
  const context = useContext(ExplorerTitleContext);
  useEffect(() => {
    context.setHeaderTitle(newTitle, extension);
  }, [newTitle, extension]);
};
