export const IconRequestReview = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_447_128305)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.69697 1.55409C2.77734 1.47373 2.88635 1.42857 3.00001 1.42857H8.00001C8.3945 1.42857 8.7143 1.10877 8.7143 0.714286C8.7143 0.319797 8.3945 0 8.00001 0H3.00001C2.50747 0 2.0351 0.195663 1.68681 0.543944C1.33854 0.892226 1.14287 1.36459 1.14287 1.85714V11.7413L0.0366696 15.0599C-0.0457713 15.3072 0.01308 15.5798 0.190214 15.7711C0.367346 15.9623 0.634623 16.0419 0.887541 15.9786L5.37367 14.8571H14.1429C14.6354 14.8571 15.1078 14.6615 15.4561 14.3131C15.8043 13.9649 16 13.4926 16 13V8C16 7.60551 15.6802 7.28571 15.2857 7.28571C14.8912 7.28571 14.5714 7.60551 14.5714 8V13C14.5714 13.1137 14.5263 13.2226 14.4459 13.3031C14.3656 13.3834 14.2566 13.4286 14.1429 13.4286H5.28573C5.22733 13.4286 5.16914 13.4358 5.11249 13.4499L1.80341 14.2771L2.53479 12.083C2.55906 12.0102 2.57144 11.9339 2.57144 11.8571V1.85714C2.57144 1.74347 2.61659 1.63447 2.69697 1.55409ZM12.2586 0.281534C12.4675 0.194502 12.6917 0.149693 12.9179 0.149693C13.1442 0.149693 13.3682 0.194502 13.5771 0.281534C13.7857 0.368402 13.975 0.495619 14.1342 0.65587L14.1351 0.656776L15.3432 1.86489L15.3442 1.86594C15.5045 2.0251 15.6315 2.21432 15.7184 2.42277C15.8055 2.63166 15.8503 2.85573 15.8503 3.08203C15.8503 3.30834 15.8055 3.53241 15.7184 3.7413C15.6317 3.9496 15.5046 4.13871 15.3446 4.29778L15.3432 4.29918L9.96282 9.70238C9.88029 9.78528 9.77427 9.84085 9.65914 9.86157L6.23057 10.4787C6.04787 10.5116 5.86058 10.4537 5.72833 10.3234C5.59609 10.1931 5.53536 10.0068 5.56549 9.82358L6.13691 6.34929C6.15638 6.23096 6.21258 6.12174 6.29757 6.03712L11.7022 0.655375C11.8613 0.495357 12.0504 0.368312 12.2586 0.281534Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_447_128305">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
