import { FilterOption } from '../../../../../../../types';
import { IconFileFilled } from '@tabler/icons-react';
import { ArtefactType } from '@assemblio/type/artefacts';

interface Return {
  filterProps: FilterOption[];
}

const FILTERABLE_ARTEFACT_TYPES: ArtefactType[] = [ArtefactType.DOCX, ArtefactType.PDF, ArtefactType.VIDEO];

export const useArtefactQueryFilters = (): Return => {
  return {
    filterProps: [
      {
        label: 'Type',
        value: 'type',
        Icon: IconFileFilled,
        options: Object.entries(FILTERABLE_ARTEFACT_TYPES).map(([_, value]) => {
          return {
            label: value.toUpperCase(),
            value: value,
          };
        }),
        onToggleOption: () => null,
      },
    ],
  };
};
