import { ProductArtefactSortProps } from '@assemblio/type/artefacts';
import { SortingOption } from '../../../../../../../types';

export const ARTEFACT_QUERY_SORTING_OPTIONS: SortingOption<ProductArtefactSortProps>[] = [
  {
    value: 'name-desc',
    queryValue: ProductArtefactSortProps.originalName,
    label: 'Name (A to Z)',
    ascending: true,
  },
  {
    value: 'name-asc',
    queryValue: ProductArtefactSortProps.originalName,
    label: 'Name (Z to A)',
    ascending: false,
  },
  {
    value: 'createdAt',
    queryValue: ProductArtefactSortProps.createdAt,
    label: 'Oldest created',
    ascending: false,
  },
  {
    value: 'size-asc',
    queryValue: ProductArtefactSortProps.size,
    label: 'Size (ascending)',
    ascending: true,
  },
  {
    value: 'size-desc',
    queryValue: ProductArtefactSortProps.size,
    label: 'Size (descending)',
    ascending: false,
  },
];
