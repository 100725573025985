import { ActionIcon, Collapse, Group, Text } from '@mantine/core';
import { IconCaretDown, IconCaretRight } from '@assemblio/design-system';
import React from 'react';
import classes from '../Styles/Step.module.scss';
import cx from 'clsx';

interface CollapsibleSectionProps {
  title: string;
  titleColor?: string;
  collapsed: boolean;
  selected: boolean;
  setCollapsed: () => void;
  children?: React.ReactNode;
  withTopBorder?: boolean;
}

export const CollapsibleSection = ({
  title,
  titleColor,
  collapsed,
  selected,
  setCollapsed,
  children,
  withTopBorder = true,
}: CollapsibleSectionProps) => {
  return (
    <>
      <Group
        className={cx({
          [classes.collapsibleSection__border]: withTopBorder,
        })}
        align={'center'}
        px={'lg'}
        py={'sm'}
        gap={5}
      >
        <ActionIcon
          data-cy="collapsible-section"
          size="sm"
          color="dimmed"
          variant="transparent"
          c={'text-tertiary'}
          onClick={() => setCollapsed()}
        >
          {collapsed ? <IconCaretDown /> : <IconCaretRight />}
        </ActionIcon>
        <Text size={'sm'} variant={'medium'} data-selected={selected}>
          {title}
        </Text>
      </Group>
      <Collapse in={collapsed}>{children}</Collapse>
    </>
  );
};
