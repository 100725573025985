import { ActionIcon, Group, Menu, Tooltip, useMantineTheme } from '@mantine/core';
import { IconCircleArrowRightFilled, IconTrash, IconTrashFilled } from '@tabler/icons-react';
import classes from './ItemContextMenu.module.scss';
import { ExplorerItem } from '../../types/items.types';
import { useDeleteItem } from './hooks/deleteItem.hook';
import { useEffect } from 'react';
import { useModalContext } from '@assemblio/frontend/components';
import { useRenameItem } from './hooks/renameItem.hook';
import { ContextMenuButton, ContextMenuItem, IconRenameFilled } from '@assemblio/design-system';

interface ItemContextMenuProps {
  item: ExplorerItem;
  onListItem?: boolean;
  disabled?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ItemContextMenu({ item, onListItem = false, disabled = false, setLoading }: ItemContextMenuProps) {
  const hasItems = (item.subItemCount ?? 0) > 0;
  const { openModal } = useModalContext();

  //TODO: implement hook for moving items
  const { deleteItem, isLoading } = useDeleteItem();
  const { renameItem, isLoading: isRenameLoading } = useRenameItem();

  const openRenameModal = () => {
    openModal('rename-explorer-item', { name: item.name, onRename: submitRenameItem });
  };

  const openMoveProjectModal = () => {
    openModal('move-explorer-item', { item: item, itemType: item.type });
  };

  const submitRenameItem = (newName: string) => {
    renameItem(newName, item.id, item.type);
  };

  const theme = useMantineTheme();

  useEffect(() => {
    setLoading(isLoading || isRenameLoading);
  }, [isLoading, isRenameLoading]);

  return onListItem ? (
    <Group>
      <Tooltip openDelay={500} label={hasItems ? 'Only empty folders can be deleted' : 'Delete Folder'}>
        <ActionIcon
          variant={'filled'}
          disabled={hasItems}
          onClick={() => deleteItem(item)}
          color={theme.other.color.brand.pink}
        >
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    </Group>
  ) : (
    <Menu
      disabled={disabled}
      position="bottom-start"
      withinPortal
      trapFocus={false}
      classNames={{
        itemLabel: classes.menuButton__itemLabel,
      }}
      trigger={'hover'}
    >
      <Menu.Target>
        <ContextMenuButton />
      </Menu.Target>

      <Menu.Dropdown data-cy="menu-dropdown">
        <ContextMenuItem LeftIcon={IconRenameFilled} label={'Rename'} onClick={openRenameModal} />

        <ContextMenuItem
          disabled={hasItems}
          tooltipLabel={hasItems ? `Only empty ${item.type}s can be deleted` : undefined}
          LeftIcon={IconTrashFilled}
          label={'Delete'}
          onClick={() => deleteItem(item)}
        />
        {item.type === 'Project' && (
          <ContextMenuItem LeftIcon={IconCircleArrowRightFilled} label={'Move to'} onClick={openMoveProjectModal} />
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
