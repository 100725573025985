import React, { useState } from 'react';
import { Menu, ActionIcon } from '@mantine/core';
import { IconHelpHexagonFilled } from '@tabler/icons-react';
import { ContextMenuItem } from '@assemblio/design-system';
import { ShortcutDrawer } from './components/ShortcutDrawer/ShortcutDrawer';

export const HelpMenu = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleShortcutsClick = () => {
    setDrawerOpened(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpened(false);
  };

  return (
    <>
      <Menu width={232} position="bottom-start">
        <Menu.Target>
          <ActionIcon variant="ghost">
            <IconHelpHexagonFilled size={16} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown px={'xs'} py={'sm'}>
          <ContextMenuItem label="Shortcuts" onClick={handleShortcutsClick} />
        </Menu.Dropdown>
      </Menu>

      <ShortcutDrawer opened={drawerOpened} onClose={handleCloseDrawer} />
    </>
  );
};
