import { BrandingOptionsDTO } from '@assemblio/shared/dtos';
import { Box, Table, Text } from '@mantine/core';
import classes from '../PdfPreview.module.scss';

interface InstructionInformationTableProps {
  scalingFactor: number;
  headerInfo: BrandingOptionsDTO | null;
  color: string;
}

export const InstructionInformationTable = ({ scalingFactor, headerInfo, color }: InstructionInformationTableProps) => {
  return (
    <Box
      style={{
        position: 'absolute',
        width: '200px',
        left: '45.5%',
        top: '8.4%',
        transform: `scale(${scalingFactor})`,
      }}
    >
      <Table
        classNames={{
          tbody: classes.pdf_preview__table__body,
        }}
        withRowBorders={false}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            {headerInfo?.showDate && (
              <>
                <Table.Td>
                  <Text c={color}>Date:</Text>
                </Table.Td>
                <Table.Td>
                  <Text c={color}>01.01.2000</Text>
                </Table.Td>
              </>
            )}
          </Table.Tr>
          <Table.Tr>
            {headerInfo?.showContributor && (
              <>
                <Table.Td>
                  <Text c={color}>Contributor:</Text>
                </Table.Td>
                <Table.Td>
                  <Text c={color}>Editor @ Your Company</Text>
                </Table.Td>
              </>
            )}
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Table
        classNames={{
          tbody: classes.pdf_preview__table__body,
        }}
        mt={'3%'}
        withRowBorders={false}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th p={0} />
            <Table.Th p={0} />
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            {headerInfo?.showPartCount && (
              <>
                <Table.Td>
                  <Text c={color}>No. parts:</Text>
                </Table.Td>
                <Table.Td>
                  <Text c={color}>420</Text>
                </Table.Td>
              </>
            )}
          </Table.Tr>
          <Table.Tr>
            {headerInfo?.showStepCount && (
              <>
                <Table.Td>
                  <Text c={color}>No. steps:</Text>
                </Table.Td>
                <Table.Td>
                  <Text c={color}>42</Text>
                </Table.Td>
              </>
            )}
          </Table.Tr>
          <Table.Tr>
            {headerInfo?.showVersion && (
              <>
                <Table.Td pt={'3%'}>
                  <Text c={color}>Version</Text>
                </Table.Td>
                <Table.Td pt={'3%'}>
                  <Text c={color}>1.0</Text>
                </Table.Td>
              </>
            )}
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Box>
  );
};
