import React, { useState } from 'react';
import { Avatar, Box, Button, Card, Group, LoadingOverlay, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import { ProductCardBreadcrumbs } from './components/ProductCardBreadcrumbs';
import { ProductImage } from './components/ProductImage';
import classes from './ProductCard.module.scss';
import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { ProductContextMenu } from './components/ProductContextMenu/ProductContextMenu';
import { FeatureFlag } from '@assemblio/type/feature-flag';
import { HoverButton } from './components/Button/HoverButton';
import { ProductSyncIcons } from './components/ProductSyncIcons/ProductSyncIcons';
import { getFormattedDateString, getFormattedTimeDifference } from '../../util';
import { useHasFeatureFlag } from '../../hooks/HasFeatureFlag.hook';
import { useUIStore } from '@assemblio/frontend/stores';

export interface ProductCardProps {
  item: ProductOverviewDto;
  path?: string[];
  isLoading?: boolean;
  onOpenProduct: () => void;
  onOpenDetails: () => void;
  hasVariantsFeatureValue?: boolean;
}

export const ProductCard = ({
  item,
  isLoading,
  onOpenProduct,
  onOpenDetails,
  hasVariantsFeatureValue = false,
}: ProductCardProps) => {
  const isViewer = useUIStore.getState().view === 'viewer';

  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { id, name, state, owner, updatedAt, sync } = item;
  const hasVariantsFeature = useHasFeatureFlag(FeatureFlag.Variants);

  return (
    <Card
      key={id}
      className={classes.card}
      h={'20rem'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <LoadingOverlay visible={isLoading || isMutationLoading} />

      <Stack h={'100%'}>
        <Box className={classes.imageContainer}>
          <ProductImage productId={id} state={state} />
          {isHovered && <HoverButton onClick={onOpenProduct} />}
        </Box>

        <Stack px={'md'} gap={'sm'}>
          <Group wrap={'nowrap'} gap={0} align={'flex-start'} justify={'space-between'}>
            <Text fw={'bold'} lineClamp={2}>
              {name}
            </Text>
            {!isViewer && (
              <Group wrap={'nowrap'} gap={'xs'}>
                {!!sync && (
                  <ProductSyncIcons
                    syncOverview={sync}
                    hasVariantsFeature={hasVariantsFeature || hasVariantsFeatureValue}
                  />
                )}
                <ProductContextMenu product={item} setLoading={setIsMutationLoading} />
              </Group>
            )}
          </Group>
          <ProductCardBreadcrumbs path={[item.folder.name, item.project.name]} />
        </Stack>
        <Group px={'md'} justify={'space-between'}>
          <Group>
            <Avatar name={owner.fullName} color={'initials'} />
            <Tooltip label={`Edited at ${getFormattedDateString(updatedAt)}`}>
              <Text c={'text-tertiary'}>Edited {getFormattedTimeDifference(updatedAt)}</Text>
            </Tooltip>
          </Group>
          <Button variant={'secondary'} onClick={onOpenDetails}>
            Details
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export const ProductCardSkeleton = () => {
  return (
    <Card className={classes.card} h={'20rem'}>
      <Stack h={'100%'}>
        <Skeleton h={'2rem'} w={'90%'} />
        <Skeleton h={'10rem'} w={'90%'} />
        <Skeleton h={'5rem'} w={'90%'} />
      </Stack>
    </Card>
  );
};
