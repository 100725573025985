export enum InstructionImageSize {
  THUMBNAIL = 'thumbnail',
  THUMBNAIL_VERTICAL = 'thumbnail-vertical',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum InstructionImageColor {
  DARK = 'dark',
  LIGHT = 'light',
}
