import { Button, Divider, Group, Modal, Text } from '@mantine/core';
import React from 'react';

interface ConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  label: string;
  buttonLabel?: string;
  message: string;
  onConfirm: () => void;
}

export const ConfirmationModal = ({
  opened,
  onConfirm,
  onClose,
  message,
  label,
  buttonLabel = 'Confirm',
}: ConfirmationModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal title={label} withCloseButton={false} centered opened={opened} onClose={onClose}>
      <Text px={'lg'} c={'text-primary'} size={'sm'}>
        {message}
      </Text>

      <Divider my={'lg'} />

      <Group justify={'flex-end'} mb={'lg'} px={'lg'}>
        <Button variant={'primary'} onClick={handleConfirm}>
          {buttonLabel}
        </Button>
        <Button onClick={onClose} variant={'secondary'}>
          Cancel
        </Button>
      </Group>
    </Modal>
  );
};
