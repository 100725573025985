import { useLocalStorage } from '@mantine/hooks';

export const useFolderDisplayMode = () => {
  const [view, setView] = useLocalStorage<'separate' | 'mixed'>({
    key: 'assemblio-folder-display-mode',
    defaultValue: 'mixed',
  });

  return {
    view,
    setView,
  };
};
