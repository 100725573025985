export const IconProject = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14307 0C1.03849 0 0.143066 0.895431 0.143066 2V13.2857C0.143066 14.7848 1.35829 16 2.85735 16H13.0002C13.4736 16 13.8574 15.6162 13.8574 15.1429C13.8574 14.6695 13.4736 14.2857 13.0002 14.2857H12.4288V12.2621C13.2395 12.1259 13.8574 11.4209 13.8574 10.5714V1.71429C13.8574 0.767512 13.0898 0 12.1431 0H2.14307ZM10.7145 14.2857H2.85735C2.30507 14.2857 1.85735 13.8379 1.85735 13.2857C1.85735 12.7335 2.30507 12.2857 2.85735 12.2857H10.7145V14.2857ZM5.00021 1.71429C5.00021 1.55649 5.12813 1.42857 5.28592 1.42857H9.85735C10.0151 1.42857 10.1431 1.55649 10.1431 1.71429V7.71429C10.1431 7.82792 10.0757 7.93075 9.97156 7.97618C9.8674 8.0216 9.74621 8.00098 9.66294 7.92366L7.57164 5.98174L5.48034 7.92366C5.39707 8.00098 5.27589 8.0216 5.17172 7.97618C5.06756 7.93075 5.00021 7.82792 5.00021 7.71429V1.71429Z"
        fill="currentColor"
      />
    </svg>
  );
};
