import { ScrollArea, Table, Text, Tooltip, Checkbox } from '@mantine/core';
import produce from 'immer';
import { useMemo, useState } from 'react';
import classes from './SyncProfilePairEditTable.module.scss';
import { SyncProfilePair, SyncProfilePairs } from '@assemblio/shared/next-types';

export interface UpdatedSyncPair {
  pair: SyncProfilePair;
  state: 'Keep' | 'Replace';
}

interface SyncProfilePairEditTableProps {
  pairs: UpdatedSyncPair[] | undefined;
  onChange: React.Dispatch<React.SetStateAction<UpdatedSyncPair[] | undefined>>;
  searchString?: string;
}

export const SyncProfilePairEditTable = ({ pairs, onChange, searchString }: SyncProfilePairEditTableProps) => {
  const [replaceAll, setReplaceAll] = useState(false);

  const movePair = (index: number, newState: UpdatedSyncPair['state']) => {
    onChange(
      produce<UpdatedSyncPair[]>((state) => {
        state[index].state = newState;
        return state;
      })
    );
  };

  const handleReplaceAll = (checked: boolean) => {
    setReplaceAll(checked);
    const newPairs: UpdatedSyncPair[] | undefined = pairs?.map((pair) => ({
      ...pair,
      state: checked ? 'Replace' : 'Keep',
    }));
    onChange(newPairs);
  };

  const filteredPairs = useMemo(
    () =>
      searchString
        ? pairs?.filter(
            (pair) =>
              pair.pair.source.name.toLowerCase().includes(searchString.toLowerCase()) ||
              pair.pair.target.name.toLowerCase().includes(searchString.toLowerCase())
          )
        : pairs,
    [searchString, pairs]
  );

  const rows = useMemo(
    () =>
      filteredPairs?.map((updatedPair, index) => {
        const { pair, state } = updatedPair;
        return (
          <Table.Tr key={`pair-${pair.source.id}`}>
            <Table.Td w={'1rem'}>
              <Checkbox
                checked={state === 'Replace'}
                onChange={(event) => movePair(index, event.currentTarget.checked ? 'Replace' : 'Keep')}
              />
            </Table.Td>
            <Table.Td>
              <Tooltip openDelay={250} label={pair.source.name}>
                <Text lineClamp={1}>{pair.source.name}</Text>
              </Tooltip>
            </Table.Td>
            <Table.Td>
              <Tooltip openDelay={250} label={pair.target.name}>
                <Text lineClamp={1}>{pair.target.name}</Text>
              </Tooltip>
            </Table.Td>
          </Table.Tr>
        );
      }),
    [filteredPairs]
  );

  return (
    <ScrollArea.Autosize
      mah={'450px'}
      w={'100%'}
      classNames={{
        scrollbar: classes.table_scrollbar,
      }}
      type={'always'}
      scrollbars={'y'}
    >
      <Table
        classNames={{
          table: classes.table,
          thead: classes.table__head,
          td: classes.table__column,
        }}
        stickyHeader
        stickyHeaderOffset={0}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th w={'1rem'}>
              <Checkbox checked={replaceAll} onChange={(event) => handleReplaceAll(event.currentTarget.checked)} />
            </Table.Th>
            <Table.Th>
              <Text c={'text-secondary'}>Source part</Text>
            </Table.Th>
            <Table.Th>
              <Text c={'text-secondary'}>Target part</Text>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea.Autosize>
  );
};

export const transformPairs = (pairs: SyncProfilePairs | undefined): UpdatedSyncPair[] | undefined => {
  if (!pairs) return undefined;
  const toKeep: UpdatedSyncPair[] = pairs.resolved.keep.map((pair) => {
    return { pair, state: 'Keep' };
  });
  const toReplace: UpdatedSyncPair[] = pairs.resolved.replace.map((pair) => {
    return { pair, state: 'Replace' };
  });
  const unresolved: UpdatedSyncPair[] = pairs.unresolved.map((pair) => {
    return { pair, state: 'Replace' };
  });

  return [...toKeep, ...toReplace, ...unresolved];
};
