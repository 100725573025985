export const IconEdit = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_197_102836)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5426 0.502103C11.8732 -0.167368 10.7877 -0.167367 10.1183 0.502103L1.31023 9.31023C1.23047 9.39 1.17609 9.4916 1.15396 9.60222L0.011106 15.3165C-0.0263637 15.5039 0.0322777 15.6975 0.167377 15.8327C0.302476 15.9678 0.496156 16.0264 0.683504 15.9889L6.39779 14.8461C6.50841 14.8239 6.61001 14.7696 6.68979 14.6898L15.4978 5.88166C16.1673 5.21218 16.1673 4.12675 15.4978 3.45729L12.5426 0.502103Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_197_102836">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
