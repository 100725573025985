import { useEffect, useState } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';

interface JobUpdateProps {
  jobId: string;
  onJobCompletion: () => void;
}
export const useJobUpdates = ({ jobId, onJobCompletion }: JobUpdateProps) => {
  const socket = useEventStore((state) => state.socket);

  const [progress, setProgress] = useState<number | undefined>(undefined);

  const handleJobCompletion = () => {
    onJobCompletion();
  };

  useEffect(() => {
    socket.on('product-job-update', (event) => {
      if (event.jobId !== jobId) return;
      if (typeof event.progress === 'undefined') {
        handleJobCompletion();
      } else {
        setProgress(event.progress * 100);
      }
    });
    return () => {
      socket.off('product-job-update');
    };
  }, []);

  return [progress];
};
