import { Button, Divider, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useBrandingFileQuery, useBrandingQuery, useUpdateBrandingMutation } from '@assemblio/frontend/data-access';
import {
  BrandingFileType,
  DEFAULT_FOOTER_COLOR,
  DEFAULT_HEADER_COLOR,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TITLE_COLOR,
} from '@assemblio/type/branding';
import { BrandingOptionsDTO } from '@assemblio/shared/dtos';
import { notifications } from '@mantine/notifications';
import produce from 'immer';
import { BrandingColors, BrandingColorsType } from '../BrandingColors';
import { PdfPreview } from '../PdfPreview/PdfPreview';
import { HeaderTableContent } from '../HeaderTableContent/HeaderTableContent';
import { LogoDisplay } from '../LogoDisplay/LogoDisplay';
export const LOGO_PLACEHOLDER_PATH = 'assets/editor/Logo.png';

const DefaultHeaderInfo: BrandingOptionsDTO = {
  showDate: true,
  showContributor: true,
  showPartCount: true,
  showStepCount: true,
  showVersion: true,
};

export const LayoutSettings = () => {
  const theme = useMantineTheme();
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.xl})`);

  const [headerInfo, setHeaderInfo] = useState<BrandingOptionsDTO | null>(null);

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { data: brandingData, isLoading: brandingIsLoading } = useBrandingQuery();

  const {
    data: imageData,
    isLoading: logoQueryIsLoading,
    isError: logoQueryIsError,
  } = useBrandingFileQuery(BrandingFileType.logo);

  const updateBrandingMutation = useUpdateBrandingMutation();

  const [brandingColors, setBrandingColors] = useState<BrandingColorsType>({
    headerColor: DEFAULT_HEADER_COLOR,
    footerColor: DEFAULT_FOOTER_COLOR,
    titleColor: DEFAULT_TITLE_COLOR,
    textColor: DEFAULT_TEXT_COLOR,
  });

  const handleSaveBrandingOptions = () => {
    if (!headerInfo) return;
    updateBrandingMutation.mutate(
      {
        ...brandingColors,
        options: headerInfo,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'save-branding-options-success',
            message: 'Updated Layout Definition',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'save-branding-options-error',
            message: 'Failed to update Layout Definition',
            color: 'red',
          });
        },
      }
    );
  };

  useEffect(() => {
    if (imageData) {
      if (imageUrl) URL.revokeObjectURL(imageUrl);
      setImageUrl(createUrlFromBlob(imageData));
    }
    if (logoQueryIsError || imageData === null) {
      setImageUrl(LOGO_PLACEHOLDER_PATH);
    }
  }, [imageData, logoQueryIsError]);

  useEffect(() => {
    if (brandingData) {
      setBrandingColors(
        produce<BrandingColorsType>((state) => {
          // Iterate over the keys of the BrandingColors
          // and set the value to the value from the query.
          // If no value is available, set it to the default value.
          Object.keys(state).forEach((key) => {
            const color = key as keyof BrandingColorsType;
            state[color] = brandingData[color] ?? state[color];
          });
        })
      );
    }
    if (brandingData?.options) {
      setHeaderInfo(brandingData.options);
    }
    if (!brandingIsLoading && !brandingData) {
      //Set Info to default when query is done and no data is available
      setHeaderInfo(DefaultHeaderInfo);
    }
  }, [brandingData, brandingIsLoading]);
  return (
    <Stack>
      <Group gap={isSmall ? '5%' : '100px'} align={'flex-start'}>
        <Stack gap={'xs'}>
          <Text variant={'medium'} mb={'xs'}>
            Export Layout
          </Text>

          <Stack w={'100%'} gap={'md'}>
            <LogoDisplay isLoading={logoQueryIsLoading} imageUrl={imageUrl} />

            <Divider />

            {/* Accent Color Input*/}
            <BrandingColors
              isLoading={brandingIsLoading}
              brandingColors={brandingColors}
              setBrandingColors={setBrandingColors}
            />
            <Divider />

            {/* Header Content */}
            <HeaderTableContent isLoading={brandingIsLoading} setHeaderInfo={setHeaderInfo} options={headerInfo} />
            <Divider />
            <Button
              disabled={updateBrandingMutation.isLoading}
              variant={'primary'}
              onClick={() => {
                handleSaveBrandingOptions();
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
        <PdfPreview
          isLoading={brandingIsLoading || logoQueryIsLoading}
          imgSource={imageUrl ?? LOGO_PLACEHOLDER_PATH}
          colors={brandingColors}
          headerInfo={headerInfo}
        />
      </Group>
    </Stack>
  );
};

const createUrlFromBlob = (blob: Blob): string | null => {
  try {
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error creating the URL:', error);
    return null;
  }
};
