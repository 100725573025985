export const IconUsers = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71429 6.85711C7.29224 6.85711 8.57143 5.57792 8.57143 3.99997C8.57143 2.42201 7.29224 1.14282 5.71429 1.14282C4.13633 1.14282 2.85714 2.42201 2.85714 3.99997C2.85714 5.57792 4.13633 6.85711 5.71429 6.85711ZM5.71429 7.99997C2.55838 7.99997 0 10.5583 0 13.7143V14.2857C0 14.6012 0.255838 14.8571 0.571429 14.8571H10.8571C11.1727 14.8571 11.4286 14.6012 11.4286 14.2857V13.7143C11.4286 10.5583 8.87019 7.99997 5.71429 7.99997ZM15.4286 14.8571H12.7743C12.8281 14.6761 12.8571 14.4842 12.8571 14.2857V13.7143C12.8571 11.3816 11.739 9.31011 10.0094 8.00653C10.1009 8.00217 10.193 7.99997 10.2857 7.99997C13.4416 7.99997 16 10.5583 16 13.7143V14.2857C16 14.6012 15.7441 14.8571 15.4286 14.8571ZM10.2857 6.85711C9.86885 6.85711 9.47286 6.76785 9.11579 6.60739C9.67033 5.88505 9.99995 4.98101 9.99995 3.99997C9.99995 3.01893 9.67033 2.11488 9.11579 1.39254C9.47286 1.23208 9.86885 1.14282 10.2857 1.14282C11.8637 1.14282 13.1429 2.42201 13.1429 3.99997C13.1429 5.57792 11.8637 6.85711 10.2857 6.85711Z"
        fill="currentColor"
      />
    </svg>
  );
};
