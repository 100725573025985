import { Menu } from '@mantine/core';
import { ContextMenuButton, ContextMenuItem } from '@assemblio/design-system';
import { IconRefresh, IconTrashFilled } from '@tabler/icons-react';
import { useDeleteJob, useRetryJob } from '@assemblio/frontend/data-access';
import { useModalContext } from '@assemblio/frontend/components';

interface JobContextMenu {
  jobId: string;
  productId: string;
}

export const JobContextMenu = ({ jobId, productId }: JobContextMenu) => {
  const { openModal } = useModalContext();

  const deleteJobMutation = useDeleteJob();
  const retryJobMutation = useRetryJob();

  const handleRetryJob = () => {
    openModal('confirmation-modal', {
      onConfirm: () => retryJobMutation.mutate({ instructionId: productId, jobId: jobId }),
      label: `Retry Job`,
      message: `Are you sure you want to restart this job?`,
      buttonLabel: 'Retry',
    });
  };

  const handleDeleteJob = () => {
    openModal('confirmation-modal', {
      onConfirm: () => deleteJobMutation.mutate({ instructionId: productId, jobId: jobId }),
      label: `Delete Job`,
      message: `Are you sure you want to delete this job?`,
      buttonLabel: 'Delete',
    });
  };

  return (
    <Menu trigger={'hover'}>
      <Menu.Target>
        <ContextMenuButton />
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem label={'Retry'} onClick={handleRetryJob} LeftIcon={IconRefresh} />
        <ContextMenuItem label={'Delete'} onClick={handleDeleteJob} LeftIcon={IconTrashFilled} />
      </Menu.Dropdown>
    </Menu>
  );
};
