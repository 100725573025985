import {
  ConfirmationModal,
  UserSettingsOverlay,
  CreateExplorerItemModal,
  MoveExplorerItemModal,
  RenameExplorerItemModal,
  ChangeRequestReviewModal,
  CreateProductModal,
  AddUsersModal,
  ExecuteSynchronisationModal,
  ExportModal,
  CreateSyncProfileModal,
  DeleteSyncProfileModal,
  ResolveSyncIssuesModal,
  EditSyncProfileModal,
  AdminSettingsModal,
  OwnershipErrorModal,
} from '@assemblio/frontend/explorer';

export const AvailableModals = {
  'create-explorer-item': CreateExplorerItemModal,
  'user-settings-overlay': UserSettingsOverlay,
  'admin-settings': AdminSettingsModal,
  'rename-explorer-item': RenameExplorerItemModal,
  'move-explorer-item': MoveExplorerItemModal,
  'confirmation-modal': ConfirmationModal,
  'create-product': CreateProductModal,
  'create-request-review-modal': ChangeRequestReviewModal,
  'add-users-modal': AddUsersModal,
  'create-sync-profile-modal': CreateSyncProfileModal,
  'delete-sync-profile-modal': DeleteSyncProfileModal,
  'execute-sync-modal': ExecuteSynchronisationModal,
  'resolve-sync-issues-modal': ResolveSyncIssuesModal,
  'edit-sync-profile': EditSyncProfileModal,
  'export-modal': ExportModal,
  'ownership-error-modal': OwnershipErrorModal,
};
