import React, { useEffect } from 'react';
import { Text, Stack } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { useCurrentUserQuery } from '@assemblio/frontend/data-access';
import Joi from 'joi';
import { AppRole } from '@assemblio/type/user';

type UserSettingsFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

const userSettingsSchema = Joi.object({
  firstName: Joi.string().trim().strict().min(3).required(),
  lastName: Joi.string().trim().strict().min(3).required(),
  email: Joi.string().email({ minDomainSegments: 2, tlds: false }).required(),
  role: Joi.string().trim().strict().min(3).required(),
});

export const PersonalInformation = () => {
  const { data: userData } = useCurrentUserQuery();
  const form = useForm<UserSettingsFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
    validate: joiResolver(userSettingsSchema),
  });

  useEffect(() => {
    if (userData) {
      form.setFieldValue('firstName', userData.firstName);
      form.setFieldValue('lastName', userData.lastName);
      form.setFieldValue('email', userData.email);
      form.setFieldValue('role', (userData.role as AppRole) ?? ('viewer' as AppRole));
    }
  }, [userData]);
  return (
    <Stack gap={'lg'}>
      <Stack gap={'xs'}>
        <Text size={'md'} c={'var(--text-secondary)'} variant={'medium'}>
          First name
        </Text>
        <Text size={'md'} variant={'medium'}>
          {userData?.firstName}
        </Text>
      </Stack>
      <Stack gap={'xs'}>
        <Text size={'md'} c={'var(--text-secondary)'} variant={'medium'}>
          Last name
        </Text>
        <Text size={'md'} variant={'medium'}>
          {userData?.lastName}
        </Text>
      </Stack>
      <Stack gap={'xs'}>
        <Text size={'md'} c={'var(--text-secondary)'} variant={'medium'}>
          Role
        </Text>
        <Text size={'md'} variant={'medium'}>
          {userData?.role}
        </Text>
      </Stack>
      <Stack gap={'xs'}>
        <Text size={'md'} c={'var(--text-secondary)'} variant={'medium'}>
          E-mail
        </Text>
        <Text size={'md'} variant={'medium'}>
          {userData?.email}
        </Text>
      </Stack>
    </Stack>
  );
};
