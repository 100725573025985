import { useState } from 'react';
import { useTenantUsersQuery } from '@assemblio/frontend/data-access';
import { FilterOption } from '../../../types';
import { IconUsers } from '@assemblio/design-system';

interface Return {
  filterProps: FilterOption[];
}

export const useFolderQueryFilters = (): Return => {
  const [enableOwnerQuery, setEnableOwnerQuery] = useState(false);

  const { data: userData, isLoading: userQueryIsLoading } = useTenantUsersQuery(
    {
      exclude: 'viewer',
    },
    enableOwnerQuery
  );

  const getUserData = () => {
    if (userQueryIsLoading) return undefined;
    return userData?.items.map((user) => {
      return {
        label: user.fullName,
        value: user.id,
      };
    });
  };

  return {
    filterProps: [
      {
        label: 'Owner',
        value: 'owner',
        Icon: IconUsers,
        options: getUserData(),
        onToggleOption: (active: boolean) => {
          setEnableOwnerQuery(active);
        },
      },
    ],
  };
};
