import { Box, Group, Stack, Image, Center, Text, Flex } from '@mantine/core';
import classes from './ProjectThumbnail.module.scss';
import cx from 'clsx';
import { useProductImage } from '../../../../../../hooks';
import { InstructionImageSize } from '@assemblio/type/instruction';

interface ProjectThumbnailProps {
  subItemCount: number;
  productIds: string[];
}

interface ThumbnailProps {
  productId?: string;
}

interface SmallThumbnailProps {
  productId?: string;
  count?: number;
}

const MAX_THUMBNAILS = 3;

export const ProjectThumbnail = ({ subItemCount, productIds }: ProjectThumbnailProps) => {
  const thumbnailCountValue = subItemCount > MAX_THUMBNAILS ? subItemCount - (MAX_THUMBNAILS - 1) : undefined;

  return (
    <Group gap={'sm'} pt={'md'} className={classes.container}>
      <LargeThumbnail productId={productIds[0]} />
      <Stack className={classes.thumbnail__stack} h={'100%'} gap={'sm'} justify={'space-between'}>
        <SmallThumbnail productId={productIds[1]} />
        <SmallThumbnail productId={productIds[2]} count={thumbnailCountValue} />
      </Stack>
    </Group>
  );
};

const LargeThumbnail = ({ productId }: ThumbnailProps) => {
  const { imageSrc } = useProductImage(productId, InstructionImageSize.THUMBNAIL_VERTICAL);

  return (
    <Box
      className={cx(classes.thumbnail__large, {
        [classes.thumbnail__border]: !productId || !imageSrc,
      })}
    >
      {imageSrc && <Image className={classes.thumbnail__image} src={imageSrc} />}
    </Box>
  );
};

const SmallThumbnail = ({ productId, count }: SmallThumbnailProps) => {
  const { imageSrc } = useProductImage(productId, InstructionImageSize.THUMBNAIL);

  const hasImage = Boolean(imageSrc);
  const showImage = count === undefined && hasImage;
  const showCount = count !== undefined;

  return (
    <Box
      className={cx(classes.thumbnail__small, {
        [classes.thumbnail__border]: (!productId && !count) || !hasImage,
        [classes.thumbnail__small__count]: !!count,
      })}
    >
      {showImage && <Image className={classes.thumbnail__image} src={imageSrc} />}
      {showCount && (
        <Center h="100%" w="100%">
          <Flex className={classes.count_container} h="32px" w="32px" align="center" justify="center">
            <Text size="sm">+{count}</Text>
          </Flex>
        </Center>
      )}
    </Box>
  );
};
