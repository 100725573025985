import { Group, NumberInput, Text, Tooltip } from '@mantine/core';
import classes from '../Styles/StepSettings.module.scss';
import { useState, useEffect } from 'react';
import cx from 'clsx';
interface StepNumberInputProps {
  stepId: string;
  selected: boolean;
  value: number;
  label: string;
  min: number;
  max: number;
  disabled?: boolean;
  onSetValue: (stepId: string, oldValue: number, newValue: number) => void;
}

export const StepNumberInput = ({
  stepId,
  selected,
  value,
  label,
  min,
  max,
  disabled = false,
  onSetValue,
}: StepNumberInputProps) => {
  const [localValue, setLocalValue] = useState<number>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Group justify={'space-between'}>
      <Text w={'40%'} size={'sm'} c={'text-secondary'}>
        {label}
      </Text>
      <Tooltip
        classNames={{
          tooltip: classes.numberInput__tooltip,
        }}
        label={`Allowed values: ${min} to ${max}`}
        openDelay={500}
      >
        <NumberInput
          w={'50%'}
          data-cy="step-number-input"
          disabled={disabled}
          className={cx(classes.settings_group__section)}
          classNames={{
            input: classes.textInput,
            wrapper: classes.textInputWrapper,
            label: cx(classes.checkboxLabel, 'global__label', {
              'global__label--selected': selected,
            }),
          }}
          value={localValue}
          onChange={(value) => {
            if (typeof value === 'number') {
              setLocalValue(value);
            }
          }}
          onBlur={() => {
            if (value !== localValue) {
              if (localValue >= min && localValue <= max) {
                onSetValue(stepId, value, localValue);
              }
            }
            setLocalValue(value);
          }}
          rightSection={
            <Text pt={'2%'} pr={'.5rem'} size={'xs'} c={'text-tertiary'}>
              s
            </Text>
          }
        />
      </Tooltip>
    </Group>
  );
};
