import { UIController, useUIStore } from '@assemblio/frontend/stores';
import { Button, Menu, Tooltip } from '@mantine/core';
import { IconCheck, IconChevronDown, IconDeviceDesktop } from '@tabler/icons-react';
import classes from './Viewport.module.scss';
import { ContextMenuItem } from '@assemblio/design-system';

export const PreviewSwitch = () => {
  const ratio = useUIStore((state) => state.previewRatio);
  return (
    <Menu position="bottom" width={190} offset={8}>
      <Menu.Target>
        <Tooltip offset={8} position="bottom" label="Switch between different Preview Resolutions">
          <Button
            className={classes.controls__icon}
            rightSection={<IconChevronDown />}
            variant={'ghost'}
            size={'compact-sm'}
            c={'text-secondary'}
          >
            <IconDeviceDesktop size={16} />
          </Button>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <ContextMenuItem
          label={'4 : 3'}
          LeftIcon={IconDeviceDesktop}
          RightIcon={ratio === '4/3' ? IconCheck : undefined}
          onClick={() => UIController.setPreviewRatio('4/3')}
        />
        <ContextMenuItem
          label={'16 : 9 (Default)'}
          LeftIcon={IconDeviceDesktop}
          RightIcon={ratio === '16/9' ? IconCheck : undefined}
          onClick={() => UIController.setPreviewRatio('16/9')}
        />
        <ContextMenuItem
          label={'Fullscreen'}
          LeftIcon={IconDeviceDesktop}
          RightIcon={ratio === 'auto' ? IconCheck : undefined}
          onClick={() => UIController.setPreviewRatio('auto')}
        />
      </Menu.Dropdown>
    </Menu>
  );
};
