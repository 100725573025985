import { ExplorerItem } from '../../../types/items.types';
import { useFolderDelete, useNextProjectDelete } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useExplorerRouteParams } from '../../../../../hooks/ExplorerRouteParams.hook';

export const useDeleteItem = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { folderId } = useExplorerRouteParams();
  const deleteFolderMutation = useFolderDelete();
  const deleteProjectMutation = useNextProjectDelete();

  const deleteItem = (item: ExplorerItem) => {
    item.type === 'Folder' ? deleteFolderItem(item) : deleteProjectItem(item);
  };

  const deleteProjectItem = (item: ExplorerItem) => {
    setIsLoading(true);
    deleteProjectMutation.mutate(
      {
        projectId: item.id,
        parentFolderId: folderId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-project-success',
            message: 'Project has been deleted',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'delete-project-error',
            message: 'Project could not be deleted',
            color: 'red',
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      }
    );
  };

  const deleteFolderItem = (item: ExplorerItem) => {
    if (!folderId) return;
    setIsLoading(true);
    deleteFolderMutation.mutate(
      {
        folderId: item.id,
        parentId: folderId,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'delete-folder-success',
            message: 'Folder has been deleted',
            color: 'green',
          });
          setIsLoading(false);
        },
        onError: (error) => {
          setIsLoading(false);
          let reason;
          if (isAxiosError(error)) {
            reason = error.response?.data.message;
          }
          notifications.show({
            id: 'delete-folder-error',
            title: 'Folder could not be deleted',
            message: reason,
            color: 'red',
          });
        },
      }
    );
  };

  return { deleteItem, isLoading };
};
