import { IconLayoutGridFilled, IconLayoutListFilled } from '@tabler/icons-react';
import { SegmentedControl, Center } from '@mantine/core';
import { useExplorerView } from '../../hooks/';
import classes from './ViewToggleButton.module.scss';

export const ViewToggleButton = () => {
  const { isListView, toggleListView } = useExplorerView();

  return (
    <SegmentedControl
      classNames={classes}
      value={isListView ? 'list' : 'grid'}
      onChange={toggleListView}
      data={[
        {
          value: 'grid',
          label: (
            <Center p={'xs'}>
              <IconLayoutGridFilled className={classes.icon} />
            </Center>
          ),
        },
        {
          value: 'list',
          label: (
            <Center p={'xs'}>
              <IconLayoutListFilled className={classes.icon} />
            </Center>
          ),
        },
      ]}
    />
  );
};
