import { Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

interface TitleWithInformationProps {
  title: string;
  information: string;
}
export const TitleWithInformation = ({ title, information }: TitleWithInformationProps) => {
  return (
    <Group gap={'xs'} mb={'xs'}>
      <Text variant={'medium'}>{title}</Text>
      <Tooltip label={information}>
        <ThemeIcon c={'text-tertiary'} size={'xs'} style={{ border: 'none' }} variant="outline">
          <IconInfoCircle />
        </ThemeIcon>
      </Tooltip>
    </Group>
  );
};
