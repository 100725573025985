import { Group, Select } from '@mantine/core';
import { Resolution } from '@assemblio/shared/next-types';
import { VideoFormatPreset } from '../types/export-modal.types';
import { ResolutionEnum } from '@assemblio/type/export';

type VideoExportSettingsProps = {
  presets: VideoFormatPreset[];
  resolution: Resolution;
  format: VideoFormatPreset;
  setResolution: React.Dispatch<React.SetStateAction<ResolutionEnum>>;
  onFormatSelection: (value: string) => unknown;
};

export const VideoExporterSettings = ({
  presets,
  resolution,
  format,
  setResolution,
  onFormatSelection,
}: VideoExportSettingsProps) => {
  return (
    <Group mb={'xs'} gap={'xs'} w={'100%'} wrap={'nowrap'} align={'stretch'}>
      <Select
        label="Resolution"
        mb={'xs'}
        value={resolution}
        data={Object.keys(ResolutionEnum).map((res) => {
          return { value: res, label: res };
        })}
        allowDeselect={false}
        onOptionSubmit={(value) => setResolution(value as ResolutionEnum)}
      />

      <Select
        label="Format"
        value={format.label}
        onOptionSubmit={onFormatSelection}
        data={presets.map((preset) => preset.label)}
        allowDeselect={false}
      />
    </Group>
  );
};
