import { Center, Group, Table, UnstyledButton, Text, rem, StyleProp } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import classes from '../ExplorerTable.module.scss';

export interface ThProps {
  name?: React.ReactNode;
  reversed?: boolean;
  sorted?: boolean;
  onSort?: () => void;
  width?: StyleProp<React.CSSProperties['width']>;
}

export const Th = ({ name, reversed = false, sorted = false, onSort, width }: ThProps) => {
  const Icon = reversed ? IconArrowUp : IconArrowDown;

  return (
    <Table.Th
      key={`tableHeader-${name}`}
      w={width}
      data-sorted={sorted}
      data-sortable={typeof onSort !== 'undefined'}
      className={classes.th}
    >
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group>
          <Text variant={'medium'}>{name}</Text>
          <Center className={classes.icon}>
            {sorted && <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
};
