import { cameraEvents } from '@assemblio/frontend/events';
import { useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { Hud, OrthographicCamera } from '@react-three/drei';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import {
  BoxGeometry,
  CanvasTexture,
  Color,
  ConeGeometry,
  CylinderGeometry,
  EdgesGeometry,
  Group,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector3,
} from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { ViewCubeMaterial } from './ViewCubeMaterial';
import { viewCube } from './ViewCubeMesh';

const COLORS = {
  light: {
    text: 0x000000,
    highlight: 0xab8bec,
    surface: 0xffffff,
    edge: 0x8b8b8c,
  },
  dark: {
    text: 0xffffff,
    highlight: 0x6a4eb2,
    surface: 0x1a1b1e,
    edge: 0x383b3e,
  },
};

const generateTexture = (textColor: Color, text: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const context = canvas.getContext('2d');
  if (context) {
    context.font = 'bold 110px Inter var, Arial, sans-serif';
    context.textAlign = 'center';
    context.strokeStyle = `#${textColor.getHexString()}`;
    context.fillStyle = `#${textColor.getHexString()}`;
    context.fillText(text, 256, 306);
    const texture = new CanvasTexture(canvas);
    texture.colorSpace = 'srgb';
    return texture;
  }
  return;
};

const cubeData = [
  'right-top-back',
  'left-top-back',
  'left-top-front',
  'right-top-front',
  'right-bottom-front',
  'right-bottom-back',
  'left-bottom-back',
  'left-bottom-front',
  'right-bottom',
  'bottom-back',
  'left-bottom',
  'left-back',
  'right-back',
  'right-front',
  'left-front',
  'top-back',
  'right-top',
  'top-front',
  'left-top',
  'bottom-front',
  'top',
  'front',
  'bottom',
  'right',
  'back',
  'left',
].map((data) => {
  let x = 0,
    y = 0,
    z = 0;
  if (data.includes('right')) {
    x = 1;
  }
  if (data.includes('left')) {
    x = -1;
  }
  if (data.includes('top')) {
    y = 1;
  }
  if (data.includes('bottom')) {
    y = -1;
  }
  if (data.includes('front')) {
    z = 1;
  }
  if (data.includes('back')) {
    z = -1;
  }
  return {
    id: data,
    normal: new Vector3(x, y, z).normalize(),
    name: ['left', 'right', 'top', 'bottom', 'front', 'back'].includes(data) ? data.toUpperCase() : undefined,
  };
});

type ViewCubeProps = {
  marginX?: number;
  marginY?: number;
};
export const ViewCube = ({ marginX = 80, marginY = 80 }: ViewCubeProps) => {
  const colorScheme = useComputedColorScheme();
  const colors = useMemo(() => COLORS[colorScheme as 'light' | 'dark'], [colorScheme]);
  const size = useThree((state) => state.size);
  const { camera } = useThree();
  const cubeRef = useRef<Group | null>(null);
  const hoveredObject = useRef<Object3D | undefined | null>();

  useFrame(() => {
    if (cubeRef.current) {
      cubeRef.current.quaternion.setFromRotationMatrix(camera.matrix.clone().invert());
    }
  }, 0);

  const cube = useMemo(() => {
    const loader = new OBJLoader();
    const object = loader.parse(viewCube);
    object.children.forEach((child) => {
      const mesh = child as Mesh;
      const data = cubeData.find((data) => data.id === child.name);
      const material = new ViewCubeMaterial();

      if (data?.name) {
        const texture = generateTexture(new Color(colors.text), data.name);
        if (texture) {
          material.map = texture;
          material.baseColor = new Color(colors.surface);
        }
      } else {
        material.baseColor = new Color(colors.surface);
      }
      mesh.material = material;
    });
    return object;
  }, [colors]);

  const outline = useMemo(() => {
    const boxGeometry = new BoxGeometry(2, 2, 2);
    const edges = new EdgesGeometry(boxGeometry, 90);
    const line = new LineSegments(edges, new LineBasicMaterial({ color: colors.edge }));
    return line;
  }, [colors]);

  const coordinateSystem = useMemo(() => {
    const group = new Group();
    const axes = [new Vector3(1, 0, 0), new Vector3(0, 1, 0), new Vector3(0, 0, 1)];
    const capHeight = 0.3;
    const capRadius = 0.1;
    const cylinderRadius = 0.05;
    const segments = 16;
    axes.forEach((axis) => {
      const lineGeometry = new CylinderGeometry(cylinderRadius, cylinderRadius, 2 + capHeight, segments, 1);
      const capGeometry = new ConeGeometry(capRadius, capHeight, segments, 1);
      const material = new MeshBasicMaterial({ color: new Color(axis.x, axis.y, axis.z).offsetHSL(-0.023, 0, 0) });
      const cap = new Mesh(capGeometry, material);
      cap.position.set(0, 1 + capHeight, 0);
      const cylinder = new Mesh(lineGeometry, material);
      cylinder.add(cap);
      cylinder.position.set(
        axis.x * (axis.x + capHeight / 2),
        axis.y * (axis.y + capHeight / 2),
        axis.z * (axis.z + capHeight / 2)
      );
      cylinder.quaternion.setFromUnitVectors(new Vector3(0, 1, 0), axis);
      group.add(cylinder);
    });
    group.position.set(-1 - capHeight, -1 - capHeight, -1 - capHeight);
    return group;
  }, []);

  const x = -size.width / 2 + marginX;
  const y = -size.height / 2 + marginY;

  return (
    <Hud renderPriority={1}>
      <OrthographicCamera makeDefault position={[0, 0, 200]} />
      <group ref={cubeRef} position={[x, y, 0]} scale={35}>
        <primitive object={coordinateSystem} />
        <primitive
          object={cube}
          onClick={(e: ThreeEvent<Event>) => {
            e.stopPropagation();
            const cameraPivot = new Vector3(0, 0, -camera.distance).applyMatrix4(camera.matrix);
            const data = cubeData.find((data) => data.id === e.object.name);
            if (data) {
              const normal = data.normal.clone();
              camera.position.copy(cameraPivot.clone().add(normal.multiplyScalar(camera.distance)));
              camera.updateMatrix();
              camera.lookAt(cameraPivot);
              cameraEvents.dispatchEvent('update');
            }
          }}
          onContextMenu={(e: ThreeEvent<Event>) => {
            e.stopPropagation();
          }}
          onPointerMove={(e: ThreeEvent<Event>) => {
            const mesh = e.object as Mesh;
            const material = mesh.material as ViewCubeMaterial;

            material.baseColor = new Color(colors.highlight);

            if (hoveredObject.current && hoveredObject.current !== e.object) {
              const material = (hoveredObject.current as Mesh).material as ViewCubeMaterial;
              material.baseColor = new Color(colors.surface);
            }
            hoveredObject.current = e.object;
          }}
          onPointerLeave={(e: ThreeEvent<Event>) => {
            if (hoveredObject.current) {
              const material = (hoveredObject.current as Mesh).material as ViewCubeMaterial;
              material.baseColor = new Color(colors.surface);
            }
          }}
        />
        <primitive object={outline} />
      </group>
      <ambientLight intensity={2} />
    </Hud>
  );
};
