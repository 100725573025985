import React from 'react';
import { Box, Button, Overlay } from '@mantine/core';
import classes from './HoverButton.module.scss';
import { IconArrowRight } from '@tabler/icons-react';

interface HoverButtonProps {
  onClick: () => void;
}

export const HoverButton = ({ onClick }: HoverButtonProps) => {
  return (
    <>
      <Overlay opacity={0.6} color="black" zIndex={5} className={classes.overlay} />
      <Button variant="primary" className={classes.hoverButton} onClick={onClick}>
        Open
        <Box ml={'sm'}>
          <IconArrowRight />
        </Box>
      </Button>
    </>
  );
};
