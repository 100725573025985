import { useProductExportJobQuery } from '@assemblio/frontend/data-access';
import { Divider } from '@mantine/core';
import { JobCard } from '../../../../../../../../components/JobCard/JobCard';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';

interface ExportJobsListProps {
  productId: string | undefined;
}

export const ExportJobsList = ({ productId }: ExportJobsListProps) => {
  const socket = useEventStore((state) => state.socket);
  const queryClient = useQueryClient();
  const { data: exportJobs, isLoading, refetch } = useProductExportJobQuery(productId);

  const exportJobsIsEmpty = exportJobs && exportJobs.length === 0;

  const handleJobCompletion = () => {
    refetch();
    queryClient.refetchQueries(['artefacts', productId]);
  };

  useEffect(() => {
    if (!productId) return;
    socket.emit('subscribe-product', productId);

    return () => {
      socket.emit('unsubscribe-product', productId);
    };
  }, [productId]);

  if (isLoading || !exportJobs || exportJobsIsEmpty) return null;
  return (
    <>
      {exportJobs?.map((job) => <JobCard onJobCompletion={handleJobCompletion} key={job.id} job={job} />)}
      <Divider />
    </>
  );
};
