import { useState } from 'react';
import { SortingOption } from '../../../types';

interface Return<T extends string | undefined> {
  sortingValue: SortingOption<T> | undefined;
  handleSort: (value: string) => void;
}

export const useSortingOptions = <T extends string | undefined>(options: SortingOption<T>[]): Return<T> => {
  const [sortingValue, setSortingValue] = useState<SortingOption<T> | undefined>(undefined);

  const handleSort = (value: string) => {
    const sortingOption = options.find((option) => option.value === value);
    const isSelectedOption = sortingOption?.value === sortingValue?.value;
    setSortingValue(isSelectedOption ? undefined : sortingOption);
  };

  return {
    sortingValue: sortingValue,
    handleSort,
  };
};
