import { FolderContentDto } from '@assemblio/shared/dtos';
import { useExplorerTitle } from '../../../hooks';

/**
 * Hook to set the page title based on the current folder data - specific to the Assembly Plans page
 * @param currentFolderData
 * @param rootFolderId
 */
export const usePageTitle = (currentFolderData: FolderContentDto | undefined, rootFolderId: string | undefined) => {
  const pageTitle = () => {
    if (!currentFolderData) return undefined;
    return currentFolderData.id === rootFolderId ? 'Assembly Plans' : currentFolderData.name;
  };

  useExplorerTitle(pageTitle(), {
    itemCount: getItemCount(currentFolderData),
  });
};

const getItemCount = (folder: FolderContentDto | undefined): number | undefined => {
  if (!folder) return undefined;
  const folders = folder.children?.length ?? 0;
  const projects = folder.projects?.length ?? 0;
  return folders + projects;
};
