import { useAuthStore } from '@assemblio/frontend/data-access';
import { useEffect, useState } from 'react';
import { Uppy } from '@uppy/core';
import Tus from '@uppy/tus';
import { UppyConfig, FileMetaData, CustomUppyFile, FileProgressBase } from './uppyUploader.types';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationData, notifications } from '@mantine/notifications';
import { IconCircleXFilled } from '@tabler/icons-react';
import classes from './UploadNotification.module.scss';
import { useUppyEvent, useUppyState } from '@uppy/react';
import { ProgressSnackbar } from './components/ProgressSnackbar/ProgressSnackbar';

const DEFAULT_UPPY_CONFIG: UppyConfig = {
  acceptedFileTypes: ['.step', '.stp', '.p21', '.jt'],
  minNumberOfFiles: 1,
  maxChunkSize: 50 * 1024 * 1024,
  maxFileSize: 1 * 1024 * 1024 * 1024,
};

interface Return {
  uploadFile: (file: File, meta: FileMetaData) => Promise<boolean>;
  removeFile: () => void;
  uploadReady: boolean;
}

const PROGRESS_NOTIFICATION_DATA: Partial<NotificationData> = {
  icon: null,
  title: 'File uploading',
  classNames: classes,
  closeButtonProps: {
    classNames: {
      root: classes['close_button'],
    },
    icon: <IconCircleXFilled size={16} color={'var(--text-secondary)'} />,
  },
};

export const useUppyUploader = (): Return => {
  const queryClient = useQueryClient();
  const token = useAuthStore((state) => state.accessToken);
  const { acceptedFileTypes, minNumberOfFiles, maxChunkSize, maxFileSize } = DEFAULT_UPPY_CONFIG;

  // using split and trim to use the pre-existing file types array string value
  const allowedFileTypes = acceptedFileTypes?.map((item) => item.trim());

  // IMPORTANT: passing an initializer function to prevent Uppy from being reinstantiated on every render.
  const [uppy] = useState(() =>
    new Uppy<FileMetaData>({
      autoProceed: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes,
        minNumberOfFiles,
        maxNumberOfFiles: null,
        maxFileSize,
      },
      onBeforeFileAdded: () => true,
    }).use(Tus<FileMetaData, Record<string, never>>, {
      endpoint: '/upload-api/files',
      onBeforeRequest: (req, file) => {
        const { parentFolderId, projectId, fileName, token } = file.meta;

        // Abort the upload if the required meta fields are not set
        if (!parentFolderId || !projectId || !fileName) {
          console.debug(`Aborting upload - required meta fields are not set`, parentFolderId, projectId, fileName);
          req.abort().then(() => {
            uppy.cancelAll();
          });
          return;
        } else {
          req.setHeader('FolderId', parentFolderId);

          req.setHeader('ProjectId', projectId);

          req.setHeader('ProductName', fileName);
        }

        req.setHeader('OriginalFileName', file.name ?? '');
        req.setHeader('Token', `Bearer ${token}`);
      },
      onError(error) {
        console.log(error);
      },
      storeFingerprintForResuming: false,
      id: 'tus',
      chunkSize: maxChunkSize,
    })
  );

  const handleUploadStarted = (files: CustomUppyFile[]) => {
    const activeUpload = files[0];
    showProgressNotification(activeUpload.id, activeUpload.meta.fileName);
  };

  const handleUploadProgress = (file: CustomUppyFile | undefined, progress: FileProgressBase) => {
    if (!file) return;
    const { bytesUploaded, bytesTotal } = progress;
    if (!bytesUploaded || !bytesTotal) return;
    const percentage = (bytesUploaded / bytesTotal) * 100;
    updateProgressNotification(file.id, file.meta.fileName, percentage);
  };

  const onUploadSuccess = (file: CustomUppyFile | undefined) => {
    file && uppy.removeFile(file.id);
    const projectId = file?.meta.projectId;
    queryClient.invalidateQueries(['project', projectId]);
    uppy.cancelAll();
    uppy.clear();
  };

  useUppyEvent(uppy, 'upload-start', handleUploadStarted);
  useUppyEvent(uppy, 'upload-progress', handleUploadProgress);
  useUppyEvent(uppy, 'upload-success', onUploadSuccess);
  useUppyEvent(uppy, 'upload-error', () => {
    notifications.show({
      title: 'Error',
      message: 'An error occurred while uploading the file',
    });
  });

  const handleCancelFileUpload = () => {
    uppy.cancelAll();
    uppy.clear();
  };

  const showProgressNotification = (id: string, name: string | undefined) => {
    notifications.show({
      id,
      autoClose: false,
      message: ProgressSnackbar({
        name: name ?? '',
        progress: 0,
      }),
      onClose: handleCancelFileUpload,
      ...PROGRESS_NOTIFICATION_DATA,
    });
  };

  const updateProgressNotification = (id: string, name: string | undefined, progress: number) => {
    notifications.update({
      id: id,
      title: 'Upload successful',
      message: ProgressSnackbar({
        name: name ?? '',
        progress,
      }),
      autoClose: progress === 100 ? 2000 : false,
      ...PROGRESS_NOTIFICATION_DATA,
    });
  };

  /**
   * Uploads a file to the upload service.
   * @param file
   * @param meta
   * @return Promise<boolean> - Returns a promise that resolves to true if the upload was started successfully, false if the upload could not be started.
   */
  const handleUploadFile = async (file: File, meta: FileMetaData) => {
    const added = uppy.addFile({
      source: 'file input',
      name: file.name,
      type: '.STEP',
      data: file,
    });
    if (!added) return false;
    uppy.setFileMeta(added, meta);
    return uppy
      .upload()
      .then(() => true)
      .catch((err) => false);
  };

  const handleFileRemoval = () => {
    uppy.cancelAll();
    uppy.clear();
  };

  useEffect(() => {
    if (token) {
      uppy.setMeta({ token });
    }
  }, [token]);

  return {
    uploadFile: handleUploadFile,
    removeFile: handleFileRemoval,
    uploadReady: useUppyState(uppy, (state) => state.allowNewUpload),
  };
};
