import React, { forwardRef } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import classes from './SearchField.module.scss';

export const SearchField = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  return (
    <TextInput
      classNames={classes}
      leftSection={<IconSearch size={16} color={'var(--text-primary)'} />}
      {...props}
      ref={ref}
    />
  );
});
