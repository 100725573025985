import { IconChevronRight, IconFolderFilled } from '@tabler/icons-react';
import { Center, Group, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import classes from '../CreateExplorerItemModal.module.scss';
import { PathElementDto } from '@assemblio/shared/dtos';
import { PathType } from '@assemblio/type/path';
import { IconProject } from '@assemblio/design-system';

interface LocationDisplayProps {
  path: PathElementDto[] | undefined;
  isLoading?: boolean;
}

export const LocationDisplay = ({ path, isLoading = false }: LocationDisplayProps) => {
  const lastPathElements = [...(path ?? [])].slice(0, 2);
  const reversed = lastPathElements.reverse();
  return (
    <Stack px={'lg'}>
      <Text variant={'medium'}>Current Location</Text>
      <Group gap={'sm'}>
        {isLoading ? (
          <PathSkeleton />
        ) : (
          reversed.map((location, index) => {
            return (
              <Group gap={'sm'} key={location.id} wrap={'nowrap'}>
                <Tooltip label={location.name} openDelay={500}>
                  <Group wrap={'nowrap'} className={classes.locationDisplay__item} px={'lg'} py={'sm'}>
                    <Center>
                      {location.type === PathType.folder ? <IconFolderFilled size="16" /> : <IconProject />}
                    </Center>
                    <Text lineClamp={1} className={classes.locationDisplay__name} size="sm" variant="medium">
                      {location.name}
                    </Text>
                  </Group>
                </Tooltip>
                {index < reversed.length - 1 && <IconChevronRight size="12" />}
              </Group>
            );
          })
        )}
      </Group>
    </Stack>
  );
};

const PathSkeleton = () => {
  return (
    <Group key={'locationDisplay-skeleton-1'} wrap={'nowrap'}>
      <Skeleton w={'7rem'} h={'2rem'} />
      <IconChevronRight size="16" />
      <Skeleton w={'7rem'} h={'2rem'} />
    </Group>
  );
};
