import { ExportJobDto } from '@assemblio/shared/dtos';
import { Box, Card, Group, Loader, Progress, Stack, Text } from '@mantine/core';
import classes from './JobCard.module.scss';
import { IconMP4, IconPDF, Tag } from '@assemblio/design-system';
import { IconCheck, IconFolder } from '@tabler/icons-react';
import { ExportJobType } from '@assemblio/type/job';
import { useJobUpdates } from './hooks/jobUpdate.hook';
import { JobContextMenu } from './components/JobContextMenu';

interface JobCardProps {
  job: ExportJobDto;
  onJobCompletion: () => void;
}

export const JobCard = ({ job, onJobCompletion }: JobCardProps) => {
  const [progress] = useJobUpdates({ jobId: job.id, onJobCompletion });

  const isInitializing = progress === undefined;

  const showProgressBar = Boolean(progress && progress > 0);

  const isCompleted = Boolean(progress === 0);
  return (
    <Card p={'lg'} className={classes.card}>
      <Group h={'100%'} justify="space-between">
        <Group h={'100%'}>
          <Box className={classes.icon}>{getJobIcon(job.type)}</Box>
          <Stack gap={'xs'} justify="center">
            <Text fw={'bold'} c={'text-primary'}>
              Creating {job.createdFileName ?? job.type}
            </Text>
            {showProgressBar && <Progress color={'var(--text-brand)'} value={progress ?? 0} />}
            {isCompleted ? <Tag leftSection={<IconCheck />}>Completed</Tag> : null}
            {isInitializing && <Tag leftSection={<Loader color={'var(--text-brand)'} size={'xs'} />}>Initializing</Tag>}
          </Stack>
        </Group>
        <Group>
          <JobContextMenu jobId={job.id} productId={job.productId} />
        </Group>
      </Group>
    </Card>
  );
};

const getJobIcon = (type: ExportJobType) => {
  switch (type) {
    case ExportJobType.EXPORT_VIDEO:
      return <IconMP4 />;
    case ExportJobType.EXPORT_PDF:
      return <IconPDF />;
    case ExportJobType.EXPORT_DOCX:
      return <IconPDF />;
    default:
      return <IconFolder />;
  }
};
