import { ActionIcon, AppShell, Box, Group, Stack } from '@mantine/core';
import classes from './Sidebar.module.scss';
import { Logo } from '@assemblio/frontend/components';
import { AdminSidebarButton, UserButton } from '../../components';
import {
  IconCalendarMonth,
  IconChevronsLeft,
  IconChevronsRight,
  IconFileFilled,
  IconStarFilled,
} from '@tabler/icons-react';
import { SidebarItem } from './components/SidebarItems/SidebarItems';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserQuery } from '@assemblio/frontend/data-access';

const sidebarItems = [
  {
    name: 'Assembly Plans',
    icon: <IconFileFilled />,
    navigationTarget: '/explorer',
  },
  {
    name: 'Favorites',
    icon: <IconStarFilled />,
    navigationTarget: '/explorer/favorites',
  },
  {
    name: 'Recent',
    icon: <IconCalendarMonth />,
    navigationTarget: '/explorer/recent',
  },
];

interface SidebarProps {
  collapsed: boolean;
  onToggleCollapse: () => void;
}

export const Sidebar = ({ collapsed, onToggleCollapse }: SidebarProps) => {
  const navigate = useNavigate();
  const { data: userData } = useCurrentUserQuery();
  const appRole = userData?.role?.toLowerCase();

  const handleLogoClick = () => {
    navigate('/explorer');
  };

  return (
    <AppShell.Navbar className={classes.container}>
      <Stack pt={'xl'} mx={'md'} gap={'lg'}>
        <Group w={'100%'} h={50} px={'md'} justify={'space-between'} wrap={'nowrap'} className={classes.topSection}>
          {!collapsed && <Logo accented={false} onClick={handleLogoClick} width={'auto'} />}
          <ActionIcon
            size="sm"
            color="dimmed"
            variant="transparent"
            onClick={onToggleCollapse}
            className={classes.collapseButton}
          >
            {collapsed ? <IconChevronsRight /> : <IconChevronsLeft />}
          </ActionIcon>
        </Group>
        <Stack gap={'xs'}>
          {sidebarItems.map((item) => (
            <SidebarItem
              key={item.name}
              name={item.name}
              icon={item.icon}
              navigationTarget={item.navigationTarget}
              collapsed={collapsed}
            />
          ))}
        </Stack>
      </Stack>
      <Box m={'md'} mb={'lg'}>
        <UserButton collapsed={collapsed} />
        {appRole === 'manager' && <AdminSidebarButton collapsed={collapsed} appRole={appRole} />}
      </Box>
    </AppShell.Navbar>
  );
};
