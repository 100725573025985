import { useUIStore } from '@assemblio/frontend/stores';
import { TextDto, TextElement } from '@assemblio/shared/dtos';
import { HorizontalAlignment, VerticalAlignment } from '@assemblio/type/annotation';
import { useElementSize } from '@mantine/hooks';
import _ from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { BaseEditor, Descendant, Editor, createEditor } from 'slate';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react';
import classes from './TextEditor.module.scss';
import { notifications } from '@mantine/notifications';

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
  const style = { textAlign: element.align } as React.CSSProperties;
  switch (element.type) {
    case 'heading':
      switch (element.level) {
        case 1:
          return <h1 style={style}>{children}</h1>;
        case 2:
          return <h2 style={style}>{children}</h2>;
        case 3:
          return <h3 style={style}>{children}</h3>;
        default:
          return (
            <p style={style} {...attributes}>
              {children}
            </p>
          );
      }
    case 'ordered-list':
      return <ol style={style}>{children}</ol>;
    case 'unordered-list':
      return <ul style={style}>{children}</ul>;
    case 'paragraph': //Fallthrough, default is paragraph for now
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export interface Props {
  onChange: (value: TextElement[]) => void;
  onLengthChange?: (length: number) => void;
  text?: TextDto | null;
  verticalAlignment: VerticalAlignment;
  horizontalAlignment: HorizontalAlignment;
  maxLength?: number;
  onTextAreaResize?: (width: number, height: number) => void;
  background?: string;
  color?: string;
  editorHeight?: string;
  textClassName?: string;
  debounced?: boolean;
}

export const TextEditor = forwardRef<BaseEditor & ReactEditor, Props>(
  (
    {
      onChange,
      onLengthChange,
      text,
      verticalAlignment,
      horizontalAlignment,
      onTextAreaResize,
      maxLength,
      background,
      color,
      editorHeight,
      textClassName,
      debounced = true,
    }: Props,
    ref
  ) => {
    const { ref: containerRef, width, height } = useElementSize<HTMLDivElement>();
    const view = useUIStore.getState().view;
    const isAnimating = useUIStore((state) => state.isAnimating);
    const disabled = view === 'viewer' || isAnimating;

    useEffect(() => {
      if (containerRef.current && onTextAreaResize && width > 0 && height > 0) {
        onTextAreaResize(width, height);
      }
    }, [containerRef, width, height, onTextAreaResize]);

    const editor = useMemo(() => withReact(createEditor()), []);
    useImperativeHandle(ref, () => editor);

    const value = text
      ? text.elements
      : [
          {
            type: 'paragraph',
            align: horizontalAlignment,
            children: [{ text: '' }],
          },
        ];

    const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);

    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

    const getVerticalAlignment = () => {
      switch (verticalAlignment) {
        case 'top':
          return 'flex-start';
        case 'middle':
          return 'center';
        case 'bottom':
          return 'flex-end';
      }
    };

    const onChangeCallback = (value: Descendant[]) => {
      onChange && onChange(value as TextElement[]);
    };

    const debouncedChangeCallback = useRef(_.debounce(onChangeCallback, 500));

    const onChangeHandler = (value: Descendant[]) => {
      if (debounced) debouncedChangeCallback.current(value);
      else {
        onChangeCallback(value);
      }
      if (onLengthChange) {
        const textLength = Editor.string(editor, []).length;
        onLengthChange(textLength);
      }
    };

    const handleBeforeInput = (e: InputEvent) => {
      const inputType = e.inputType;
      if (inputType === 'insertText') {
        const textLength = Editor.string(editor, []).length;
        if (maxLength !== undefined && textLength >= maxLength) {
          e.preventDefault();
          return;
        }
      }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
      const pastedText = e.clipboardData.getData('text');
      const currentTextLength = Editor.string(editor, []).length;
      if (maxLength !== undefined && currentTextLength + pastedText.length > maxLength) {
        e.preventDefault();
        notifications.show({
          id: 'step-text-paste-error',
          message: `Pasted text exceeds the maximum text length of ${maxLength}`,
          color: 'red',
        });
        return;
      }
    };

    return (
      <div
        className={classes.wrapper}
        style={{
          justifyContent: getVerticalAlignment(),
        }}
        onClick={() => {
          ReactEditor.focus(editor);
        }}
      >
        <Slate editor={editor} initialValue={value as Descendant[]} onChange={onChangeHandler}>
          <Editable
            readOnly={disabled}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onMouseDown={(e) => e.stopPropagation()}
            className={textClassName}
            onDOMBeforeInput={handleBeforeInput}
            onPaste={handlePaste}
            style={{
              color: color ?? 'black',
              fontFamily: 'Arial, Helvetica, sans-serif',
              userSelect: disabled ? 'none' : 'auto',
              cursor: disabled ? 'default' : 'text',
              outline: '0px solid transparent',
            }}
          />
        </Slate>
      </div>
    );
  }
);
