export const IconLogOut = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.85711C0 1.91033 0.767512 1.14282 1.71429 1.14282H12C12.9467 1.14282 13.7143 1.91033 13.7143 2.85711V3.79759C13.283 3.66906 12.8112 3.68683 12.3775 3.86648C11.7193 4.13909 11.2629 4.73718 11.1633 5.42852H7.42857C6.00841 5.42852 4.85714 6.57979 4.85714 7.99995C4.85714 9.42011 6.00841 10.5714 7.42857 10.5714H11.1633C11.2629 11.2627 11.7193 11.8608 12.3775 12.1335C12.8112 12.3131 13.283 12.3308 13.7143 12.2024V13.1428C13.7143 14.0896 12.9467 14.8571 12 14.8571H1.71429C0.767512 14.8571 0 14.0896 0 13.1428V2.85711ZM12.9242 5.18633C13.1377 5.09789 13.3835 5.14678 13.547 5.3102L15.8327 7.59592C16.0558 7.81907 16.0558 8.18089 15.8327 8.40405L13.547 10.6898C13.3835 10.8532 13.1377 10.9021 12.9242 10.8136C12.7106 10.7252 12.5714 10.5168 12.5714 10.2857V9.14283H7.42857C6.79739 9.14283 6.28571 8.63117 6.28571 7.99998C6.28571 7.3688 6.79739 6.85712 7.42857 6.85712H12.5714V5.71426C12.5714 5.48314 12.7106 5.27479 12.9242 5.18633Z"
        fill="currentColor"
      />
    </svg>
  );
};
