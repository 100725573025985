import {
  ActionIcon,
  Menu,
  Stack,
  Text,
  Group,
  Button,
  ThemeIcon,
  ColorInput,
  Slider,
  NumberInput,
  Switch,
} from '@mantine/core';
import { IconRefresh, IconSettingsFilled } from '@tabler/icons-react';
import classes from './ViewportSettingsMenu.module.scss';
import { useState } from 'react';
import { useSetInstructionEditorSettings } from '@assemblio/frontend/data-access';
import { SettingsController, useProjectStore, useSettingsStore } from '@assemblio/frontend/stores';
import { useIsOwner } from '@assemblio/frontend/hooks';
import { IconColourPicker } from '@assemblio/design-system';
import { shallow } from 'zustand/shallow';
import cx from 'clsx';

interface ViewportSettingsMenuProps {
  disabled?: boolean;
}

const defaultSettings = {
  viewportColor: '#251E29',
  grid: {
    enabled: true,
    color: '#000000',
    thickness: 1,
  },
  table: {
    enabled: false,
    color: '#ffffff',
  },
};

export const ViewportSettingsMenu = ({ disabled = false }: ViewportSettingsMenuProps) => {
  const [opened, setOpened] = useState(false);
  const [settingsDirty, setSettingsDirty] = useState<boolean>(false);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const isOwner = useIsOwner();

  const setEditorSettingsMutation = useSetInstructionEditorSettings();

  const { viewportBackgroundColor, gridSettings, tableSettings } = useSettingsStore(
    (state) => ({
      viewportBackgroundColor: state.viewport.color,
      gridSettings: state.viewport.grid,
      tableSettings: state.viewport.table,
    }),
    shallow
  );

  const { setGridSettings, setGridSettingsByKey, setTableSettings, setTableSettingsByKey, setViewportColor } =
    SettingsController;

  const handleResetSettings = () => {
    setSettingsDirty(true);
    setGridSettings(defaultSettings.grid);
    setTableSettings(defaultSettings.table);
    setViewportColor(defaultSettings.viewportColor);
  };

  const onViewportColorChange = (value: string) => {
    setSettingsDirty(true);
    setViewportColor(value);
  };

  const onGridColorChange = (value: string) => {
    setSettingsDirty(true);
    setGridSettingsByKey('color', value);
  };

  const onGridEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsDirty(true);
    setGridSettingsByKey('enabled', e.currentTarget.checked);
  };

  const onThicknessChange = (value: number) => {
    setSettingsDirty(true);
    setGridSettingsByKey('thickness', +value.toFixed(1));
  };

  const onTableEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsDirty(true);
    setTableSettingsByKey('enabled', e.currentTarget.checked);
  };

  const onTableColorChange = (value: string) => {
    setSettingsDirty(true);
    setTableSettingsByKey('color', value);
  };

  const handleChange = (opened: boolean) => {
    if (isColorPickerOpen) return;
    if (opened) {
      setOpened(true);
      return;
    }
    if (isOwner && settingsDirty) {
      const editorSettings = SettingsController.getSettingsForUpdate();
      const id = useProjectStore.getState().instructionId;
      setEditorSettingsMutation.mutate({ id, data: editorSettings });
    }
    setSettingsDirty(false);
    setOpened(false);
  };

  return (
    <Menu
      classNames={{
        dropdown: classes.menu_dropdown,
      }}
      position={'bottom-end'}
      width={'300px'}
      disabled={disabled}
      opened={opened}
      onChange={handleChange}
      withinPortal={false}
    >
      <Menu.Target>
        <ActionIcon variant={'ghost'}>
          <IconSettingsFilled />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Group pt={'lg'} pb={'md'} pl={'lg'} pr={'md'} justify={'space-between'}>
          <Text fw={'bold'}>Settings</Text>
          <Button
            size={'xs'}
            variant={'transparent'}
            c={'text-primary'}
            leftSection={<IconRefresh style={{ height: '1rem', width: '1rem' }} />}
            onClick={handleResetSettings}
          >
            Reset
          </Button>
        </Group>
        <Menu.Divider m={0} />
        <Stack p={'md'}>
          <Text size={'sm'} variant={'medium'}>
            Viewport colour
          </Text>
          <Group>
            <ThemeIcon size={'xs'} variant={'transparent'} c={'text-secondary'}>
              <IconColourPicker />
            </ThemeIcon>
            <ColorInput
              popoverProps={{
                onOpen: () => setIsColorPickerOpen(true),
                onClose: () => setIsColorPickerOpen(false),
              }}
              value={viewportBackgroundColor}
              onChange={onViewportColorChange}
            />
          </Group>
          <Group justify={'space-between'}>
            <Text size={'sm'} variant={'medium'}>
              Show viewport grid
            </Text>
            <Switch
              size={'xs'}
              color={'text-brand'}
              className={cx(classes.settings__item)}
              checked={gridSettings.enabled}
              onChange={(event) => onGridEnabledChange(event)}
            />
          </Group>
        </Stack>

        <Menu.Divider m={0} />

        <Stack p={'md'} py={'sm'}>
          <Text size={'sm'} variant={'medium'}>
            Grid colour
          </Text>
          <Group>
            <ThemeIcon size={'xs'} variant={'transparent'} c={'text-secondary'}>
              <IconColourPicker />
            </ThemeIcon>
            <ColorInput
              popoverProps={{
                onOpen: () => setIsColorPickerOpen(true),
                onClose: () => setIsColorPickerOpen(false),
              }}
              value={gridSettings.color}
              onChange={onGridColorChange}
              closeOnColorSwatchClick={false}
            />
          </Group>
          <Text size={'sm'} variant={'medium'}>
            Grid thickness
          </Text>
          <Group pr={'md'} mb={'sm'} align={'start'}>
            <NumberInput
              w={'20%'}
              size={'sm'}
              className={classes.number_input}
              color="text-brand"
              hideControls
              suffix={'%'}
              value={gridSettings.thickness * 50}
              onChange={(event) => onThicknessChange(Number(event) / 50)}
            />
            <Slider
              w={'75%'}
              color="text-brand"
              size={2}
              pt={'xs'}
              classNames={{
                thumb: classes.slider_thumb,
                mark: classes.slider_mark,
                markLabel: classes.slider_mark_label,
                markWrapper: classes.slider_mark_wrapper,
              }}
              value={gridSettings.thickness}
              onChange={(event) => onThicknessChange(event)}
              showLabelOnHover={false}
              min={0}
              max={2}
              step={0.1}
              marks={[
                { value: 0, label: '0%' },
                { value: 0.5, label: '25%' },
                { value: 1.0, label: '50%' },
                { value: 1.5, label: '75%' },
                { value: 2, label: '100%' },
              ]}
            />
          </Group>
        </Stack>

        <Menu.Divider m={0} />

        <Stack p={'md'}>
          <Group justify={'space-between'}>
            <Text size={'sm'} variant={'medium'}>
              Show table
            </Text>
            <Switch
              size={'xs'}
              color={'text-brand'}
              className={cx(classes.settings__item)}
              checked={tableSettings.enabled}
              onChange={(event) => onTableEnabledChange(event)}
            />
          </Group>

          <Text size={'sm'} variant={'medium'}>
            Table colour
          </Text>
          <Group>
            <ThemeIcon size={'xs'} variant={'transparent'} c={'text-secondary'}>
              <IconColourPicker />
            </ThemeIcon>
            <ColorInput
              popoverProps={{
                onOpen: () => setIsColorPickerOpen(true),
                onClose: () => setIsColorPickerOpen(false),
              }}
              value={tableSettings.color}
              onChange={onTableColorChange}
            />
          </Group>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
