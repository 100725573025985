import { useModalContext } from '@assemblio/frontend/components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useUserSettings = () => {
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [userSettingsOpened, setUserSettingsOpened] = useState(false);

  const handleOpenUserSettings = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('profile', 'open');
    navigate({ search: `?${searchParams.toString()}` }, { replace: false });
    openModal('user-settings-overlay', {
      onClose: handleCloseUserSettings,
    });
    setUserSettingsOpened(true);
  };

  const handleCloseUserSettings = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('profile');
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
    setUserSettingsOpened(false);
    closeModal();
  };

  useEffect(() => {
    if (searchParams.has('profile')) {
      handleOpenUserSettings();
    } else {
      handleCloseUserSettings();
    }
  }, [location.search]);

  return {
    closeUserSettings: handleCloseUserSettings,
    openUserSettings: handleOpenUserSettings,
    settingsOpened: userSettingsOpened,
  };
};
