import { Box, Breadcrumbs, Text, ThemeIcon } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import classes from '../ProductCard.module.scss';
import { useElementSize } from '@mantine/hooks';
import cx from 'clsx';

interface ProductCardBreadcrumbsProps {
  path: [string, string];
}

export const ProductCardBreadcrumbs = ({ path }: ProductCardBreadcrumbsProps) => {
  const { ref, width } = useElementSize();
  const { ref: innerRef, width: innerWidth } = useElementSize();
  return (
    <Box
      ref={ref}
      className={cx(classes.breadcrumbs__wrapper, {
        [classes['breadcrumbs__root__ellipsis']]: width < innerWidth,
      })}
    >
      <Breadcrumbs
        ref={innerRef}
        classNames={{
          root: classes.breadcrumbs__root,
        }}
        separator={
          <ThemeIcon m={0} variant={'transparent'} c={'text-tertiary'} size={'sm'}>
            <IconChevronRight style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
        }
      >
        {path.map((p) => (
          <Text key={`path-${p}`} size={'sm'} c={'text-tertiary'}>
            {p}
          </Text>
        ))}
      </Breadcrumbs>
    </Box>
  );
};
