import { Divider, Group, Text } from '@mantine/core';
import classes from '../ArtefactsCard.module.scss';
import React from 'react';
import { getFormattedDateDifference, formatBytes } from '../../../util';
interface ArtefactFileInformationProps {
  type: string;
  size: number;
  timestamp: Date;
}

export const ArtefactFileInformation = ({ type, size, timestamp }: ArtefactFileInformationProps) => {
  return (
    <Group align={'center'}>
      <Text c={'text-tertiary'} size={'sm'}>
        {type.toUpperCase()} • {formatBytes(size)}
      </Text>
      <Divider
        classNames={{
          root: classes.divider,
        }}
        orientation="vertical"
      />
      <Text c={'text-tertiary'} size={'sm'}>
        Created {getFormattedDateDifference(timestamp)}
      </Text>
    </Group>
  );
};
