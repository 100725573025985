import { useConfirmationDialog } from '@assemblio/frontend/components';
import { useDeleteAnnotation, useDeleteImageAnnotation } from '@assemblio/frontend/data-access';
import { AnnotationController } from '@assemblio/frontend/stores';
import { ActionIcon } from '@mantine/core';
import { IconTrashFilled } from '@tabler/icons-react';
import classes from './Annotations.module.scss';

type CommonToolProps = {
  annotationId: string;
  isImage?: boolean;
};

export const CommonTools = ({ annotationId, isImage = false }: CommonToolProps) => {
  const deleteAnnotationMutation = useDeleteAnnotation();
  const deleteImageAnnotationMutation = useDeleteImageAnnotation();

  const handleRemoveAnnotation = () => {
    const stepIds = AnnotationController.disassociateAnnotationFromAllSteps(annotationId);
    AnnotationController.removeAnnotationFromSteps(stepIds, annotationId);
    AnnotationController.removeAnnotation(annotationId);

    if (isImage) {
      deleteImageAnnotationMutation.mutate(annotationId);
    } else {
      deleteAnnotationMutation.mutate(annotationId);
    }
  };

  const { openDialog, RenderConfirmationDialog } = useConfirmationDialog(
    'This annotation is used in several steps, are you sure you want to delete it?',
    handleRemoveAnnotation
  );

  const handleClick = () => {
    const count = AnnotationController.getAnnotationStepCount(annotationId);
    if (count && count > 1) {
      openDialog();
    } else {
      handleRemoveAnnotation();
    }
  };

  return (
    <>
      {RenderConfirmationDialog()}
      <ActionIcon
        className={classes.controls__target}
        size="md"
        variant="transparent"
        c={'text-secondary'}
        onClick={() => handleClick()}
      >
        <IconTrashFilled />
      </ActionIcon>
    </>
  );
};
