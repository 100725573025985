import { Group } from '@mantine/core';
import { PropsWithChildren } from 'react';
import classes from './Annotations.module.scss';
export interface Props {
  label?: string;
}

export const ToolbarGroup = (props: PropsWithChildren<Props>) => {
  return (
    <Group
      className={classes.toolbar}
      gap="0"
      py={'xs'}
      px={'sm'}
      justify="center"
      h={'100%'}
      wrap={'nowrap'}
      style={{ pointerEvents: 'auto' }}
    >
      {props.children}
    </Group>
  );
};
