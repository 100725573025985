import { Box, Center, Text } from '@mantine/core';
import classes from './VersionBadge.module.scss';
interface VersionBadgeProps {
  version: string;
}

export const VersionBadge = ({ version }: VersionBadgeProps) => {
  return (
    <Center className={classes.badge} p={'xs'}>
      <Text>{version}</Text>
    </Center>
  );
};
