import { Box, Checkbox, Stack, Text } from '@mantine/core';
import cx from 'clsx';
import classes from './AppearanceMode.module.scss';

interface ThemeBoxProps {
  image: string;
  altText: string;
  selected: boolean;
  onSelect: () => void;
  label: string;
}

export const ThemeBox = ({ image, altText, selected, onSelect, label }: ThemeBoxProps) => {
  return (
    <Stack>
      <Box onClick={onSelect} className={cx(classes.theme_box, { [classes.theme_box_selected]: selected })}>
        <img src={image} alt={altText} />
        <Checkbox className={classes.check_box} checked={selected} />
      </Box>
      <Text ta={'center'} size={'sm'} variant={'medium'}>
        {label}
      </Text>
    </Stack>
  );
};
