import { useInstructionRename } from '@assemblio/frontend/data-access';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useExplorerRouteParams } from './ExplorerRouteParams.hook';
import { useModalContext } from '@assemblio/frontend/components';

export const useRenameProduct = () => {
  const { openModal } = useModalContext();

  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useExplorerRouteParams();

  const renameProductMutation = useInstructionRename();

  const renameProduct = (name: string, productId: string) => {
    openModal('rename-explorer-item', {
      name: name,
      onRename: (newName: string) => submitRenameProduct(newName, productId),
    });
  };

  const submitRenameProduct = (name: string, productId: string) => {
    setIsLoading(true);
    renameProductMutation.mutate(
      {
        id: productId,
        projectId,
        data: { name: name },
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'rename-product-success',
            message: 'Product has been renamed',
            color: 'green',
          });
        },
        onError: () => {
          notifications.show({
            id: 'rename-product-error',
            message: 'Product could not be renamed',
            color: 'red',
          });
        },
        onSettled: () => setIsLoading(false),
      }
    );
  };

  return { renameProduct, isLoading };
};
