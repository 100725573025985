import { TextChild } from '@assemblio/shared/dtos';
import { ActionIcon } from '@mantine/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import classes from '../TextEditor.module.scss';

interface Props {
  icon: JSX.Element;
  format: keyof TextChild;
  editor: (BaseEditor & ReactEditor) | null;
}
export const MarkButton = ({ format, icon, editor }: Props) => {
  const [active, setActive] = useState(false);

  const isMarkActive = (editor: Editor, format: keyof TextChild) => {
    const marks = Editor.marks(editor);
    const mark = marks ? _.get(marks, format) : false;
    return typeof mark === 'boolean' ? mark : false;
  };

  const toggleMark = (editor: Editor, format: keyof TextChild) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
      setActive(false);
    } else {
      Editor.addMark(editor, format, true);
      setActive(true);
    }
  };

  useEffect(() => {
    if (editor) {
      const previousOnChange = editor.onChange;
      editor.onChange = () => {
        setActive(isMarkActive(editor, format));
        previousOnChange && previousOnChange();
      };
      return previousOnChange;
    }
    return _.noop;
  }, [editor, format]);

  return editor ? (
    <ActionIcon
      style={{ cursor: 'pointer' }}
      variant={active ? 'filled' : 'transparent'}
      color={active ? 'text-brand' : 'text-primary'}
      size={'md'}
      className={classes.controls__target}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ActionIcon>
  ) : null;
};
