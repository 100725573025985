import { ProductContentDto, ProductOverviewDto } from '@assemblio/shared/dtos';
import { InstructionState } from '@assemblio/type/instruction';
import { notifications } from '@mantine/notifications';
import { ErrorNotification } from '@assemblio/frontend/types';
import { useUIStore } from '@assemblio/frontend/stores';
import { useAuthStore } from '@assemblio/frontend/data-access';
import { useModalContext } from '@assemblio/frontend/components';
import { useAppTransition } from './appTransition.hook';

export const useOpenProduct = () => {
  const view = useUIStore.getState().view;

  const userId = useAuthStore((state) => state.userId);

  const appTransition = useAppTransition();
  const { openModal } = useModalContext();

  const checkOwnership = (ownerId: string) => {
    //Bypass ownership check for KIM
    if (view !== 'editor') return true;
    return ownerId === userId;
  };

  const handleOpenProduct = (product: ProductContentDto | ProductOverviewDto, projectId: string) => {
    if (product.state === InstructionState.INITIALIZING) {
      notifications.show(ErrorNotification.instructionIsBeingInitialized);
      return;
    }

    if (product.state === InstructionState.PROCESSING) {
      notifications.show(ErrorNotification.instructionIsBeingProcessed);
      return;
    }

    const ownerId = 'owner' in product ? product.owner.id : product.ownedBy.id;

    if (!checkOwnership(ownerId)) {
      openModal('ownership-error-modal', {
        resourceId: product.id,
        resourceName: product.name,
      });
      return;
    }

    if (view !== 'editor' && product.state !== InstructionState.APPROVED) {
      openModal('confirmation-modal', {
        label: 'Product not approved',
        message:
          'The instruction for this product is not complete yet. You can still view the product but it is still subject to change.',
        onConfirm: () => appTransition(projectId, product.id),
      });
      return;
    }

    appTransition(projectId, product.id);
  };

  return [handleOpenProduct];
};
