type ShortCutType = 'VIEW' | 'EDIT' | 'PLAY' | 'CAMERA';

interface ShortCut {
  label: string;
  keys: [string, string?];
}

interface ShortCutGroup {
  title: ShortCutType;
  shortcuts: ShortCut[];
}

export const SHORTCUT_DATA: ShortCutGroup[] = [
  {
    title: 'VIEW',
    shortcuts: [
      {
        label: 'Collapse sidebar',
        keys: ['ALT', 'C'],
      },
      {
        label: 'Expand sidebar',
        keys: ['ALT', 'E'],
      },
      {
        label: 'Select step above',
        keys: ['↓', 'Arrow down'],
      },
      {
        label: 'Select step below',
        keys: ['↑', 'Arrow up'],
      },
    ],
  },
  {
    title: 'EDIT',
    shortcuts: [
      {
        label: 'Translation mode',
        keys: ['W'],
      },
      {
        label: 'Rotation mode',
        keys: ['R'],
      },
    ],
  },
  {
    title: 'PLAY',
    shortcuts: [
      {
        label: 'Play/pause animation',
        keys: ['ALT', 'P'],
      },
      {
        label: 'Stop animation',
        keys: ['ALT', 'S'],
      },
      {
        label: 'Next animation',
        keys: ['ALT', 'N'],
      },
      {
        label: 'Previous animation',
        keys: ['ALT', 'B'],
      },
    ],
  },
  {
    title: 'CAMERA',
    shortcuts: [
      {
        label: 'Set camera of selected step',
        keys: ['C'],
      },
      {
        label: 'Center camera on selection',
        keys: ['Spacebar'],
      },
    ],
  },
];
