import { TitleWithInformation } from '../TitleWithInformation';
import { Box, Checkbox, Skeleton, Stack } from '@mantine/core';
import { BrandingOptionsDTO } from '@assemblio/shared/dtos';
import produce from 'immer';

type BrandingOptionKeys = keyof BrandingOptionsDTO;

type BrandingOptionsMap = {
  [key in BrandingOptionKeys]: {
    label: string;
    description?: string;
  };
};

const brandingOptionsInformationMap: BrandingOptionsMap = {
  showDate: {
    label: 'Date',
    description: 'Creation Date of the instruction.',
  },
  showContributor: {
    label: 'Contributor',
    description: 'Name of the responsible contributor.',
  },
  showPartCount: {
    label: 'Part Count',
    description: 'Sum of all parts used in the instruction.',
  },
  showStepCount: {
    label: 'Step Count',
    description: 'Number of steps included in the instruction.',
  },
  showVersion: {
    label: 'Version No.',
  },
};
interface HeaderTableContentProps {
  options: BrandingOptionsDTO | null;
  isLoading: boolean;
  setHeaderInfo: React.Dispatch<React.SetStateAction<BrandingOptionsDTO | null>>;
}
export const HeaderTableContent = ({ options, isLoading, setHeaderInfo }: HeaderTableContentProps) => {
  const brandingOptionsKeys = Object.keys(brandingOptionsInformationMap) as BrandingOptionKeys[];

  return (
    <Stack gap={'xs'}>
      <TitleWithInformation
        title={'Header Table Content'}
        information={'Aggregated Product Information to be displayed on the cover sheet of your instruction.'}
      />
      {isLoading || !options ? (
        <HeaderTableContentSkeleton />
      ) : (
        brandingOptionsKeys.map((info, index) => (
          <Checkbox
            key={`header-info-checkbox-${index}`}
            checked={options[info]}
            onChange={(e) => {
              setHeaderInfo(
                produce<BrandingOptionsDTO | null>((state: BrandingOptionsDTO | null) => {
                  if (!state) return;
                  state[info] = !state[info];
                })
              );
            }}
            description={brandingOptionsInformationMap[info]?.description}
            label={brandingOptionsInformationMap[info]?.label}
            mb={'sm'}
          />
        ))
      )}
    </Stack>
  );
};

export const HeaderTableContentSkeleton = () => {
  return (
    <Stack>
      <Skeleton h={'2rem'} w={'100%'} />
      <Skeleton h={'2rem'} w={'100%'} />
      <Skeleton h={'2rem'} w={'100%'} />
      <Skeleton h={'2rem'} w={'100%'} />
      <Skeleton h={'2rem'} w={'100%'} />
    </Stack>
  );
};
