import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useAdminSettings = () => {
  const [adminSettingsOpened, setAdminSettingsOpened] = useState(false);

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const closeUserProfile = () => {
    setAdminSettingsOpened(false);
  };

  const openUserProfile = () => {
    setAdminSettingsOpened(true);
  };

  useEffect(() => {
    if (searchParams.has('administration')) {
      openUserProfile();
    } else {
      closeUserProfile();
    }
  }, [search]);

  return adminSettingsOpened;
};
