import { Button, Modal, Stack, Title, TextInput, Text, Group, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormEvent, useState } from 'react';
import { IconCircleXFilled } from '@tabler/icons-react';
import classes from './CreateProductModal.module.scss';
import { FileUpload } from './components/FileUpload/FileUpload';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { ProjectContentDto } from '@assemblio/shared/dtos';
import { useQueryClient } from '@tanstack/react-query';
import { FileMetaData } from '@assemblio/frontend/hooks';

interface CreateProductModalProps {
  opened: boolean;
  onClose: () => void;
  submitFileUpload: (file: File, meta: FileMetaData) => void;
  removeFile: () => void;
  uploadDisabled?: boolean;
}

const NAME_INPUT_PLACEHOLDER = 'Product name';

export const CreateProductModal = ({
  opened,
  onClose,
  submitFileUpload,
  removeFile,
  uploadDisabled = false,
}: CreateProductModalProps) => {
  const queryClient = useQueryClient();
  const { projectId } = useExplorerRouteParams();

  const [activeFile, setActiveFile] = useState<File | null>(null);

  const getParentFolderId = () => {
    const projectData = queryClient.getQueryData<ProjectContentDto>(['project', projectId]);
    return projectData?.folder?.id;
  };
  const form = useForm({
    initialValues: { productName: '' },
    validate: {
      productName: (value) => {
        if (value.trim().length < 3 || value.trim().length > 55) {
          return 'Name must be between 3 and 55 characters long';
        }
        return null;
      },
    },
  });

  const handleFileSelection = (file: File | null) => {
    const currentInput: string = form.getInputProps('productName').value;

    const fileNameUsed = file?.name.includes(currentInput);

    if (file) {
      /** Replace Input for name when none is set or current name is equal to previous fileName */
      if (!currentInput || fileNameUsed) {
        /** Remove extension before setting name */
        form.setFieldValue('productName', file.name.replace(/\.[^/.]+$/, ''));
      }
      setActiveFile(file);
    } else {
      form.setFieldValue('productName', '');
      setActiveFile(null);
      removeFile();
    }
  };

  const handleCreation = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!activeFile) return;
    if (form.validate().hasErrors) {
      return;
    }

    submitFileUpload(activeFile, {
      fileName: form.values.productName,
      projectId,
      parentFolderId: getParentFolderId(),
    });

    handleCancel();
  };

  const handleCancel = () => {
    setActiveFile(null);
    form.reset();
    onClose();
  };

  return (
    <Modal
      withCloseButton={false}
      opened={opened}
      onClose={handleCancel}
      withinPortal={false}
      centered
      classNames={{
        body: classes.modalBody,
      }}
    >
      <form onSubmit={handleCreation}>
        <Stack>
          <Group justify="space-between" px={'lg'} pt={'lg'} pb={'md'} className={classes.modalTitle}>
            <Title fw={'bold'} size={'md'} c={'--text-primary'}>
              New Product
            </Title>
            <IconCircleXFilled cursor={'pointer'} onClick={handleCancel} size={16} />
          </Group>
          <Box px={'lg'}>
            <TextInput
              mb={'md'}
              classNames={{ input: classes.textInput }}
              label={
                <Text variant={'medium'} size={'sm'} c={'text-secondary'} mb={'xs'}>
                  Product name
                </Text>
              }
              placeholder={NAME_INPUT_PLACEHOLDER}
              {...form.getInputProps('productName')}
            />

            <FileUpload activeFile={activeFile} onFileChange={handleFileSelection} />
          </Box>
          <Group mt={'lg'} p={'lg'} className={classes.btnGroup}>
            <Button variant={'secondary'} onClick={handleCancel} py={'sm'} px={'md'}>
              Cancel
            </Button>
            <Button disabled={!activeFile || uploadDisabled} variant={'primary'} type={'submit'} py={'sm'} px={'md'}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};
