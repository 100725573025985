import { useAuthStore } from '@assemblio/frontend/data-access';
import { Box, Button, Stack, Text } from '@mantine/core';
import { IconArrowLeft, IconFoldersOff } from '@tabler/icons-react';
import classes from '../ExplorerFolderListView/ExplorerFolderListView.module.scss';
import { PopulatedFolderList } from './components/PopulatedFolderList/PopulatedFolderList';
import { FolderContentDto } from '@assemblio/shared/dtos';

interface ExplorerFolderListViewProps {
  currentFolder: FolderContentDto;
  onFolderNavigation: (targetId?: string) => unknown;
}

export const ExplorerFolderListView = ({ currentFolder, onFolderNavigation }: ExplorerFolderListViewProps) => {
  const tenantName = useAuthStore((state) => state.tenant.name);
  const parentFolderId = currentFolder?.parent?.id;
  return (
    <Stack px={'sm'} h={400} my={'sm'} gap={'xs'}>
      {parentFolderId && (
        <Button
          p={0}
          w={'100%'}
          classNames={{
            inner: classes.listHeaderButton,
          }}
          leftSection={<IconArrowLeft />}
          variant={'transparent'}
          c={'text-primary'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (parentFolderId) onFolderNavigation();
          }}
        >
          {parentFolderId ? currentFolder.name : tenantName}
        </Button>
      )}

      {currentFolder.children?.length || currentFolder.projects?.length ? (
        <PopulatedFolderList currentFolder={currentFolder} onFolderNavigation={onFolderNavigation} />
      ) : (
        <Box className={classes.emptyList}>
          <IconFoldersOff size={'54'} />
          <Text mt={10}>Folder is empty</Text>
        </Box>
      )}
    </Stack>
  );
};
