export type ColorCode = { hex: string; dec: number };

export type PartColor = {
  name: string;
  code: ColorCode;
};

export const ASSEMBLIO_PART_COLORS: Array<PartColor> = [
  { name: 'pink', code: { hex: '#E64980', dec: 15092096 } },
  { name: 'red', code: { hex: '#FA5252', dec: 16405074 } },
  { name: 'blue', code: { hex: '#228be6', dec: 2264038 } },
  { name: 'indigo', code: { hex: '#4c6ef5', dec: 5009141 } },
  { name: 'grape', code: { hex: '#BE4BDB', dec: 12471259 } },
  { name: 'lime', code: { hex: '#82c91e', dec: 8571166 } },
];

export const COLOR_REEL: Array<Array<PartColor>> = [
  [
    { name: 'white', code: { hex: '#FFFFFF', dec: 16777215 } },
    { name: 'white-25', code: { hex: '#d9d9d9', dec: 14277081 } },
    { name: 'white-50', code: { hex: '#bfbfbf', dec: 12566463 } },
    { name: 'white-70', code: { hex: '#a6a6a6', dec: 10921638 } },
    { name: 'white-80', code: { hex: '#7f7f7f', dec: 8355711 } },
  ],
  [
    { name: 'black', code: { hex: '#030303', dec: 197379 } },
    { name: 'black-15', code: { hex: '#7f7f7f', dec: 8355711 } },
    { name: 'black-25', code: { hex: '#595959', dec: 5855577 } },
    { name: 'black-50', code: { hex: '#404040', dec: 4210752 } },
    { name: 'black-70', code: { hex: '#262626', dec: 2500134 } },
  ],
  [
    { name: 'blue', code: { hex: '#4472c4', dec: 4485828 } },
    { name: 'blue-25', code: { hex: '#b4c7e7', dec: 11847655 } },
    { name: 'blue-50', code: { hex: '#8faadc', dec: 9415388 } },
    { name: 'blue-70', code: { hex: '#2f5597', dec: 3102103 } },
    { name: 'blue-80', code: { hex: '#203864', dec: 2111588 } },
  ],
  [
    { name: 'orange', code: { hex: '#ed7d31', dec: 15564081 } },
    { name: 'orange-25', code: { hex: '#f8cbad', dec: 16305069 } },
    { name: 'orange-50', code: { hex: '#f4b183', dec: 16036227 } },
    { name: 'orange-70', code: { hex: '#c55a11', dec: 12933649 } },
    { name: 'orange-80', code: { hex: '#843c0c', dec: 8666124 } },
  ],
  [
    { name: 'gold', code: { hex: '#ffc000', dec: 16760832 } },
    { name: 'gold-25', code: { hex: '#ffe699', dec: 16770713 } },
    { name: 'gold-50', code: { hex: '#ffd966', dec: 16767334 } },
    { name: 'gold-70', code: { hex: '#bf9000', dec: 12554240 } },
    { name: 'gold-80', code: { hex: '#7f6000', dec: 8347648 } },
  ],
  [
    { name: 'green', code: { hex: '#70ad47', dec: 7384391 } },
    { name: 'green-25', code: { hex: '#c5e0b4', dec: 12968116 } },
    { name: 'green-50', code: { hex: '#a9d18e', dec: 11129230 } },
    { name: 'green-70', code: { hex: '#548235', dec: 5538357 } },
    { name: 'green-80', code: { hex: '#385723', dec: 3692323 } },
  ],
];
