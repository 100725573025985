import { Menu, Tooltip, Text, MenuItemProps, Combobox, ComboboxOptionProps, Group, TextProps } from '@mantine/core';
import { ComponentType } from 'react';
import { IconProps } from '@tabler/icons-react';
import classes from './ContextMenuItem.module.scss';

interface ContextMenuItemProps {
  label: string;
  onClick?: () => void;
  LeftIcon?: ComponentType<IconProps>;
  RightIcon?: ComponentType<IconProps>;
  tooltipLabel?: string;
  textProps?: TextProps;
}

const MENU_ICON_SIZE = 16;

export const ContextMenuItem = ({
  label,
  disabled = false,
  tooltipLabel = undefined,
  LeftIcon,
  RightIcon,
  rightSection,
  leftSection,
  onClick,
  ...props
}: ContextMenuItemProps & MenuItemProps) => {
  return (
    <Tooltip disabled={typeof tooltipLabel === 'undefined'} openDelay={500} label={tooltipLabel}>
      <Menu.Item
        {...props}
        classNames={{
          item: classes.item,
          itemSection: classes.itemSection,
        }}
        onClick={(event) => {
          if (!onClick) return;
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }}
        leftSection={LeftIcon ? <LeftIcon size={MENU_ICON_SIZE} /> : leftSection}
        rightSection={RightIcon ? <RightIcon size={MENU_ICON_SIZE} /> : rightSection}
        disabled={disabled}
      >
        {label}
      </Menu.Item>
    </Tooltip>
  );
};

export const DrowpDownItem = ({
  label,
  tooltipLabel = undefined,
  LeftIcon,
  RightIcon,
  onClick,
  textProps,
  ...props
}: ContextMenuItemProps & ComboboxOptionProps) => {
  return (
    <Tooltip disabled={typeof tooltipLabel === 'undefined'} openDelay={500} label={tooltipLabel}>
      <Combobox.Option
        {...props}
        className={classes.item}
        data-leftSection={Boolean(LeftIcon)}
        onClick={(event) => {
          if (!onClick) return;
          event.preventDefault();
          event.stopPropagation();
          onClick();
        }}
      >
        <Group className={classes.dropdown_item_group} gap={'xs'}>
          {LeftIcon && <LeftIcon size={MENU_ICON_SIZE} />}
          <Text size={'sm'} {...textProps}>
            {label}
          </Text>
        </Group>
      </Combobox.Option>
    </Tooltip>
  );
};
