export const IconSearchResultProduct = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_208_119442)">
      <path
        d="M4 2.33333C4.17681 2.33333 4.34638 2.2631 4.4714 2.13807C4.59643 2.01305 4.66667 1.84348 4.66667 1.66667V0.666667C4.66667 0.489856 4.59643 0.320286 4.4714 0.195262C4.34638 0.0702379 4.17681 1.98679e-08 4 1.98679e-08C3.82319 1.98679e-08 3.65362 0.0702379 3.5286 0.195262C3.40357 0.320286 3.33333 0.489856 3.33333 0.666667V1.66667C3.33333 1.84348 3.40357 2.01305 3.5286 2.13807C3.65362 2.2631 3.82319 2.33333 4 2.33333Z"
        fill="currentColor"
      />
      <path
        d="M1.66667 3.33333H0.666667C0.489856 3.33333 0.320286 3.40357 0.195262 3.5286C0.0702379 3.65362 1.98679e-08 3.82319 1.98679e-08 4C1.98679e-08 4.17681 0.0702379 4.34638 0.195262 4.4714C0.320286 4.59643 0.489856 4.66667 0.666667 4.66667H1.66667C1.84348 4.66667 2.01305 4.59643 2.13807 4.4714C2.2631 4.34638 2.33333 4.17681 2.33333 4C2.33333 3.82319 2.2631 3.65362 2.13807 3.5286C2.01305 3.40357 1.84348 3.33333 1.66667 3.33333Z"
        fill="currentColor"
      />
      <path
        d="M1.66667 11.3333H0.666667C0.489856 11.3333 0.320286 11.4036 0.195262 11.5286C0.0702379 11.6536 0 11.8232 0 12C0 12.1768 0.0702379 12.3464 0.195262 12.4714C0.320286 12.5964 0.489856 12.6667 0.666667 12.6667H1.66667C1.84348 12.6667 2.01305 12.5964 2.13807 12.4714C2.2631 12.3464 2.33333 12.1768 2.33333 12C2.33333 11.8232 2.2631 11.6536 2.13807 11.5286C2.01305 11.4036 1.84348 11.3333 1.66667 11.3333Z"
        fill="currentColor"
      />
      <path
        d="M12 0C11.8232 0 11.6536 0.0702379 11.5286 0.195262C11.4036 0.320286 11.3333 0.489856 11.3333 0.666667V1.66667C11.3333 1.84348 11.4036 2.01305 11.5286 2.13807C11.6536 2.2631 11.8232 2.33333 12 2.33333C12.1768 2.33333 12.3464 2.2631 12.4714 2.13807C12.5964 2.01305 12.6667 1.84348 12.6667 1.66667V0.666667C12.6667 0.489856 12.5964 0.320286 12.4714 0.195262C12.3464 0.0702379 12.1768 0 12 0Z"
        fill="currentColor"
      />
      <path
        d="M4 13.6667C3.82319 13.6667 3.65362 13.7369 3.5286 13.8619C3.40357 13.987 3.33333 14.1565 3.33333 14.3333V15.3333C3.33333 15.5101 3.40357 15.6797 3.5286 15.8047C3.65362 15.9298 3.82319 16 4 16C4.17681 16 4.34638 15.9298 4.4714 15.8047C4.59643 15.6797 4.66667 15.5101 4.66667 15.3333V14.3333C4.66667 14.1565 4.59643 13.987 4.4714 13.8619C4.34638 13.7369 4.17681 13.6667 4 13.6667Z"
        fill="currentColor"
      />
      <path
        d="M12 13.6667C11.8232 13.6667 11.6536 13.7369 11.5286 13.8619C11.4036 13.987 11.3333 14.1565 11.3333 14.3333V15.3333C11.3333 15.5101 11.4036 15.6797 11.5286 15.8047C11.6536 15.9298 11.8232 16 12 16C12.1768 16 12.3464 15.9298 12.4714 15.8047C12.5964 15.6797 12.6667 15.5101 12.6667 15.3333V14.3333C12.6667 14.1565 12.5964 13.987 12.4714 13.8619C12.3464 13.7369 12.1768 13.6667 12 13.6667Z"
        fill="currentColor"
      />
      <path
        d="M14.3333 4.66667H15.3333C15.5101 4.66667 15.6797 4.59643 15.8047 4.4714C15.9298 4.34638 16 4.17681 16 4C16 3.82319 15.9298 3.65362 15.8047 3.5286C15.6797 3.40357 15.5101 3.33333 15.3333 3.33333H14.3333C14.1565 3.33333 13.987 3.40357 13.8619 3.5286C13.7369 3.65362 13.6667 3.82319 13.6667 4C13.6667 4.17681 13.7369 4.34638 13.8619 4.4714C13.987 4.59643 14.1565 4.66667 14.3333 4.66667Z"
        fill="currentColor"
      />
      <path
        d="M15.3333 11.3333H14.3333C14.1565 11.3333 13.987 11.4036 13.8619 11.5286C13.7369 11.6536 13.6667 11.8232 13.6667 12C13.6667 12.1768 13.7369 12.3464 13.8619 12.4714C13.987 12.5964 14.1565 12.6667 14.3333 12.6667H15.3333C15.5101 12.6667 15.6797 12.5964 15.8047 12.4714C15.9298 12.3464 16 12.1768 16 12C16 11.8232 15.9298 11.6536 15.8047 11.5286C15.6797 11.4036 15.5101 11.3333 15.3333 11.3333Z"
        fill="currentColor"
      />
      <path
        d="M8.33333 7.5H10C10 7.5 10.1667 7.5 10.1667 7.66667V10C10.1667 10 10.1667 10.1667 10 10.1667H8.33333C8.33333 10.1667 8.16667 10.1667 8.16667 10V7.66667C8.16667 7.66667 8.16667 7.5 8.33333 7.5Z"
        fill="currentColor"
      />
      <path
        d="M7.66667 6.5C7.71087 6.5 7.75326 6.48244 7.78452 6.45118C7.81577 6.41993 7.83333 6.37754 7.83333 6.33333V6C7.83333 5.9558 7.81577 5.9134 7.78452 5.88215C7.75326 5.85089 7.71087 5.83333 7.66667 5.83333H6C5.9558 5.83333 5.9134 5.85089 5.88215 5.88215C5.85089 5.9134 5.83333 5.9558 5.83333 6V8.33333C5.83333 8.37754 5.85089 8.41993 5.88215 8.45118C5.9134 8.48244 5.9558 8.5 6 8.5H7C7.0442 8.5 7.0866 8.48244 7.11785 8.45118C7.14911 8.41993 7.16667 8.37754 7.16667 8.33333V7C7.16839 6.86793 7.22163 6.74175 7.31502 6.64836C7.40842 6.55496 7.5346 6.50173 7.66667 6.5Z"
        fill="currentColor"
      />
      <path
        d="M12 3.33333H4C3.82319 3.33333 3.65362 3.40357 3.5286 3.5286C3.40357 3.65362 3.33333 3.82319 3.33333 4V12C3.33333 12.1768 3.40357 12.3464 3.5286 12.4714C3.65362 12.5964 3.82319 12.6667 4 12.6667H12C12.1768 12.6667 12.3464 12.5964 12.4714 12.4714C12.5964 12.3464 12.6667 12.1768 12.6667 12V4C12.6667 3.82319 12.5964 3.65362 12.4714 3.5286C12.3464 3.40357 12.1768 3.33333 12 3.33333ZM10.6667 6.5C10.7987 6.50173 10.9249 6.55496 11.0183 6.64836C11.1117 6.74175 11.1649 6.86793 11.1667 7V10.6667C11.1649 10.7987 11.1117 10.9249 11.0183 11.0183C10.9249 11.1117 10.7987 11.1649 10.6667 11.1667H7.66667C7.53459 11.1649 7.40842 11.1117 7.31502 11.0183C7.22163 10.9249 7.16839 10.7987 7.16667 10.6667V9.66667C7.16667 9.64478 7.16236 9.62311 7.15398 9.60289C7.1456 9.58267 7.13333 9.56429 7.11785 9.54882C7.10238 9.53334 7.084 9.52106 7.06378 9.51269C7.04356 9.50431 7.02189 9.5 7 9.5H5.33333C5.20126 9.49827 5.07509 9.44504 4.98169 9.35164C4.88829 9.25825 4.83506 9.13207 4.83333 9V5.33333C4.83506 5.20126 4.88829 5.07509 4.98169 4.98169C5.07509 4.88829 5.20126 4.83506 5.33333 4.83333H8.33333C8.4654 4.83506 8.59158 4.88829 8.68498 4.98169C8.77837 5.07509 8.83161 5.20126 8.83333 5.33333V6.33333C8.83333 6.37754 8.85089 6.41993 8.88215 6.45118C8.9134 6.48244 8.9558 6.5 9 6.5H10.6667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_208_119442">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
