import { ActionIcon, Box, Checkbox, Collapse, Group, Stack, Text } from '@mantine/core';
import classes from '../ExportModal.module.scss';
import { StepGroupSelection } from '../types/export-modal.types';
import { useDisclosure } from '@mantine/hooks';
import { IconCaretDown, IconCaretUp } from '@assemblio/design-system';
interface SelectableSequenceProps {
  stepGroups: {
    id: string;
    name: string;
  }[];
  selection: StepGroupSelection;
  onStepGroupSelection: (stepGroupId: string) => unknown;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

export const SelectableSequence = ({
  stepGroups,
  onSelectAll,
  onDeselectAll,
  onStepGroupSelection,
  selection,
}: SelectableSequenceProps) => {
  const [open, { toggle }] = useDisclosure(true);
  const allStepGroupsSelected = selection.every((sG) => sG.selected);

  return (
    <Box className={classes.sequence}>
      <Group gap={0}>
        <ActionIcon c={'text-tertiary'} size={'xs'} variant={'transparent'} onClick={toggle}>
          {open ? <IconCaretDown /> : <IconCaretUp />}
        </ActionIcon>
        <Checkbox
          size={'sm'}
          mx={'sm'}
          checked={allStepGroupsSelected}
          onChange={() => {
            allStepGroupsSelected ? onDeselectAll() : onSelectAll();
          }}
        />
        <Text size={'sm'}>All Step Groups</Text>
      </Group>
      <Collapse pl={'xl'} pt={'lg'} in={open}>
        <Stack gap={'md'} ml={'xl'}>
          {stepGroups.map((sG) => {
            return (
              <Group
                onClick={() => {
                  onStepGroupSelection(sG.id);
                }}
                key={sG.id}
                className={classes.sequence__stepGroup}
              >
                <Checkbox
                  size={'sm'}
                  checked={selection.find((sg) => sg.stepGroupId === sG.id)?.selected ?? false}
                  readOnly
                />
                <Text size={'sm'}>{sG.name}</Text>
              </Group>
            );
          })}
        </Stack>
      </Collapse>
    </Box>
  );
};
