import { Button, Divider, Group, Stack, Text } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { SyncProfilePairs } from '@assemblio/shared/next-types';
import { SearchField } from '@assemblio/design-system';
import { useV1InstructionSyncResolve } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../../../hooks/ExplorerRouteParams.hook';
import { useDebouncedValue } from '@mantine/hooks';
import { SyncProfilePairEditTable, UpdatedSyncPair, transformPairs } from '../../../../components';

interface ResolveSyncIssuesContentProps {
  syncProfilePairs: SyncProfilePairs | undefined;
  sourceId: string;
  targetId: string;
  isLoading?: boolean;
  onClose: () => void;
}

export const ResolveSyncIssuesModalContent = ({
  syncProfilePairs,
  targetId,
  sourceId,
  isLoading = false,
  onClose,
}: ResolveSyncIssuesContentProps) => {
  const { productId } = useExplorerRouteParams();

  const [updatedPairs, setUpdatedPairs] = useState<UpdatedSyncPair[] | undefined>(transformPairs(syncProfilePairs));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
  const resolveMutation = useV1InstructionSyncResolve();

  const replacedPairsCount = useMemo(
    () => updatedPairs?.filter((pair) => pair.state === 'Replace').length,
    [updatedPairs]
  );

  const handleSync = () => {
    if (!sourceId || !targetId) return;
    if (updatedPairs) {
      resolveMutation.mutate({
        sourceId: sourceId,
        targetId: targetId,
        productId,
        resolve: {
          keep: updatedPairs.filter((pair) => pair.state === 'Keep').map((pair) => pair.pair),
          replace: updatedPairs.filter((pair) => pair.state === 'Replace').map((pair) => pair.pair),
        },
      });
      notifications.show({
        id: 'sync-resolve-success',
        message: 'Synchronization Issues resolved',
        color: 'green',
      });
      handleClose();
    }
  };

  const getSimilarPartsNotice = () => {
    const partCount = syncProfilePairs?.unresolved?.length || 0;
    let partPhrase: string;

    switch (partCount) {
      case 0:
        partPhrase = `are ${partCount} parts yet that are`;
        break;
      case 1:
        partPhrase = `is ${partCount} part that is`;
        break;
      default:
        partPhrase = `are ${partCount} parts that are`;
        break;
    }
    return (
      <Text>There {partPhrase} similar to each other and the step information has not been synchronized yet.</Text>
    );
  };

  useEffect(() => {
    if (!syncProfilePairs) return;
    if (!updatedPairs) {
      setUpdatedPairs(transformPairs(syncProfilePairs));
    }
  }, [syncProfilePairs]);

  const handleClose = () => {
    setUpdatedPairs([]);
    onClose();
  };

  return (
    <Stack
      style={{
        overflow: 'hidden',
      }}
      gap="lg"
      mb={'md'}
    >
      <Stack gap="md" px={'lg'}>
        <Text>
          During the synchronisation {syncProfilePairs?.replace?.length ?? 0} identical parts were found and the
          corresponding step information can be applied.
        </Text>
        <Text>{getSimilarPartsNotice()}</Text>
        <Text variant={'medium'}>Select which of these parts should be replaced.</Text>
      </Stack>

      <SearchField
        px={'lg'}
        placeholder={'Search'}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
      />

      <Stack px={'lg'}>
        <Group justify={'space-between'}>
          <Text size={'sm'} variant={'medium'}>
            Select items that you want to replace
          </Text>
          <Text size={'sm'} c={'text-secondary'}>
            {replacedPairsCount} out of {updatedPairs?.length} selected
          </Text>
        </Group>

        <SyncProfilePairEditTable onChange={setUpdatedPairs} pairs={updatedPairs} searchString={debouncedSearchTerm} />
      </Stack>
      <Divider mb="sm" />
      <Group justify="end" pr={'md'}>
        <Button variant="secondary">Cancel</Button>
        <Button variant={'primary'} onClick={handleSync}>
          Synchronise
        </Button>
      </Group>
    </Stack>
  );
};
