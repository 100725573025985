import React, { useEffect } from 'react';
import { UnstyledButton, Group, Avatar, Text } from '@mantine/core';
import { IconKey } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './AdminSidebarButton.module.scss';
import { AppRole } from '@assemblio/type/user';
import { useModalContext } from '@assemblio/frontend/components';
import { useAdminSettings } from './hooks/adminSettings.hook';

interface AdminSidebarButtonProps {
  collapsed: boolean;
  appRole: AppRole;
}

export const AdminSidebarButton = ({ collapsed, appRole }: AdminSidebarButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModalContext();

  const settingsOpen = useAdminSettings();

  const openAdminSettings = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('administration', 'open');
    navigate({ search: `?${searchParams.toString()}` }, { replace: false });
    openModal('admin-settings', {});
  };

  const handleCloseModal = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('administration');
    navigate({ search: `?${searchParams.toString()}` }, { replace: true });
    closeModal();
  };

  const handleToggleAdminSettings = () => {
    settingsOpen ? handleCloseModal() : openAdminSettings();
  };

  useEffect(() => {
    if (settingsOpen) {
      openAdminSettings();
    } else {
      closeModal();
    }
  }, [settingsOpen]);

  return (
    <Group className={`${classes.container} ${collapsed ? classes.collapsed : ''}`}>
      {collapsed ? (
        <Avatar color={'white'} bg={'black'}>
          <IconKey size={20} />
        </Avatar>
      ) : (
        <UnstyledButton onClick={handleToggleAdminSettings} className={classes.button}>
          <Group>
            <Avatar color={'white'} bg={'black'}>
              <IconKey size={20} />
            </Avatar>
            <Text>Administration</Text>
          </Group>
        </UnstyledButton>
      )}
    </Group>
  );
};
