import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  downloadGLTF,
  fetchFavoriteProducts,
  fetchInstruction,
  fetchInstructionParts,
  fetchInstructionRevisions,
  fetchInstructionState,
  fetchInstructionVersions,
  fetchProductContent,
  fetchProductView, fetchRecentProducts,
  fetchSequencesForExport,
  fetchStepGroups
} from './instruction.requests';
import { ExportModalQuery, FetchProductView, InstructionSequenceQuery } from './types/instruction.request.types';
import { ProductQueryDto } from '@assemblio/shared/dtos';

export const useInstructionPartsQuery = (instructionId?: string) => {
  return useQuery(
    ['instruction-parts', instructionId],
    () => {
      return fetchInstructionParts(instructionId);
    },
    {
      enabled: Boolean(instructionId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInstructionFileQuery = (fileId?: string) => {
  return useQuery(
    ['instruction-file', fileId],
    () => {
      return downloadGLTF(fileId);
    },
    { enabled: Boolean(fileId), cacheTime: 0, refetchOnWindowFocus: false }
  );
};

export const useStepGroupsQuery = (query: InstructionSequenceQuery) => {
  return useQuery(
    ['instruction-sequence-step-groups', query.sequenceId],
    () => {
      return fetchStepGroups(query);
    },
    {
      enabled: Boolean(query.sequenceId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInstructionQuery = (instructionId: string | undefined) => {
  return useQuery(
    ['instruction', instructionId],
    () => {
      return fetchInstruction(instructionId);
    },
    {
      enabled: Boolean(instructionId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useProductViewQuery = (data: FetchProductView) => {
  return useQuery(
    ['product-view', data.productId],
    () => {
      return fetchProductView(data);
    },
    {
      enabled: Boolean(data.productId),
      cacheTime: 0,
      retry: false,
    }
  );
};

export const useProductOverviewQuery = (productId: string | undefined) => {
  return useQuery(
    ['product', productId],
    () => {
      return fetchProductContent(productId);
    },
    {
      enabled: Boolean(productId),
      cacheTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export const useFetchRevisions = (instructionId: string | undefined) => {
  return useQuery(['instruction-revisions', instructionId], () => {
    return fetchInstructionRevisions(instructionId);
  });
};

export const useFetchVersions = (instructionId: string | undefined) => {
  return useQuery(['instruction-versions', instructionId], () => {
    return fetchInstructionVersions(instructionId);
  });
};

export const useSequencesForExportQuery = (query: ExportModalQuery) => {
  return useQuery(
    ['export-sequences', query.instructionId],
    () => {
      return fetchSequencesForExport(query.instructionId);
    },
    {
      enabled: Boolean(query.instructionId) && query.modalOpened,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export const useInstructionStateQuery = (instructionId: string, projectId: string, enabled: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['instruction-state', instructionId],
    () => {
      return fetchInstructionState(instructionId, projectId, queryClient);
    },
    {
      enabled,
      refetchInterval: 5000,
    }
  );
};

export const useFavoriteProductsQuery = (data?: ProductQueryDto) => {
  return useQuery(
    ['favorite-products'],
    () => {
      return fetchFavoriteProducts(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};


export const useRecentProductsQuery = (data?: ProductQueryDto) => {
  return useQuery(
    ['recent-products'],
    () => {
      return fetchRecentProducts(data);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
