import { ModelVisibilityController, useUIStore } from '@assemblio/frontend/stores';
import { Assembly, Part } from '@assemblio/type/input';
import { Group, Tooltip } from '@mantine/core';
import { LightWeightIcon } from './LightWeightIcon';
import classes from './TreeItem.module.scss';
import cx from 'clsx';
import { IconInvisible, IconOpacity, IconVisible } from '@assemblio/design-system';

interface Props {
  isLeaf: boolean;
  assembly: Assembly | Part;
  visible: boolean;
  transparent: boolean;
}

export const TreeItemActions = (props: Props) => {
  const isAnimating = useUIStore((state) => state.isAnimating);

  return (
    <Group wrap="nowrap" gap={5}>
      <Tooltip
        label={props.transparent ? 'Display object non-transparently' : 'Display object transparently'}
        openDelay={2000}
      >
        <LightWeightIcon
          className={cx(classes.tree_action_item, {
            [classes.tree_action_item__active]: props.transparent,
          })}
          disabled={isAnimating}
          onClick={() => onChangeTransparency(props.isLeaf, props.assembly, !props.transparent)}
        >
          <IconOpacity />
        </LightWeightIcon>
      </Tooltip>
      <Tooltip label={props.visible ? 'Hide object' : 'Display object'} openDelay={2000}>
        <LightWeightIcon
          className={cx(classes.tree_action_item, {
            [classes.tree_action_item__active]: !props.visible,
          })}
          disabled={isAnimating}
          onClick={() => onChangeVisiblity(props.isLeaf, props.assembly, !props.visible)}
        >
          {props.visible ? <IconVisible /> : <IconInvisible />}
        </LightWeightIcon>
      </Tooltip>

      {/*{props.isLeaf ? (*/}
      {/*  <LightWeightIcon*/}
      {/*    className={classes.tree_action_item}*/}
      {/*    disabled={isAnimating}*/}
      {/*    onClick={() => {*/}
      {/*      if (hasPath) ModelController.selectNextStepByGltfIndex(props.assembly.gltfIndex);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <IconArrowsJoin style={{ width: '100%', height: '100%' }} />*/}
      {/*  </LightWeightIcon>*/}
      {/*) : (*/}
      {/*  <div className={classes.tree_action_item}></div>*/}
      {/*)}*/}
    </Group>
  );
};

const onChangeVisiblity = (isLeaf: boolean, data: Assembly | Part, visible: boolean) => {
  if (isLeaf) ModelVisibilityController.setPartVisible(data.gltfIndex, visible, true);
  else ModelVisibilityController.setAssemblyVisible(data.gltfIndex, visible, true);
};

const onChangeTransparency = (isLeaf: boolean, data: Assembly | Part, transparent: boolean) => {
  if (isLeaf) ModelVisibilityController.setPartTransparent(data.gltfIndex, transparent);
  else ModelVisibilityController.setAssemblyTransparent(data.gltfIndex, transparent);
};
