import { PathType } from '@assemblio/type/path';
import { useItemPathQuery } from '@assemblio/frontend/data-access';
import { useExplorerRouteParams } from '../../../../hooks/ExplorerRouteParams.hook';
import { BreadCrumbList } from './components/BreadcrumbList';
import { PathElementDto } from '@assemblio/shared/dtos';
import { BreadcrumbItemType } from './types/BreadcrumbNavigation.types';
import { BreadcrumbSkeleton } from './components/BreadcrumbSkeleton';
import { useLocation } from 'react-router-dom';

const INDEX_ROUTES = ['/dashboard', '/explorer/favorites', '/explorer/recent'];

export const BreadcrumbNavigation = () => {
  const { projectId, productId, folderId, rootFolderId } = useExplorerRouteParams();
  const { pathname } = useLocation();

  const getPathType = (folderId: string | undefined, projectId: string | undefined, productId: string | undefined) => {
    if (productId) return PathType.product;
    if (projectId) return PathType.project;
    if (folderId) return PathType.folder;
    return PathType.folder;
  };

  const itemPathQuery = useItemPathQuery(
    projectId || productId || folderId || rootFolderId,
    getPathType(folderId ?? rootFolderId, projectId, productId)
  );

  if (INDEX_ROUTES.includes(pathname)) return null;

  const getHref = (item: PathElementDto) => {
    if (item.type === PathType.folder) return item.id === rootFolderId ? '/explorer' : `/explorer/${item.id}`;
    return `/explorer/${item.type}/${item.id}`;
  };

  const reversed: BreadcrumbItemType[] = [...(itemPathQuery.data ?? [])].reverse().map((item) => {
    return {
      name: item.name,
      navigationTarget: getHref(item),
    };
  });

  return itemPathQuery.isLoading ? <BreadcrumbSkeleton /> : <BreadCrumbList items={reversed} />;
};
