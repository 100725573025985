import { ItemCardProps } from '../ItemCard/ItemCard';
import { Button, Group, Skeleton, Table, ThemeIcon, Text, Center, Loader } from '@mantine/core';
import { IconFolderFilled } from '@tabler/icons-react';
import { ItemContextMenu } from '../ItemContextMenu/ItemContextMenu';
import { useState } from 'react';
import classes from './ItemRow.module.scss';
import { IconProject } from '@assemblio/design-system';

export const ItemRow = ({ item, onClick }: ItemCardProps) => {
  const { id, name, subItemCount, type } = item;
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Table.Tr className={classes.row}>
      <Table.Td>
        {isLoading && (
          <div className={classes.loading_overlay}>
            <Center h={'100%'}>
              <Loader />
            </Center>
          </div>
        )}
        <Group>
          <ThemeIcon size={'sm'} c={'text-primary'} variant={'transparent'}>
            {type === 'Project' ? <IconProject /> : <IconFolderFilled />}
          </ThemeIcon>

          <Text lineClamp={1} variant={'medium'}>
            {name}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>
        <Text lineClamp={1} variant={'medium'}>
          {subItemCount} {type === 'Project' ? 'Products' : 'Projects'}
        </Text>
      </Table.Td>
      <Table.Td>
        <Group wrap={'nowrap'} justify={'end'} align={'center'}>
          <Button onClick={() => onClick(id)} variant={'secondary'}>
            Open
          </Button>
          <ItemContextMenu item={item} setLoading={setIsLoading} />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};

export const ItemRowSkeleton = () => (
  <Table.Tr>
    <Table.Td>
      <Skeleton h={'2rem'} w={'15rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td />
    <Table.Td />
  </Table.Tr>
);
