import { Text, Stack, Group } from '@mantine/core';
import { useMantineColorScheme } from '@mantine/core';
import { ThemeBox } from './ThemeBox';

export const AppearanceMode = () => {
  const { setColorScheme, colorScheme } = useMantineColorScheme();

  return (
    <Stack>
      <Text variant={'medium'} size={'md'}>
        Appearance
      </Text>
      <Group>
        <ThemeBox
          image={'assets/editor/light-mode.png'}
          altText="Light Mode"
          selected={colorScheme === 'light'}
          onSelect={() => setColorScheme('light')}
          label="Light Mode"
        />
        <ThemeBox
          image={'assets/editor/dark-mode.png'}
          altText="Dark Mode"
          selected={colorScheme === 'dark'}
          onSelect={() => setColorScheme('dark')}
          label="Dark Mode"
        />
        <ThemeBox
          image={'assets/editor/system-preference.png'}
          altText="System Preference"
          selected={colorScheme === 'auto'}
          onSelect={() => setColorScheme('auto')}
          label="System Preferences"
        />
      </Group>
    </Stack>
  );
};
