import { Button, Stack, Text } from '@mantine/core';
import { IconError404 } from '@tabler/icons-react';
import classes from './ErrorScreen.module.scss';

export const ErrorScreen = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Stack align="center" justify="center" className={classes.container}>
      <IconError404 size={80} />
      <Text size={'lg'} variant={'medium'} c={'text-secondary'}>
        Something went wrong
      </Text>
      <Button onClick={handleReload} variant={'primary'}>
        Reload Page
      </Button>
    </Stack>
  );
};
