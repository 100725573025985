export const IconAddAnnotation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85715 0C2.4025 0 1.96646 0.180611 1.64497 0.502103C1.32349 0.823593 1.14287 1.25963 1.14287 1.71429V11.9073L0.0293356 15.2479C-0.036617 15.4457 0.010464 15.6638 0.15217 15.8168C0.293877 15.9698 0.507698 16.0335 0.710032 15.983L5.21321 14.8571H14.2857C14.7403 14.8571 15.1765 14.6766 15.4979 14.3551C15.8194 14.0336 16 13.5975 16 13.1429V1.71429C16 1.25962 15.8194 0.823593 15.4979 0.502103C15.1765 0.180611 14.7403 0 14.2857 0H2.85715ZM8.57143 3.14286C9.04481 3.14286 9.42857 3.52662 9.42857 4V6.57143H12C12.4734 6.57143 12.8571 6.95519 12.8571 7.42857C12.8571 7.90195 12.4734 8.28571 12 8.28571H9.42857V10.8571C9.42857 11.3305 9.04481 11.7143 8.57143 11.7143C8.09805 11.7143 7.71429 11.3305 7.71429 10.8571V8.28571H5.14286C4.66947 8.28571 4.28571 7.90195 4.28571 7.42857C4.28571 6.95519 4.66947 6.57143 5.14286 6.57143H7.71429V4C7.71429 3.52662 8.09805 3.14286 8.57143 3.14286Z"
        fill="currentColor"
      />
    </svg>
  );
};
