import { UIController } from '@assemblio/frontend/stores';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconFocusCentered } from '@tabler/icons-react';
import classes from './Viewport.module.scss';

export const CameraCenterButton = () => {
  return (
    <Tooltip offset={8} position="bottom" label="Center Camera on Selection">
      <ActionIcon
        className={classes.controls__icon}
        c={'text-secondary'}
        size="sm"
        variant="transparent"
        onClick={() => UIController.centerCameraOnSelection()}
      >
        <IconFocusCentered />
      </ActionIcon>
    </Tooltip>
  );
};
