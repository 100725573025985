import { MantineSize, Text, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from 'react';
import classes from '../../Sequencer.module.scss';

export interface ToggableTextInputProps {
  id: string;
  name: string;
  disabled: boolean;
  size?: string | MantineSize;
  selected?: boolean;
  isToggable?: boolean;
  onNameChange: (id: string, oldName: string, newName: string) => void;
}

const ToggableTextInput = ({
  id,
  name,
  disabled,
  selected = false,
  size,
  isToggable = true,
  onNameChange,
}: ToggableTextInputProps) => {
  const theme = useMantineTheme();
  const [nameToggle, setNameToggle] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const focusTrapRef = useFocusTrap();
  const mergedRef = useMergedRef(nameRef, focusTrapRef);

  useEffect(() => {
    if (nameRef.current && nameToggle) {
      nameRef.current.value = name;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameToggle]);

  const handleNameChange = useCallback(() => {
    if (nameRef && nameRef.current) {
      const newName = nameRef.current.value.trim();
      if ((newName.length > 0 && newName.length <= 255) || newName === name) {
        onNameChange(id, name, newName);
      } else {
        notifications.show({
          id: 'update-name-error',
          message: 'Names must have at least 1 and less than 255 characters',
          color: 'red',
        });
      }
    }
    setNameToggle(false);
  }, [id, onNameChange, name]);

  return (
    <div
      data-cy="toggable-text-input"
      style={{ flex: '1 1 auto', userSelect: 'none' }}
      onDoubleClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!disabled) {
          setNameToggle(true);
        }
      }}
    >
      {nameToggle && isToggable ? (
        <TextInput
          size="sm"
          className={classes.toggableTextInput__textInput}
          variant="unstyled"
          disabled={disabled}
          ref={mergedRef}
          onBlur={() => {
            handleNameChange();
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleNameChange();
              event.preventDefault();
              event.stopPropagation();
            } else if (event.key === 'Escape') {
              setNameToggle(false);
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      ) : (
        <Tooltip offset={10} multiline label={name} openDelay={500}>
          <Text size={size || 'sm'} variant={'medium'} data-selected={selected} lineClamp={1}>
            {name}
          </Text>
        </Tooltip>
      )}
    </div>
  );
};

export default ToggableTextInput;
