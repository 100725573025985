import { useUIStore } from '@assemblio/frontend/stores';
import { Divider, Flex, Group } from '@mantine/core';
import { shallow } from 'zustand/shallow';
import { CameraCenterButton } from './CameraCenterButton';
import { TransformActionSwitch } from './TransformActionSwitch';
import { AnnotationChooser } from './UI/AnnotationChooser';
import { ColorPicker } from './UI/ColorPicker';
import { PreviewSwitch } from './PreviewSwitch';
import { RotationSnapSettings } from './RotationSnapSettings';
import { SidebarTextRendererButton } from './SidebarTextRendererButton';
import classes from './Viewport.module.scss';

export const ViewportControls = () => {
  const { cameraControlsActive, toolBarActive, stepSelected } = useUIStore(
    (state) => ({
      cameraControlsActive: state.viewportControlsActive,
      toolBarActive: state.toolbarActive,
      stepSelected: state.selectedStep !== undefined,
    }),
    shallow
  );

  return (
    <Flex mt={'xl'} justify={'center'}>
      <Group p={'xs'} gap="xs" align="center" mr={'xs'} className={classes.controls}>
        {cameraControlsActive && (
          <>
            {/* <TransformModeSwitch /> */}
            <CameraCenterButton />
            <Divider my={'xs'} orientation="vertical" />
            <TransformActionSwitch />
            <Divider my={'xs'} orientation="vertical" />
            <RotationSnapSettings />
            <Divider my={'xs'} orientation="vertical" />
            <PreviewSwitch />
            <Divider my={'xs'} orientation="vertical" />
            <ColorPicker />
            <Divider orientation="vertical" />
          </>
        )}

        <SidebarTextRendererButton />
        {toolBarActive && stepSelected && <AnnotationChooser />}
      </Group>
    </Flex>
  );
};
