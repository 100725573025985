import { Badge, BadgeProps } from '@mantine/core';
import classes from './Tag.module.scss';
import { PropsWithChildren, ReactNode } from 'react';
import { InstructionState } from '@assemblio/type/instruction';
import { IconCircleCheckFilled, IconRefresh, IconSettingsFilled } from '@tabler/icons-react';
import { IconDraft } from '../Icons';

type ApplicableStates = Extract<
  InstructionState,
  InstructionState.DRAFT | InstructionState.APPROVED | InstructionState.IN_REVIEW | InstructionState.INITIALIZING
>;

type StateIconMapType = {
  [key in ApplicableStates]: ReactNode;
};

const StateIconMap: StateIconMapType = {
  [InstructionState.APPROVED]: <IconCircleCheckFilled size={16} />,
  [InstructionState.IN_REVIEW]: <IconRefresh size={16} />,
  [InstructionState.DRAFT]: <IconDraft />,
  [InstructionState.INITIALIZING]: <IconSettingsFilled size={16} />,
};

interface TagProps extends BadgeProps {
  state?: InstructionState;
}

export const Tag = ({ children, state, ...props }: PropsWithChildren<TagProps>) => {
  const Icon = StateIconMap[state as ApplicableStates];

  return (
    <Badge leftSection={Icon} py={'xs'} h={'fit-content'} className={classes.tag} {...props}>
      {state ? state : children}
    </Badge>
  );
};
