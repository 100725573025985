import React from 'react';
import { Card, Group, Text, Stack, Button, Box, Skeleton, ThemeIcon } from '@mantine/core';
import { IconFileFilled, IconFolder } from '@tabler/icons-react';
import classes from './ArtefactsCard.module.scss';
import { ItemPathDisplay } from '../ItemPathDisplay/ItemPathDisplay';
import { IconPDF, IconMP4 } from '@assemblio/design-system';
import { ArtefactFileInformation } from './components/ArtefactFileInformation';
import { ArtefactContextMenu } from './components/ArtefactContextMenu';
import { useNavigate } from 'react-router-dom';
import { ArtefactDto } from '@assemblio/shared/dtos';

interface LocationProps {
  folder: string;
  project: string;
}

interface ArtefactCardProps {
  artefact: ArtefactDto;
  location?: LocationProps;
  productId?: string;
}

const getArtefactIcon = (type: string) => {
  switch (type) {
    case 'video':
      return <IconMP4 />;
    case 'pdf':
      return <IconPDF />;
    case 'docx':
      return <IconFileFilled />;
    default:
      return <IconFileFilled />;
  }
};

export const ArtefactCard = ({ artefact, location, productId }: ArtefactCardProps) => {
  const navigate = useNavigate();

  const handleOpenProduct = () => {
    if (!productId) return;
    navigate(`disassembler/${productId}`);
  };
  return (
    <Card p={'md'} className={classes.artefactCard}>
      <Group h={'100%'} justify="space-between" align={'stretch'}>
        <Group h={'100%'} align={'stretch'}>
          <Box className={classes.artefactIcon}>
            <ThemeIcon variant={'transparent'} c={'text-primary'} size={'xs'}>
              {getArtefactIcon(artefact.type)}
            </ThemeIcon>
          </Box>
          <Stack gap={'xs'} justify="center">
            <Text fw={'bold'} c={'text-primary'}>
              {artefact.originalName}
            </Text>
            {location && <ItemPathDisplay folder={location.folder} project={location.project} />}
          </Stack>
        </Group>
        <Group>
          <ArtefactFileInformation type={artefact.type} size={artefact.size} timestamp={artefact.createdAt} />
          {productId && (
            <Button variant={'secondary'} onClick={handleOpenProduct}>
              <Text>Open Assembly Plan</Text>
            </Button>
          )}
          <ArtefactContextMenu artefactId={artefact.id} name={artefact.originalName} />
        </Group>
      </Group>
    </Card>
  );
};

export const ArtefactCardSkeleton = () => {
  return (
    <Card p={'lg'} className={classes.artefactCard}>
      <Group>
        <Skeleton height={40} width={32} />
        <Skeleton height={'1rem'} width={250} />
      </Group>
    </Card>
  );
};
