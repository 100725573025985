import classes from '../../modals/EditSyncProfileModal/EditSyncProfileModal.module.scss';
import { IconFileFilled } from '@tabler/icons-react';
import { Group, Text } from '@mantine/core';

interface VariantNameDisplayProps {
  name: string;
  showIcon?: boolean;
}

export const VariantNameDisplay = ({ name, showIcon = true }: VariantNameDisplayProps) => {
  return (
    <Group gap={'xs'} className={classes.variant_display}>
      {showIcon && <IconFileFilled size={'16px'} />}
      <Text fw={'bold'} span>
        {name}
      </Text>
    </Group>
  );
};
