import { ReactNode } from 'react';
import { Skeleton, Table } from '@mantine/core';
import classes from './ExplorerTable.module.scss';
import { Th, ThProps } from './components/TableHeader';
import { EmptyView } from '../../components/EmptyView/EmptyView';

interface ExplorerTableProps {
  headerItems: ThProps[];
  items: ReactNode[];
  isLoading: boolean;
  loadingPlaceholder?: ReactNode;
}

const SKELETON_ROW_COUNT = 3;

export const ExplorerTable = ({
  items,
  isLoading,
  headerItems,
  loadingPlaceholder = <SkeletonRow />,
}: ExplorerTableProps) => {
  const isEmpty = !isLoading && items.length === 0;
  return isEmpty ? (
    <EmptyView />
  ) : (
    <Table
      verticalSpacing={'sm'}
      classNames={{
        table: classes.table,
        thead: classes.table__head,
        td: classes.table__column,
      }}
    >
      <Table.Thead>
        <Table.Tr>
          {headerItems.map((header) => (
            <Th {...header} />
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{isLoading ? [...Array(SKELETON_ROW_COUNT)].map(() => loadingPlaceholder) : items}</Table.Tbody>
    </Table>
  );
};

const SkeletonRow = () => (
  <Table.Tr>
    <Table.Td />
    <Table.Td>
      <Skeleton h={'2rem'} w={'15rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td />
  </Table.Tr>
);
