import { useCallback, useState } from 'react';
import produce from 'immer';
import { StepGroupSelection } from '../types/export-modal.types';

export const useStepGroupSelection = () => {
  const [selection, setSelection] = useState<StepGroupSelection | undefined>(undefined);

  /**
   * Toggle Sequence in Selection
   * @param sequenceId
   */

  /**
   * Toggle StepGroup in Selection
   * @param sequenceId
   * @param stepGroupId
   */
  const handleStepGroupSelection = useCallback((stepGroupId: string) => {
    setSelection(
      produce<StepGroupSelection>((draft) => {
        const sG = draft.find((sG) => sG.stepGroupId === stepGroupId);
        if (sG) {
          // Toggle the selected state of the step group
          sG.selected = !sG.selected;
        }
      })
    );
  }, []);

  const selectAll = () => {
    setSelection(
      produce<StepGroupSelection>((draft) => {
        draft.forEach((sG) => {
          sG.selected = true;
        });
      })
    );
  };

  const deselectAll = () => {
    setSelection(
      produce<StepGroupSelection>((draft) => {
        draft.forEach((sG) => {
          sG.selected = false;
        });
      })
    );
  };

  return {
    selection,
    setSelection,
    selectAll,
    deselectAll,
    handleStepGroupSelection,
  };
};
