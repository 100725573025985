import { Skeleton, Text } from '@mantine/core';

interface GridTitleProps {
  title: string;
  isLoading?: boolean;
  count?: number;
}

export const GridTitle = ({ title, count, isLoading = false }: GridTitleProps) => {
  return isLoading ? (
    <Skeleton mx={'xl'} h={'2rem'} w={'100px'} />
  ) : (
    <Text px={'xl'} c={'text-tertiary'} size={'sm'}>
      {title} ({count})
    </Text>
  );
};
