import { Color, ShaderLib, ShaderMaterial, Texture, UniformsUtils } from 'three';
import { fragmentShader } from './viewcube.frag.glsl';

export class ViewCubeMaterial extends ShaderMaterial {
  private _map: Texture | null = null;
  private _baseColor: Color = new Color(0xffffff);
  set map(texture: Texture | null) {
    this._map = texture;
    this.uniforms.map.value = texture;
  }
  get map() {
    return this._map;
  }

  set color(color: Color) {
    this.uniforms.diffuse.value = color.toArray();
  }
  get color() {
    return new Color().fromArray(this.uniforms.diffuse.value);
  }

  set baseColor(color: Color) {
    this._baseColor = color;
    this.uniforms.baseColor.value = color.toArray();
  }
  get baseColor() {
    return this._baseColor;
  }

  constructor() {
    super({
      lights: true,
      uniforms: UniformsUtils.merge([
        UniformsUtils.clone(ShaderLib.phong.uniforms),
        { baseColor: { value: new Color(0xffffff).toArray() }, opacity: { value: 0.925 } },
      ]),
      transparent: true,
      vertexShader: ShaderLib.phong.vertexShader,
      forceSinglePass: false,
      fragmentShader,
      toneMapped: false,
    });
  }
}
