import React from 'react';
import { IconLink, IconRefresh } from '@tabler/icons-react';
import { SyncOverviewDto } from '@assemblio/shared/dtos';

interface SyncIconsProps {
  syncOverview: SyncOverviewDto;
  hasVariantsFeature: boolean;
}

export const ProductSyncIcons = ({ syncOverview, hasVariantsFeature }: SyncIconsProps) => {
  if (!hasVariantsFeature) return null;
  return (
    <>
      {syncOverview.fromSource && <IconLink size={16} />}
      {syncOverview.toTarget && <IconRefresh size={16} />}
    </>
  );
};
